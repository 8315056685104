import { RightOutlined } from '@ant-design/icons';
import { ROUTES } from '@app/utils/constants';
import logo from 'assets/logo.png';
import { useResponsive } from 'hooks/useResponsive';
import React from 'react';
import * as S from './MainSider/MainSider.styles';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { mobileOnly } = useResponsive();

  const img = logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={ROUTES.APPOINTMENTS.navigateTo}>
        <img src={img} alt="Zlatni Češalj" height={48} />
        {!isSiderCollapsed && <S.BrandSpan>Zlatni Češalj</S.BrandSpan>}
      </S.SiderLogoLink>
      {!mobileOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
