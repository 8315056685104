import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { PHONE_PREFIX } from '@app/utils/constants';
import { Form, FormInstance } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as S from './PhoneItem.styles';

interface PhoneItemsProps {
  required?: boolean;
  verified?: boolean;
  label?: string;
  name?: string;
  form?: FormInstance;
}

export const PhoneItem: React.FC<PhoneItemsProps> = (props) => {
  const { t } = useTranslation();
  const { required, form, verified, label = t('common.phone'), name = 'phone' } = props;

  useEffect(() => {
    if (form) {
      const onBlur = (el: React.ChangeEvent<HTMLInputElement>) => {
        if (el.target.value && el.target.value === PHONE_PREFIX) {
          form.setFieldValue(name, '');
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formName = (form as any).__INTERNAL__.name as string;
      document.getElementById(`${formName ? `${formName}_${name}` : name}`)?.addEventListener('blur', onBlur as never);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phoneContact = Form.useWatch(name, form);

  const onClick = () => {
    if (!phoneContact) {
      form?.setFieldValue(name, PHONE_PREFIX);
    }
  };

  return (
    <BaseButtonsForm.Item
      name={name}
      $isSuccess={verified}
      $successText={t('profile.nav.personalInfo.verified')}
      label={label}
      rules={[
        // eslint-disable-next-line sort-keys
        { required, message: t('common.requiredField') },
        () => ({
          validator(_, value) {
            if (!value || isValidPhoneNumber(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(t('profile.nav.personalInfo.wrongNumber')));
          },
        }),
      ]}
    >
      <S.PhoneNumberInput disabled={verified} className="ant-input" onClick={onClick} placeholder={'+385xxyyyy'} />
    </BaseButtonsForm.Item>
  );
};
