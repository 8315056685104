import { CheckboxOptionType } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

export const useDaysOfWeek = () => {
  const [daysOfWeek, setDaysOfWeek] = useState<CheckboxOptionType[]>([]);

  React.useEffect(() => {
    const days = moment.weekdaysShort().map((day, index) => ({
      label: day,
      value: index,
    }));
    // Rotate the array so that Monday is the first element
    const first = days.shift();
    if (first) {
      days.push(first);
    }
    setDaysOfWeek(days);
  }, []);

  return daysOfWeek;
};
