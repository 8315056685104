import { Modal } from '@app/components/common/Modal/Modal';
import { Button } from '@app/components/common/buttons/Button/Button';
import { DATE_FORMAT } from '@app/utils/constants';
import { Checkbox as AntCheckbox, DatePicker, Form, InputNumber, List, Row, Select, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';
import React, { useState } from 'react';
import { useDaysOfWeek } from './hooks/useDaysOfWeek';
import { useRepeated } from './hooks/useRepeated';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// A custom hook to get the days of the week as an array of strings

// A custom function to generate an array of dates based on the inputs
const generateDates = (
  appointmentStartDate: moment.Moment,
  appointmentEndDate: moment.Moment,
  repeatEndDate: moment.Moment | undefined,
  repeatOption: string,
  repeatFrequency: number,
  customRepeatWeekDays: CheckboxValueType[] = [],
) => {
  if (!repeatEndDate || !repeatFrequency) return [];
  const dates: { start: moment.Moment; end: moment.Moment }[] = [];
  const currentStartDate = appointmentStartDate.clone();
  const currentEndDate = appointmentEndDate.clone();

  while (currentStartDate.isSameOrBefore(repeatEndDate)) {
    // Check if the current date matches the repeatOption criteria
    if (repeatOption === 'daily') {
      // Add the current date to the array
      dates.push({ end: currentEndDate.clone(), start: currentStartDate.clone() });
      // Increment the current date by the repeatFrequency
      currentStartDate.add(repeatFrequency, 'days');
      currentEndDate.add(repeatFrequency, 'days');
    } else if (repeatOption === 'weekly') {
      // Check if the current date is one of the custom days
      if (customRepeatWeekDays.includes(currentStartDate.day())) {
        // Add the current date to the array
        dates.push({ end: currentEndDate.clone(), start: currentStartDate.clone() });
      }
      // Increment the current date by one day
      currentStartDate.add(1, 'days');
      currentEndDate.add(1, 'days');
      // If the current date is Monday, increment by (repeatFrequency - 1) weeks
      if (currentStartDate.day() === 1) {
        currentStartDate.add(repeatFrequency - 1, 'weeks');
        currentEndDate.add(repeatFrequency - 1, 'weeks');
      }
    } else if (repeatOption === 'monthly') {
      // Check if the current date has the same day of month as the start date
      if (currentStartDate.date() === appointmentStartDate.date()) {
        // Add the current date to the array
        dates.push({ end: currentEndDate.clone(), start: currentStartDate.clone() });
        // Increment the current date by the repeatFrequency
        currentStartDate.add(repeatFrequency, 'months');
        currentEndDate.add(repeatFrequency, 'months');
      } else {
        // Increment the current date by one day
        currentStartDate.add(1, 'days');
        currentEndDate.add(1, 'days');
      }
    } else if (repeatOption === 'yearly') {
      // Check if the current date has the same month and day as the start date
      if (
        currentStartDate.month() === appointmentStartDate.month() &&
        currentStartDate.date() === appointmentStartDate.date()
      ) {
        // Add the current date to the array
        dates.push({ end: currentEndDate.clone(), start: currentStartDate.clone() });
        // Increment the current date by the repeatFrequency
        currentStartDate.add(repeatFrequency, 'years');
        currentEndDate.add(repeatFrequency, 'years');
      } else {
        // Increment the current date by one day
        currentStartDate.add(1, 'days');
        currentEndDate.add(1, 'days');
      }
    }
  }
  return dates;
};

// The main component that renders a calendar and a form for repeatOption options
const RepeatForm = ({ startEndDate }: { startEndDate: moment.Moment[] }) => {
  const { t } = useTranslation();
  const startDate = startEndDate[0];
  const endDate = startEndDate[1];
  const [form] = Form.useForm();

  const repeatEndDate = Form.useWatch('repeatEndDate', form);
  const repeatOption = Form.useWatch('repeatOption', form);
  const repeatFrequency = Form.useWatch('repeatFrequency', form);
  const customRepeatWeekDays = Form.useWatch('customRepeatWeekDays', form);
  const repeated = Form.useWatch('repeated', form);

  const [dates, setDates] = useState<{ start: moment.Moment; end: moment.Moment }[]>([]);
  const daysOfWeek = useDaysOfWeek();

  const { repeatedModalVisible, setRepeatedModalVisible } = useRepeated();

  // A function to handle the change of the repeatOption option
  const handleRepeatChange: (e: string) => void = (value) => {
    // Reset the repeatFrequency and custom days
    const newCustomDays: number[] = [];
    if (value === 'weekly') newCustomDays.push(startDate.day());

    form.setFieldsValue({
      customRepeatWeekDays: newCustomDays,
      repeatFrequency: 1,
      repeatOption: value,
    });
  };

  // A function to update the dates array based on the inputs
  const updateDates = () => {
    setDates(generateDates(startDate, endDate, repeatEndDate, repeatOption, repeatFrequency, customRepeatWeekDays));
  };

  // A useEffect hook to update the dates array whenever the inputs change
  React.useEffect(() => {
    updateDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, repeatEndDate, repeatOption, repeatFrequency, customRepeatWeekDays]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="repeatableForm"
        initialValues={{
          customRepeatWeekDays: [],
          repeatEndDate: undefined,
          repeatFrequency: 1,
          repeatOption: 'daily',
          repeated: false,
        }}
      >
        <Row>
          <Form.Item
            name="repeated"
            style={{
              marginBottom: 0,
            }}
          >
            <>
              <Checkbox checked={repeated} onChange={() => form.setFieldValue('repeated', !repeated)}>
                {t('appointment.form.repeatable')}
              </Checkbox>
              {repeated ? (
                <Button size="small" onClick={() => setRepeatedModalVisible(true)} style={{ display: 'inline-flex' }}>
                  {t('appointment.form.setRepeatable')}
                </Button>
              ) : null}
            </>
          </Form.Item>
        </Row>

        <Modal
          title={t('appointment.form.repeatable')}
          open={repeatedModalVisible}
          onOk={() => setRepeatedModalVisible(false)}
          onCancel={() => setRepeatedModalVisible(false)}
          cancelButtonProps={{
            style: { display: 'none' },
          }}
          bodyStyle={{
            height: '60vh',
            overflow: 'auto',
          }}
        >
          <div className="repeatOption-container">
            <div className="calendar-form">
              <Form.Item label={t('appointment.form.repeatable')} name="repeatOption">
                <Select defaultValue={'daily'} value={repeatOption} onChange={(value) => handleRepeatChange(value)}>
                  <Option value="daily">{t('appointment.form.repeatableForm.daily')}</Option>
                  <Option value="weekly">{t('appointment.form.repeatableForm.weekly')}</Option>
                  <Option value="monthly">{t('appointment.form.repeatableForm.monthly')}</Option>
                  <Option value="yearly">{t('appointment.form.repeatableForm.yearly')}</Option>
                </Select>
              </Form.Item>

              <Form.Item label={t('appointment.form.repeatableForm.every')} name="repeatFrequency">
                <InputNumber
                  min={1}
                  max={99}
                  value={repeatFrequency}
                  onChange={(value) => {
                    form.setFieldValue('repeatFrequency', value);
                  }}
                />
                {repeatOption === 'daily' && ` ${t('appointment.form.repeatableForm.day')}`}
                {repeatOption === 'weekly' && ` ${t('appointment.form.repeatableForm.week')}`}
                {repeatOption === 'monthly' && ` ${t('appointment.form.repeatableForm.month')}`}
                {repeatOption === 'yearly' && ` ${t('appointment.form.repeatableForm.year')}`}
              </Form.Item>

              {repeatOption === 'weekly' && (
                <Form.Item label={t('appointment.form.repeatableForm.on')} name="customRepeatWeekDays">
                  <AntCheckbox.Group options={daysOfWeek} value={customRepeatWeekDays} />
                </Form.Item>
              )}

              <Form.Item label={t('appointment.form.repeatableForm.endDate')} name="repeatEndDate">
                <DatePicker
                  value={repeatEndDate}
                  format={DATE_FORMAT}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </div>

            {dates.length ? (
              <>
                <List
                  bordered
                  dataSource={dates}
                  style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                  }}
                  renderItem={(date) => (
                    <List.Item>
                      {date.start.format('DD.MM.YYYY HH:mm')} - {date.end.format('DD.MM.YYYY HH:mm')}
                    </List.Item>
                  )}
                />
                <Typography.Text>Ukupno termina: {dates.length}</Typography.Text>
              </>
            ) : null}
          </div>
        </Modal>
      </Form>
    </>
  );
};

export default RepeatForm;
