import {
  EditingState,
  GroupingState,
  IntegratedEditing,
  IntegratedGrouping,
  ViewState,
  ViewStateProps,
} from '@devexpress/dx-react-scheduler';
import {
  AppointmentForm,
  AppointmentFormProps,
  AppointmentTooltip,
  ConfirmationDialog,
  CurrentTimeIndicator,
  DateNavigator,
  DayView,
  DragDropProvider,
  GroupingPanel,
  Resources,
  Scheduler,
  Appointments as SchedulerAppointments,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';

import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentsWrapper } from './Appointments.styles';
import { AppointmentsViewModel } from './Appointments.vm';
import Appointment from './components/Appointment';
import { AppointmentTooltipContent } from './components/AppointmentTooltip/Content/Content';
import ChangedModal from './components/ChangedModal';
import { getConfirmationDialogLayout } from './components/ConfirmationDialogLayout';
import { FlexibleSpaceCustom } from './components/FlexibleSpaceCustom';
import { AppointmentFormOverlay, ManageAppointment } from './components/ManageAppointment/ManageAppointment';
import { getViewTimeCell } from './components/TimeCell';
import WeekDayScaleCell from './components/WeekDayScaleCell';
import { setFormVisible } from './store/appointmentFormSlice';
import { changeDate } from './store/appointmentsSlice';

const Appointments: React.FC = () => {
  const {
    onCommitChanges,
    dragNDropChangesModalVisible,
    groupByDate,
    grouping,
    locale,
    resources,
    schedulerData,
    cancelDragNDropChangesModal,
    onOkDragNDropChangesModal,
    dragNDropDiffs,
    calendarEndDayHour,
    calendarStartDayHour,
    barberWorkTimeOverridesData,
    barbersData,
    selectedDeleteRepeatOption,
    setSelectedDeleteRepeatOption,
  } = AppointmentsViewModel();
  const dispatch = useAppDispatch();

  const [defaultView] = useState(window.innerWidth < 768 ? 'Day' : 'Week');
  const filters = useAppSelector((state) => state.appointments.filters);

  const { t } = useTranslation();

  const ConfirmationDialogLayout = useMemo(
    () =>
      getConfirmationDialogLayout({
        selectedDeleteRepeatOption,
        setSelectedDeleteRepeatOption,
      }),
    [selectedDeleteRepeatOption, setSelectedDeleteRepeatOption],
  );

  const DayTimeCell = useMemo(
    () =>
      getViewTimeCell({
        barberWorkTimeOverridesData: barberWorkTimeOverridesData,
        barbersData: barbersData,
        dayView: true,
      }),
    [barberWorkTimeOverridesData, barbersData],
  );

  const WeekTimeCell = useMemo(
    () =>
      getViewTimeCell({
        barberWorkTimeOverridesData: barberWorkTimeOverridesData,
        barbersData: barbersData,
        dayView: true,
      }),
    [barberWorkTimeOverridesData, barbersData],
  );

  const onCurrentDateChange: ViewStateProps['onCurrentDateChange'] = useCallback(
    (currentDate) => {
      dispatch(changeDate(currentDate));
    },
    [dispatch],
  );

  return (
    <>
      {dragNDropDiffs?.differences.length ? (
        <ChangedModal
          data={dragNDropDiffs?.differences}
          visible={dragNDropChangesModalVisible}
          onCancel={cancelDragNDropChangesModal}
          onOk={onOkDragNDropChangesModal}
        />
      ) : null}
      <AppointmentsWrapper noOfFilterBarbers={filters.barbers.length}>
        <Scheduler firstDayOfWeek={1} locale={locale} data={schedulerData}>
          <ViewState defaultCurrentViewName={defaultView} onCurrentDateChange={onCurrentDateChange} />
          <EditingState onCommitChanges={onCommitChanges} />
          <GroupingState grouping={grouping} groupByDate={groupByDate} />

          <DayView
            startDayHour={calendarStartDayHour}
            endDayHour={calendarEndDayHour}
            displayName={t('common.day')}
            timeTableCellComponent={DayTimeCell}
          />
          <WeekView
            dayScaleCellComponent={WeekDayScaleCell}
            startDayHour={calendarStartDayHour}
            endDayHour={calendarEndDayHour}
            timeTableCellComponent={WeekTimeCell}
            displayName={t('common.week')}
          />

          <Toolbar flexibleSpaceComponent={FlexibleSpaceCustom} />
          <TodayButton messages={{ today: t('common.today') }} />
          <DateNavigator />

          <SchedulerAppointments appointmentComponent={Appointment} />
          <Resources data={resources} />
          <IntegratedGrouping />
          <IntegratedEditing />
          <ConfirmationDialog
            ignoreCancel
            messages={{
              cancelButton: t('common.confirmationDialog.cancelButton'),
              confirmDeleteMessage: t('common.confirmationDialog.appointmentConfirmDeleteMessage'),
              deleteButton: t('common.confirmationDialog.deleteButton'),
            }}
            layoutComponent={ConfirmationDialogLayout}
          />

          <AppointmentTooltip showDeleteButton showOpenButton contentComponent={AppointmentTooltipContent} />

          <ManageForm />
          <ViewSwitcher />
          <GroupingPanel />
          <DragDropProvider />
          <CurrentTimeIndicator
            shadePreviousCells
            shadePreviousAppointments
            updateInterval={300000} // 5 min
          />
        </Scheduler>
      </AppointmentsWrapper>
    </>
  );
};

const ManageForm = memo(() => {
  const dispatch = useAppDispatch();
  const formVisible = useAppSelector((state) => state.appointmentForm.formVisible);

  const onVisibilityChange: AppointmentFormProps['onVisibilityChange'] = useCallback(
    (visible) => {
      dispatch(setFormVisible(visible));
    },
    [dispatch],
  );

  return (
    <AppointmentForm
      overlayComponent={AppointmentFormOverlay}
      basicLayoutComponent={ManageAppointment}
      onVisibilityChange={onVisibilityChange}
      visible={formVisible}
    />
  );
});

export default Appointments;
