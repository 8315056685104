import { RetweetOutlined } from '@ant-design/icons';
import { InfoIcon } from '@app/components/common/icons/InfoIcon';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import styled from 'styled-components';
import { AppointmentData } from '../../Appointments.types';

export const Appointment = styled(Appointments.Appointment)`
  display: flex;
`;

export default (props: Appointments.AppointmentProps) => {
  const data = props.data as AppointmentData;

  return (
    <Appointment {...props}>
      {data.appointment?.barberNote ? <InfoIcon /> : null}
      {props.children}
      {data.appointment?.repeatId ? <RetweetOutlined style={{ color: 'white' }} /> : null}
    </Appointment>
  );
};
