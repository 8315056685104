import { ConfirmationDialog } from '@devexpress/dx-react-scheduler-material-ui';

import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Radio, Row, Space, Typography } from 'antd';
import { AppointmentData, DeleteOptionEnum } from '../../Appointments.types';
import { useTranslation } from 'react-i18next';

export const getConfirmationDialogLayout = (mainProps: {
  setSelectedDeleteRepeatOption: (value: DeleteOptionEnum) => void;
  selectedDeleteRepeatOption: DeleteOptionEnum;
}) => {
  return (props: ConfirmationDialog.LayoutProps) => {
    const { t } = useTranslation();
    const data = props.appointmentData as AppointmentData | undefined;

    if (!data?.appointment?.repeatId) {
      return <ConfirmationDialog.Layout {...props} />;
    }

    return (
      <div style={{ padding: 12 }}>
        <div style={{ padding: '10px 16px' }}>
          <Typography.Title level={5}>{props.getMessage('confirmDeleteMessage')}</Typography.Title>
          <Radio.Group
            onChange={(e) => mainProps.setSelectedDeleteRepeatOption(e.target.value)}
            value={mainProps.selectedDeleteRepeatOption}
          >
            <Space direction="vertical">
              <Radio value={'one' as DeleteOptionEnum}>
                {t('common.confirmationDialog.repeatableAppointmentDeleteOne')}
              </Radio>
              <Radio value={'all' as DeleteOptionEnum}>
                {t('common.confirmationDialog.repeatableAppointmentDeleteAll')}
              </Radio>
            </Space>
          </Radio.Group>
        </div>

        <div className="ant-modal-footer">
          <Row gutter={[10, 10]} justify={'end'}>
            <Col>
              <Button onClick={props.handleCancel}>{t('common.confirmationDialog.cancelButton')}</Button>
            </Col>
            <Col>
              <Button onClick={props.handleConfirm}>{t('common.confirmationDialog.deleteButton')}</Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
};
