import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AuthContext } from '@app/context/AuthContext';
import { ROUTES } from '@app/utils/constants';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const Profile = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return null;
  }

  return (
    <ProfileCard>
      <Button type="link" href={ROUTES.BARBERS.children.EDIT.navigateTo(userData?.id)}>
        {t('profile.title')}
      </Button>
      <Button type="link" href={'/logout'}>
        {t('header.logout')}
      </Button>
    </ProfileCard>
  );
};

const ProfileCard = styled(Card)`
  height: unset;
  width: fit-content;
`;

export default Profile;
