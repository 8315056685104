import { AuthContext } from '@app/context/AuthContext';
import { WithChildrenProps } from '@app/types/generalTypes';
import { ROUTES } from '@app/utils/constants';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../common/Loading';
import { LayoutMaster } from '../layouts/main/MainLayout/MainLayout.styles';
import { Layout } from 'antd';
import MainContent from '../layouts/main/MainContent/MainContent';

const NotRequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const { isLoggedIn, mounting } = useContext(AuthContext);

  if (mounting) {
    return (
      <LayoutMaster>
        <Layout>
          <MainContent id="main-content" $isTwoColumnsLayout={false}>
            <Loading />
          </MainContent>
        </Layout>
      </LayoutMaster>
    );
  }

  return !isLoggedIn ? <>{children}</> : <Navigate to={ROUTES.APPOINTMENTS.navigateTo} replace />;
};

export default NotRequireAuth;
