import { auth } from '@app/config/firebase';
import { BarberQuery, useBarberLazyQuery } from '@app/graphql/generated';
import { onAuthStateChanged, User } from 'firebase/auth';
import { createContext, FC, memo, useEffect, useMemo, useState } from 'react';

export const AuthContext = createContext<{
  user: User | null;
  mounting: boolean;
  isLoggedIn: boolean;
  logoutUser: () => void;
  userData: LoggedBarber | undefined;
}>({
  isLoggedIn: false,
  logoutUser: () => {
    //
  },
  mounting: true,
  user: null,
  userData: undefined,
});

type LoggedBarber = Pick<NonNullable<BarberQuery['barber']>, 'id' | 'name' | 'type' | 'active'>;

export const AuthProvider: FC = memo(({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<LoggedBarber>();
  const [mounting, setMounting] = useState(true);

  const [fetchBarber] = useBarberLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.barber) {
        setUserData({
          active: data.barber.active,
          id: data.barber.id,
          name: data.barber.name,
          type: data.barber.type,
        });
      }
      setMounting(false);
    },
    onError() {
      setMounting(false);
      return null;
    },
  });

  useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setUser(user);
          fetchBarber({
            variables: {
              where: {
                uid: user.uid,
              },
            },
          });
        } else {
          setMounting(false);
        }
      },
      (error) => {
        setMounting(false);
        // eslint-disable-next-line no-console
        console.log('firebase error: ', error);
      },
    );
  }, [fetchBarber]);

  const logoutUser = () => {
    auth.signOut().then(() => {
      setUser(null);
      setUserData(undefined);
    });
  };

  const isLoggedIn = useMemo(() => !!(user && userData), [user, userData]);

  const value = useMemo(
    () => ({ isLoggedIn, logoutUser, mounting: mounting, user, userData }),
    [user, mounting, userData, isLoggedIn],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});
