import { EditOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/table.api';
import { Badge } from '@app/components/common/Badge/Badge';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useGetColumnSearchProps } from '@app/components/tables/components/filterSearch';
import * as S from '@app/components/tables/Tables/Tables.styles';
import {
  ServiceOrderByWithRelationInput,
  ServicesQuery,
  ServiceWhereInput,
  SortOrder,
  useServicesLazyQuery,
} from '@app/graphql/generated';
import { Col, Row, Space, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Button } from 'components/common/buttons/Button/Button';
import { Table } from 'components/common/Table/Table';
import { capitalize, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

type Service = ServicesQuery['services'][0];

const ServicesPage: React.FC = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [fetchServices, { data, loading }] = useServicesLazyQuery();

  const { t } = useTranslation();

  const oldWhere = useRef<ServiceWhereInput | null>(null);

  const createWhere = (filters?: Record<keyof ServiceWhereInput, FilterValue | null>) => {
    let where: ServiceWhereInput | null = null;

    if (filters) {
      where = {
        AND: [],
      };
      (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
        const value = filters[key];
        if (where?.AND && value) {
          const OR: ServiceWhereInput['OR'] = [];

          OR.push({ [key]: { contains: value[0] } });
          OR.push({ [key]: { contains: String(value[0]).toLowerCase() } });
          OR.push({ [key]: { contains: capitalize(String(value[0])) } });
          where.AND.push({ OR });
        }
      });
    }

    return where;
  };

  const createOrderBy = (sorter?: SorterResult<Service> | SorterResult<Service>[]) => {
    let orderBy: ServiceOrderByWithRelationInput | null = null;
    const sorterIsArray = Array.isArray(sorter);

    if (sorter && !sorterIsArray) {
      orderBy = sorter.field
        ? {
            ...(typeof orderBy === 'object' ? orderBy : {}),
            [sorter.field as keyof Service]: sorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc,
          }
        : null;
    }

    return orderBy;
  };

  const fetch = useCallback(
    async (
      pagination: Pagination,
      filters?: Record<keyof ServiceWhereInput, FilterValue | null>,
      sorter?: SorterResult<Service> | SorterResult<Service>[],
    ) => {
      const where = createWhere(filters);
      const orderBy = createOrderBy(sorter);

      const response = await fetchServices({
        variables: {
          skip: pagination.current && pagination.pageSize ? (pagination.current - 1) * pagination.pageSize : undefined,
          ...(where ? { where } : null),
          ...(orderBy ? { orderBy } : null),
          take: pagination.pageSize,
        },
      });

      setPagination({ ...pagination, total: response.data?.aggregateService._count?._all || 0 });
      if (!isEqual(where, oldWhere.current)) {
        oldWhere.current = where;
      }
    },
    [fetchServices],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange: TableProps<Service>['onChange'] = (pagination, filters, sorter) => {
    fetch(pagination, filters, sorter);
  };

  const columns: ColumnsType<Service> = [
    {
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text}</span>,
      sorter: true,
      title: t('servicesList.name'),
      ...useGetColumnSearchProps('name'),
    },
    {
      dataIndex: 'description',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span
            style={{
              cursor: 'pointer',
              display: 'block',
              maxWidth: '800px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
      sorter: true,
      title: t('servicesList.description'),
    },
    {
      dataIndex: 'duration',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text} min</span>,
      sorter: true,
      title: t('servicesList.duration'),
    },
    {
      dataIndex: 'price',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text} €</span>,
      sorter: true,
      title: t('servicesList.price'),
    },
    {
      dataIndex: 'active',
      render: (active: boolean) => {
        return (
          <Row gutter={[10, 10]} style={{ minWidth: '100px' }}>
            <Col>
              <Badge
                status={active ? 'success' : 'error'}
                text={active ? t('servicesList.yes') : t('servicesList.no')}
              />
            </Col>
          </Row>
        );
      },
      title: t('clientsList.active'),
    },
    {
      dataIndex: 'priority',
      render: (priority: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>{priority ? <Badge status={'success'} text={t('servicesList.yes')} /> : ''}</Col>
          </Row>
        );
      },
      title: t('clientsList.priority'),
    },
    {
      dataIndex: 'bookable',
      render: (bookable: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>{bookable ? <Badge status={'success'} text={t('servicesList.yes')} /> : ''}</Col>
          </Row>
        );
      },
      title: t('clientsList.bookable'),
    },
    {
      dataIndex: 'actions',
      render: (_text, record: Service) => {
        return (
          <Space>
            <Button type="default" icon={<EditOutlined />} onClick={() => navigate(`/services/${record.id}`)}>
              {t('tables.edit')}
            </Button>
          </Space>
        );
      },
      title: t('tables.actions'),
      width: '15%',
    },
  ];

  return (
    <>
      <PageTitle>{t('common.services')}</PageTitle>
      <S.TablesWrapper>
        <S.Card
          id="basic-table"
          title={t('common.services')}
          extra={<Button onClick={() => navigate('/services/create')}>{t('common.serviceCreate')}</Button>}
          padding="1.25rem 1.25rem 0"
        >
          <Table
            columns={columns}
            dataSource={data?.services}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
            rowKey={'id'}
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};

export default ServicesPage;
