import { WeekView, WeekViewProps } from '@devexpress/dx-react-scheduler-material-ui';
import { alpha, styled } from '@mui/material';
import { classes } from '../classes';

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(({ theme }) => ({
  [`&.${classes.today}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  [`&.${classes.weekend}`]: {
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
  },
}));

const WeekDayScaleCell: WeekViewProps['dayScaleCellComponent'] = (props: WeekView.DayScaleCellProps) => {
  const { startDate, today } = props;

  if (today) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.weekend} />;
  }
  return <StyledWeekViewDayScaleCell {...props} />;
};

export default WeekDayScaleCell;
