import {
  useAppointmentFormBarbersAndServicesLazyQuery,
  useAppointmentsDateChangeLazyQuery,
} from '@app/graphql/generated';
import { useMemo } from 'react';

import { useAppointmentBarbersQuery } from '@app/graphql/generated';

import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useEffect } from 'react';
import { AppointmentsViewModelType } from './Appointments.types';
import { useDragNDrop } from './hooks/useDragNDrop';
import { useScheduler } from './hooks/useScheduler';
import { useSchedulerData } from './hooks/useSchedulerData';
import { useSubscription } from './hooks/useSubscription';
import { AppointmentFormBarbersAndServicesQueryVariables } from '@app/utils/constants';
import { setFormBarbersAndServices } from './store/appointmentFormSlice';

export const AppointmentsViewModel: AppointmentsViewModelType = () => {
  const filters = useAppSelector((state) => state.appointments.filters);
  const dates = useAppSelector((state) => state.appointments.dates);
  const dispatch = useAppDispatch();

  const appointmentsWhereVariables = useAppSelector((state) => state.appointments.appointmentsWhereVariables);

  const [appointmentsDateQuery, { data: appointmentsDateData }] = useAppointmentsDateChangeLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [fetchBarbersAndServices] = useAppointmentFormBarbersAndServicesLazyQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    fetchBarbersAndServices({
      onCompleted(data) {
        dispatch(setFormBarbersAndServices(data));
      },
      variables: AppointmentFormBarbersAndServicesQueryVariables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: barbersData } = useAppointmentBarbersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        active: {
          equals: true,
        },
      },
    },
  });

  const {
    cancelDragNDropChangesModal,
    dragNDropDiffs,
    dragNDropChangesModalVisible,
    onOkDragNDropChangesModal,
    generateDifferencesOnDragNDrop,
  } = useDragNDrop(appointmentsDateData?.appointments, barbersData?.barbers);

  const { calendarEndDayHour, calendarStartDayHour, grouping, locale, resources, schedulerData, groupByDate } =
    useScheduler(barbersData?.barbers, appointmentsDateData?.appointments);

  useSubscription();

  const { onCommitChanges, selectedDeleteRepeatOption, setSelectedDeleteRepeatOption } =
    useSchedulerData(generateDifferencesOnDragNDrop);

  useEffect(() => {
    if (filters.barbers.length) {
      appointmentsDateQuery({
        variables: appointmentsWhereVariables,
      });
    }
  }, [filters.barbers, filters.barbers.length, dates, appointmentsDateQuery, appointmentsWhereVariables]);

  return useMemo(
    () => ({
      barberWorkTimeOverridesData: appointmentsDateData?.barberWorkTimeOverrides,
      barbersData,
      calendarEndDayHour,
      calendarStartDayHour,
      cancelDragNDropChangesModal,
      dragNDropChangesModalVisible,
      dragNDropDiffs,
      groupByDate,
      grouping,
      locale: locale.locale,
      onCommitChanges,
      onOkDragNDropChangesModal,
      resources,
      schedulerData,
      selectedDeleteRepeatOption,
      setSelectedDeleteRepeatOption,
    }),
    [
      appointmentsDateData?.barberWorkTimeOverrides,
      barbersData,
      calendarEndDayHour,
      calendarStartDayHour,
      cancelDragNDropChangesModal,
      dragNDropChangesModalVisible,
      dragNDropDiffs,
      groupByDate,
      grouping,
      locale.locale,
      onCommitChanges,
      onOkDragNDropChangesModal,
      resources,
      schedulerData,
      selectedDeleteRepeatOption,
      setSelectedDeleteRepeatOption,
    ],
  );
};

// export const useBarberWorkTimesHook = (barbers: number[]) => {

//   return { barberWorkTimes, changeDate, dates };
// };
