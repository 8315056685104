import {
  BgColorsOutlined,
  CalendarOutlined,
  MoreOutlined,
  ScissorOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ROUTES } from '@app/utils/constants';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  component?: React.ReactNode;
  notForMobile?: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    icon: <CalendarOutlined />,
    key: 'appointments',
    title: 'common.appointments',
    url: ROUTES.APPOINTMENTS.navigateTo,
  },
  {
    icon: <UserOutlined />,
    key: 'barbers',
    title: 'common.barbers',
    url: ROUTES.BARBERS.navigateTo,
  },
  {
    icon: <ScissorOutlined />,
    key: 'services',
    title: 'common.services',
    url: ROUTES.SERVICES.navigateTo,
  },
  {
    icon: <BgColorsOutlined />,
    key: 'additionalServices',
    title: 'common.additionalServices',
    url: ROUTES.ADDITIONAL_SERVICES.navigateTo,
  },
  {
    icon: <UsergroupAddOutlined />,
    key: 'clients',
    title: 'common.clients',
    url: ROUTES.CLIENTS.navigateTo,
  },
  {
    children: [
      {
        key: 'settings.general',
        title: 'common.settings',
        url: ROUTES.SETTINGS.children.GENERAL.navigateTo,
      },
      {
        key: 'settings.profile',
        title: 'common.profile',
        url: ROUTES.SETTINGS.children.PROFILE.navigateTo,
      },
    ],
    icon: <MoreOutlined />,
    key: 'settings',
    notForMobile: true,
    title: 'common.additional',
  },
];
