import { useResponsive } from '@app/hooks/useResponsive';
import React from 'react';
import { MobileHeader } from './layouts/MobileHeader';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { mobileOnly } = useResponsive();

  return !mobileOnly ? null : <MobileHeader toggleSider={toggleSider} isSiderOpened={isSiderOpened} />;
};
