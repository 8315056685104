/* eslint-disable sort-keys */
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import { FirebaseError } from 'firebase/app';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../config/firebase';
import * as S from './ForgotPasswordForm.styles';
interface ForgotPasswordFormData {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: ForgotPasswordFormData) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      notificationController.success({
        message: t('auth.emailSent'),
        duration: 5000,
      });
    } catch (err) {
      const error = err as FirebaseError;
      let errMsg;
      if (error.code === 'auth/user-not-found') {
        errMsg = t('auth.userNotFound');
      }
      if (error.code === 'auth/invalid-email') {
        errMsg = t('auth.invalidEmail');
      }
      notificationController.error({
        message: errMsg,
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.emailError') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
