import { createContext, FC, memo, useCallback, useMemo, useState } from 'react';

export const GlobalLoadingContext = createContext<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
}>({
  loading: false,
  setLoading: () => {
    //
  },
});

export const GlobalLoadingProvider: FC = memo(({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const changeLoading = useCallback(
    (loading: boolean) => {
      setLoading(loading);
    },
    [setLoading],
  );

  const value = useMemo(
    () => ({
      loading,
      setLoading: changeLoading,
    }),
    [loading, changeLoading],
  );

  return <GlobalLoadingContext.Provider value={value}>{children}</GlobalLoadingContext.Provider>;
});
