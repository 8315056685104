import { InfoCircleFilled } from '@ant-design/icons';
import { FC } from 'react';

import styled from 'styled-components';

export const InfoCircleFilledStyled = styled(InfoCircleFilled)`
  color: white;
`;

export const InfoIcon: FC = () => <InfoCircleFilledStyled />;
