import ClientCreate from '@app/components/client/ClientCreate';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Modal } from '@app/components/common/Modal/Modal';
import { PhoneItem } from '@app/components/common/PhoneItem/PhoneItem';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { Select } from '@app/components/common/selects/Select/Select';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '@app/utils/constants';
import { Form as AntForm, Col, DatePicker, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormBodyWrapper, Label } from './Form.styles';
import { FormProps } from './Form.types';
import { FormViewModel } from './Form.vm';
import RepeatForm from './RepeatForm/RepeatForm';

const Form: FC<FormProps> = memo(
  (props) => {
    const { t } = useTranslation();
    const barberOptions = useAppSelector((state) => state.appointmentForm.barberOptions);
    const servicesOptions = useAppSelector((state) => state.appointmentForm.servicesOptions);

    const { appointment } = props;
    const {
      appointmentTime,
      appointmentExists,
      clientData,
      clientModalVisible,
      clientOptions,
      closeClientModal,
      debounceFetchClients,
      form,
      onFormFinish,
      onServiceChange,
      openClientModal,
      serviceChanged,
    } = FormViewModel(appointment, props.onFinish);

    const dateRange = AntForm.useWatch('dateRange', form);
    const isPause = AntForm.useWatch('isPause', form);

    return (
      <FormBodyWrapper>
        <BaseButtonsForm.Provider onFormFinish={onFormFinish}>
          {dateRange?.length === 2 && !appointment.id && <RepeatForm startEndDate={dateRange} />}

          <BaseButtonsForm
            name="appointmentForm"
            form={form}
            isFieldsChanged={true}
            initialValues={{
              barberId: appointment.barberId,
              clientId: appointment.clientId,
              dateRange: appointmentTime,
              isPause: appointment.isPause,
              phoneContact: appointment.phoneContact,
              price: appointment.price,
              serviceId: appointment.serviceId,
            }}
            loading
            footer={
              <BaseButtonsForm.Item>
                <Button htmlType="submit" type="primary" disabled={props.loading} loading={props.loading}>
                  {t('common.submit')}
                </Button>
              </BaseButtonsForm.Item>
            }
          >
            <BaseButtonsForm.Item
              name={'dateRange'}
              required
              label={t('appointment.form.time')}
              {...(appointmentExists ? { help: t('appointment.form.helpDateRange'), validateStatus: 'warning' } : null)}
            >
              <DatePicker.RangePicker
                minuteStep={5}
                format={DATE_TIME_FORMAT}
                showTime={{
                  format: TIME_FORMAT,
                }}
              />
            </BaseButtonsForm.Item>

            <Row>
              <BaseButtonsForm.Item
                // label={t('appointment.form.isPause')}
                name="isPause"
                style={{
                  marginBottom: 0,
                }}
              >
                <Checkbox
                  checked={isPause}
                  onChange={() => {
                    if (!isPause) {
                      form.setFieldsValue({
                        clientId: undefined,
                        price: undefined,
                        serviceId: undefined,
                      });
                    }

                    form.setFieldValue('isPause', !isPause);
                  }}
                >
                  {t('appointment.form.pause/brunch')}
                </Checkbox>
              </BaseButtonsForm.Item>
            </Row>

            <BaseButtonsForm.Item
              name="barberId"
              label={t('appointment.form.selectBarber')}
              rules={[{ message: t('appointment.form.mustBeSelectedBarber'), required: true }]}
            >
              <Select options={barberOptions} />
            </BaseButtonsForm.Item>
            {!isPause ? (
              <>
                <BaseButtonsForm.Item
                  name="serviceId"
                  label={t('appointment.form.selectService')}
                  rules={[{ message: t('appointment.form.mustBeSelectedService'), required: true }]}
                  {...(serviceChanged
                    ? { help: t('appointment.form.helpServiceChange'), validateStatus: 'warning' }
                    : null)}
                >
                  <Select options={servicesOptions} onChange={onServiceChange} />
                </BaseButtonsForm.Item>
                <BaseButtonsForm.Item
                  name="price"
                  label={t('appointment.form.price')}
                  rules={[{ message: t('appointment.form.mustBeSelectedPrice'), required: true }]}
                >
                  <InputNumber min={0} prefix="€" />
                </BaseButtonsForm.Item>

                <Row gutter={[15, 16]} style={{ justifyContent: 'space-between', margin: 0, paddingBottom: 8 }}>
                  <Label className="ant-form-item-label" style={{ padding: 0 }}>
                    {t('appointment.form.selectClient')}
                  </Label>
                  <Button size="small" onClick={openClientModal}>
                    {t('appointment.form.createClient')}
                  </Button>
                </Row>
                <Row gutter={[15, 16]} style={{ paddingBottom: 16 }}>
                  <Col xs={24} sm={24} md={24}>
                    <BaseButtonsForm.Item
                      name="clientId"
                      rules={[{ message: t('appointment.form.mustBeSelectedClient'), required: true }]}
                      {...(clientData?.client?.blocked || clientData?.client?.penalties
                        ? {
                            help: (
                              <>
                                {clientData?.client?.blocked && (
                                  <div style={{ color: 'red' }}>
                                    {t('appointment.form.clientBlocked')}: {clientData?.client?.blockedReason}
                                  </div>
                                )}
                                {clientData?.client?.penalties.length !== 0 && (
                                  <div style={{ color: 'orange' }}>
                                    {clientData?.client?.penalties.map((penalty, index) => (
                                      <div key={index}>
                                        {t('appointment.form.penalty')} {index + 1}: {penalty.reason}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            ),
                          }
                        : null)}
                    >
                      <Select
                        showSearch
                        onSearch={debounceFetchClients}
                        options={clientOptions}
                        filterOption={false}
                        allowClear
                      />
                    </BaseButtonsForm.Item>
                  </Col>
                </Row>
              </>
            ) : null}

            <PhoneItem name="phoneContact" label={t('appointment.form.phoneContact')} form={form} />

            <BaseButtonsForm.Item
              name="barberNote"
              label={t('appointment.form.barbersNote')}
              initialValue={appointment.barberNote}
            >
              <TextArea rows={2} />
            </BaseButtonsForm.Item>
          </BaseButtonsForm>
        </BaseButtonsForm.Provider>

        {clientModalVisible && (
          <Modal
            title={t('modals.basic')}
            open={clientModalVisible}
            onOk={closeClientModal}
            onCancel={closeClientModal}
            cancelButtonProps={{
              style: { display: 'none' },
            }}
          >
            <ClientCreate />
          </Modal>
        )}
      </FormBodyWrapper>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.loading === nextProps.loading && prevProps.appointment === nextProps.appointment) {
      return true;
    }

    return false;
  },
);

export default Form;
