import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationHR from './locales/hr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  hr: {
    translation: translationHR,
  },
};

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'hr',
  resources,
});

export default i18n;
