/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTimeISO: any;
  Decimal: any;
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  AdditionalServiceVersion: Array<AdditionalServiceVersion>;
  _count?: Maybe<AdditionalServiceCount>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};


export type AdditionalServiceAdditionalServiceVersionArgs = {
  cursor?: InputMaybe<AdditionalServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdditionalServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdditionalServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceAvgAggregate = {
  __typename?: 'AdditionalServiceAvgAggregate';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type AdditionalServiceCount = {
  __typename?: 'AdditionalServiceCount';
  AdditionalServiceVersion: Scalars['Int'];
};


export type AdditionalServiceCountAdditionalServiceVersionArgs = {
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceCountAggregate = {
  __typename?: 'AdditionalServiceCountAggregate';
  _all: Scalars['Int'];
  description: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
};

export type AdditionalServiceCreateInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutAdditionalServiceInput>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type AdditionalServiceCreateNestedOneWithoutAdditionalServiceVersionInput = {
  connect?: InputMaybe<AdditionalServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdditionalServiceCreateOrConnectWithoutAdditionalServiceVersionInput>;
  create?: InputMaybe<AdditionalServiceCreateWithoutAdditionalServiceVersionInput>;
};

export type AdditionalServiceCreateOrConnectWithoutAdditionalServiceVersionInput = {
  create: AdditionalServiceCreateWithoutAdditionalServiceVersionInput;
  where: AdditionalServiceWhereUniqueInput;
};

export type AdditionalServiceCreateWithoutAdditionalServiceVersionInput = {
  description: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type AdditionalServiceMaxAggregate = {
  __typename?: 'AdditionalServiceMaxAggregate';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type AdditionalServiceMinAggregate = {
  __typename?: 'AdditionalServiceMinAggregate';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type AdditionalServiceNullableRelationFilter = {
  is?: InputMaybe<AdditionalServiceWhereInput>;
  isNot?: InputMaybe<AdditionalServiceWhereInput>;
};

export type AdditionalServiceOrderByWithRelationInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export enum AdditionalServiceScalarFieldEnum {
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  Price = 'price'
}

export type AdditionalServiceSumAggregate = {
  __typename?: 'AdditionalServiceSumAggregate';
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

export type AdditionalServiceUpdateInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutAdditionalServiceNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type AdditionalServiceUpdateOneWithoutAdditionalServiceVersionNestedInput = {
  connect?: InputMaybe<AdditionalServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdditionalServiceCreateOrConnectWithoutAdditionalServiceVersionInput>;
  create?: InputMaybe<AdditionalServiceCreateWithoutAdditionalServiceVersionInput>;
  delete?: InputMaybe<AdditionalServiceWhereInput>;
  disconnect?: InputMaybe<AdditionalServiceWhereInput>;
  update?: InputMaybe<AdditionalServiceUpdateToOneWithWhereWithoutAdditionalServiceVersionInput>;
  upsert?: InputMaybe<AdditionalServiceUpsertWithoutAdditionalServiceVersionInput>;
};

export type AdditionalServiceUpdateToOneWithWhereWithoutAdditionalServiceVersionInput = {
  data: AdditionalServiceUpdateWithoutAdditionalServiceVersionInput;
  where?: InputMaybe<AdditionalServiceWhereInput>;
};

export type AdditionalServiceUpdateWithoutAdditionalServiceVersionInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type AdditionalServiceUpsertWithoutAdditionalServiceVersionInput = {
  create: AdditionalServiceCreateWithoutAdditionalServiceVersionInput;
  update: AdditionalServiceUpdateWithoutAdditionalServiceVersionInput;
  where?: InputMaybe<AdditionalServiceWhereInput>;
};

export type AdditionalServiceVersion = {
  __typename?: 'AdditionalServiceVersion';
  additionalService?: Maybe<AdditionalService>;
  barberEditor?: Maybe<Barber>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  versionAdditionalServiceId?: Maybe<Scalars['Int']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type AdditionalServiceVersionAdditionalServiceArgs = {
  where?: InputMaybe<AdditionalServiceWhereInput>;
};


export type AdditionalServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};

export type AdditionalServiceVersionCreateManyAdditionalServiceInput = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AdditionalServiceVersionCreateManyAdditionalServiceInputEnvelope = {
  data: Array<AdditionalServiceVersionCreateManyAdditionalServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AdditionalServiceVersionCreateManyBarberEditorInput = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  versionAdditionalServiceId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AdditionalServiceVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<AdditionalServiceVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AdditionalServiceVersionCreateNestedManyWithoutAdditionalServiceInput = {
  connect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdditionalServiceVersionCreateOrConnectWithoutAdditionalServiceInput>>;
  create?: InputMaybe<Array<AdditionalServiceVersionCreateWithoutAdditionalServiceInput>>;
  createMany?: InputMaybe<AdditionalServiceVersionCreateManyAdditionalServiceInputEnvelope>;
};

export type AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdditionalServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<AdditionalServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<AdditionalServiceVersionCreateManyBarberEditorInputEnvelope>;
};

export type AdditionalServiceVersionCreateOrConnectWithoutAdditionalServiceInput = {
  create: AdditionalServiceVersionCreateWithoutAdditionalServiceInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionCreateOrConnectWithoutBarberEditorInput = {
  create: AdditionalServiceVersionCreateWithoutBarberEditorInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionCreateWithoutAdditionalServiceInput = {
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutAdditionalServiceVersionInput>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AdditionalServiceVersionCreateWithoutBarberEditorInput = {
  additionalService?: InputMaybe<AdditionalServiceCreateNestedOneWithoutAdditionalServiceVersionInput>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AdditionalServiceVersionListRelationFilter = {
  every?: InputMaybe<AdditionalServiceVersionWhereInput>;
  none?: InputMaybe<AdditionalServiceVersionWhereInput>;
  some?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdditionalServiceVersionOrderByWithRelationInput = {
  additionalService?: InputMaybe<AdditionalServiceOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  versionAdditionalServiceId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum AdditionalServiceVersionScalarFieldEnum {
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  Price = 'price',
  VersionAdditionalServiceId = 'versionAdditionalServiceId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type AdditionalServiceVersionScalarWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionScalarWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceVersionUpdateManyMutationInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdditionalServiceVersionUpdateManyWithWhereWithoutAdditionalServiceInput = {
  data: AdditionalServiceVersionUpdateManyMutationInput;
  where: AdditionalServiceVersionScalarWhereInput;
};

export type AdditionalServiceVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: AdditionalServiceVersionUpdateManyMutationInput;
  where: AdditionalServiceVersionScalarWhereInput;
};

export type AdditionalServiceVersionUpdateManyWithoutAdditionalServiceNestedInput = {
  connect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdditionalServiceVersionCreateOrConnectWithoutAdditionalServiceInput>>;
  create?: InputMaybe<Array<AdditionalServiceVersionCreateWithoutAdditionalServiceInput>>;
  createMany?: InputMaybe<AdditionalServiceVersionCreateManyAdditionalServiceInputEnvelope>;
  delete?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdditionalServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<AdditionalServiceVersionUpdateWithWhereUniqueWithoutAdditionalServiceInput>>;
  updateMany?: InputMaybe<Array<AdditionalServiceVersionUpdateManyWithWhereWithoutAdditionalServiceInput>>;
  upsert?: InputMaybe<Array<AdditionalServiceVersionUpsertWithWhereUniqueWithoutAdditionalServiceInput>>;
};

export type AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdditionalServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<AdditionalServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<AdditionalServiceVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdditionalServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<AdditionalServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<AdditionalServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<AdditionalServiceVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<AdditionalServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type AdditionalServiceVersionUpdateWithWhereUniqueWithoutAdditionalServiceInput = {
  data: AdditionalServiceVersionUpdateWithoutAdditionalServiceInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: AdditionalServiceVersionUpdateWithoutBarberEditorInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionUpdateWithoutAdditionalServiceInput = {
  barberEditor?: InputMaybe<BarberUpdateOneWithoutAdditionalServiceVersionNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdditionalServiceVersionUpdateWithoutBarberEditorInput = {
  additionalService?: InputMaybe<AdditionalServiceUpdateOneWithoutAdditionalServiceVersionNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdditionalServiceVersionUpsertWithWhereUniqueWithoutAdditionalServiceInput = {
  create: AdditionalServiceVersionCreateWithoutAdditionalServiceInput;
  update: AdditionalServiceVersionUpdateWithoutAdditionalServiceInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: AdditionalServiceVersionCreateWithoutBarberEditorInput;
  update: AdditionalServiceVersionUpdateWithoutBarberEditorInput;
  where: AdditionalServiceVersionWhereUniqueInput;
};

export type AdditionalServiceVersionWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  additionalService?: InputMaybe<AdditionalServiceNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  additionalService?: InputMaybe<AdditionalServiceNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
};

export type AdditionalServiceWhereUniqueInput = {
  AND?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<IntFilter>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAdditionalService = {
  __typename?: 'AggregateAdditionalService';
  _avg?: Maybe<AdditionalServiceAvgAggregate>;
  _count?: Maybe<AdditionalServiceCountAggregate>;
  _max?: Maybe<AdditionalServiceMaxAggregate>;
  _min?: Maybe<AdditionalServiceMinAggregate>;
  _sum?: Maybe<AdditionalServiceSumAggregate>;
};

export type AggregateAppointment = {
  __typename?: 'AggregateAppointment';
  _avg?: Maybe<AppointmentAvgAggregate>;
  _count?: Maybe<AppointmentCountAggregate>;
  _max?: Maybe<AppointmentMaxAggregate>;
  _min?: Maybe<AppointmentMinAggregate>;
  _sum?: Maybe<AppointmentSumAggregate>;
};

export type AggregateBarber = {
  __typename?: 'AggregateBarber';
  _avg?: Maybe<BarberAvgAggregate>;
  _count?: Maybe<BarberCountAggregate>;
  _max?: Maybe<BarberMaxAggregate>;
  _min?: Maybe<BarberMinAggregate>;
  _sum?: Maybe<BarberSumAggregate>;
};

export type AggregateClient = {
  __typename?: 'AggregateClient';
  _avg?: Maybe<ClientAvgAggregate>;
  _count?: Maybe<ClientCountAggregate>;
  _max?: Maybe<ClientMaxAggregate>;
  _min?: Maybe<ClientMinAggregate>;
  _sum?: Maybe<ClientSumAggregate>;
};

export type AggregateService = {
  __typename?: 'AggregateService';
  _avg?: Maybe<ServiceAvgAggregate>;
  _count?: Maybe<ServiceCountAggregate>;
  _max?: Maybe<ServiceMaxAggregate>;
  _min?: Maybe<ServiceMinAggregate>;
  _sum?: Maybe<ServiceSumAggregate>;
};

export type Appointment = {
  __typename?: 'Appointment';
  AppointmentVersion: Array<AppointmentVersion>;
  _count?: Maybe<AppointmentCount>;
  barber: Barber;
  barberId: Scalars['Int'];
  barberNote?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  end: Scalars['DateTimeISO'];
  id: Scalars['Int'];
  isPause?: Maybe<Scalars['Boolean']>;
  phoneContact?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  repeatId?: Maybe<Scalars['String']>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  start: Scalars['DateTimeISO'];
};


export type AppointmentAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type AppointmentClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type AppointmentServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};

export type AppointmentAvgAggregate = {
  __typename?: 'AppointmentAvgAggregate';
  barberId?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Decimal']>;
  serviceId?: Maybe<Scalars['Float']>;
};

export type AppointmentCount = {
  __typename?: 'AppointmentCount';
  AppointmentVersion: Scalars['Int'];
};


export type AppointmentCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};

export type AppointmentCountAggregate = {
  __typename?: 'AppointmentCountAggregate';
  _all: Scalars['Int'];
  barberId: Scalars['Int'];
  barberNote: Scalars['Int'];
  clientId: Scalars['Int'];
  end: Scalars['Int'];
  id: Scalars['Int'];
  isPause: Scalars['Int'];
  phoneContact: Scalars['Int'];
  price: Scalars['Int'];
  repeatId: Scalars['Int'];
  serviceId: Scalars['Int'];
  start: Scalars['Int'];
};

export type AppointmentCreateInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutAppointmentInput>;
  barber: BarberCreateNestedOneWithoutAppointmentsInput;
  barberNote?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutAppointmentsInput>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutAppointmentsInput>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateManyBarberInput = {
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['Int']>;
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateManyBarberInputEnvelope = {
  data: Array<AppointmentCreateManyBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClientInput = {
  barberId: Scalars['Int'];
  barberNote?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['Int']>;
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateManyClientInputEnvelope = {
  data: Array<AppointmentCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyServiceInput = {
  barberId: Scalars['Int'];
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['Int']>;
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateManyServiceInputEnvelope = {
  data: Array<AppointmentCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentCreateNestedManyWithoutBarberInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutBarberInput>>;
  createMany?: InputMaybe<AppointmentCreateManyBarberInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AppointmentCreateManyClientInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutServiceInput>>;
  createMany?: InputMaybe<AppointmentCreateManyServiceInputEnvelope>;
};

export type AppointmentCreateNestedOneWithoutAppointmentVersionInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<AppointmentCreateWithoutAppointmentVersionInput>;
};

export type AppointmentCreateOrConnectWithoutAppointmentVersionInput = {
  create: AppointmentCreateWithoutAppointmentVersionInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutBarberInput = {
  create: AppointmentCreateWithoutBarberInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutClientInput = {
  create: AppointmentCreateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutServiceInput = {
  create: AppointmentCreateWithoutServiceInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateRepeatableInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutAppointmentInput>;
  barber: BarberCreateNestedOneWithoutAppointmentsInput;
  barberNote?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutAppointmentsInput>;
  customRepeatWeekDays: Array<Scalars['Int']>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatEndDate: Scalars['DateTimeISO'];
  repeatFrequency: Scalars['Int'];
  repeatOption: RepeatEnum;
  service?: InputMaybe<ServiceCreateNestedOneWithoutAppointmentsInput>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateWithoutAppointmentVersionInput = {
  barber: BarberCreateNestedOneWithoutAppointmentsInput;
  barberNote?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutAppointmentsInput>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutAppointmentsInput>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateWithoutBarberInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutAppointmentInput>;
  barberNote?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutAppointmentsInput>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutAppointmentsInput>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateWithoutClientInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutAppointmentInput>;
  barber: BarberCreateNestedOneWithoutAppointmentsInput;
  barberNote?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutAppointmentsInput>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentCreateWithoutServiceInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutAppointmentInput>;
  barber: BarberCreateNestedOneWithoutAppointmentsInput;
  barberNote?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutAppointmentsInput>;
  end: Scalars['DateTimeISO'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  start: Scalars['DateTimeISO'];
};

export type AppointmentListRelationFilter = {
  every?: InputMaybe<AppointmentWhereInput>;
  none?: InputMaybe<AppointmentWhereInput>;
  some?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentMaxAggregate = {
  __typename?: 'AppointmentMaxAggregate';
  barberId?: Maybe<Scalars['Int']>;
  barberNote?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTimeISO']>;
  id?: Maybe<Scalars['Int']>;
  isPause?: Maybe<Scalars['Boolean']>;
  phoneContact?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  repeatId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTimeISO']>;
};

export type AppointmentMinAggregate = {
  __typename?: 'AppointmentMinAggregate';
  barberId?: Maybe<Scalars['Int']>;
  barberNote?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTimeISO']>;
  id?: Maybe<Scalars['Int']>;
  isPause?: Maybe<Scalars['Boolean']>;
  phoneContact?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  repeatId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTimeISO']>;
};

export type AppointmentNullableRelationFilter = {
  is?: InputMaybe<AppointmentWhereInput>;
  isNot?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentOrderByWithRelationInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  barberNote?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPause?: InputMaybe<SortOrderInput>;
  phoneContact?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  repeatId?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrder>;
};

export enum AppointmentScalarFieldEnum {
  BarberId = 'barberId',
  BarberNote = 'barberNote',
  ClientId = 'clientId',
  End = 'end',
  Id = 'id',
  IsPause = 'isPause',
  PhoneContact = 'phoneContact',
  Price = 'price',
  RepeatId = 'repeatId',
  ServiceId = 'serviceId',
  Start = 'start'
}

export type AppointmentScalarWhereInput = {
  AND?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  OR?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type AppointmentSumAggregate = {
  __typename?: 'AppointmentSumAggregate';
  barberId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  serviceId?: Maybe<Scalars['Int']>;
};

export type AppointmentUpdateInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutAppointmentNestedInput>;
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutAppointmentsNestedInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutAppointmentsNestedInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutAppointmentsNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateManyMutationInput = {
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateManyWithWhereWithoutBarberInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutClientInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutServiceInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithoutBarberNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutBarberInput>>;
  createMany?: InputMaybe<AppointmentCreateManyBarberInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutBarberInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutBarberInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutBarberInput>>;
};

export type AppointmentUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AppointmentCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutClientInput>>;
};

export type AppointmentUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutServiceInput>>;
  createMany?: InputMaybe<AppointmentCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type AppointmentUpdateOneWithoutAppointmentVersionNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<AppointmentCreateWithoutAppointmentVersionInput>;
  delete?: InputMaybe<AppointmentWhereInput>;
  disconnect?: InputMaybe<AppointmentWhereInput>;
  update?: InputMaybe<AppointmentUpdateToOneWithWhereWithoutAppointmentVersionInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutAppointmentVersionInput>;
};

export type AppointmentUpdateToOneWithWhereWithoutAppointmentVersionInput = {
  data: AppointmentUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutBarberInput = {
  data: AppointmentUpdateWithoutBarberInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClientInput = {
  data: AppointmentUpdateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutServiceInput = {
  data: AppointmentUpdateWithoutServiceInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithoutAppointmentVersionInput = {
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutAppointmentsNestedInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutAppointmentsNestedInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutAppointmentsNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateWithoutBarberInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutAppointmentNestedInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutAppointmentsNestedInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutAppointmentsNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateWithoutClientInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutAppointmentNestedInput>;
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutAppointmentsNestedInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutAppointmentsNestedInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpdateWithoutServiceInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutAppointmentNestedInput>;
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutAppointmentsNestedInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutAppointmentsNestedInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutBarberInput = {
  create: AppointmentCreateWithoutBarberInput;
  update: AppointmentUpdateWithoutBarberInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClientInput = {
  create: AppointmentCreateWithoutClientInput;
  update: AppointmentUpdateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutServiceInput = {
  create: AppointmentCreateWithoutServiceInput;
  update: AppointmentUpdateWithoutServiceInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithoutAppointmentVersionInput = {
  create: AppointmentCreateWithoutAppointmentVersionInput;
  update: AppointmentUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentVersion = {
  __typename?: 'AppointmentVersion';
  appointment?: Maybe<Appointment>;
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']>;
  barberNote?: Maybe<Scalars['String']>;
  clientEditor?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: Maybe<Scalars['Boolean']>;
  phoneContact?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  repeatId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTimeISO']>;
  versionAppointmentId?: Maybe<Scalars['Int']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionClientEditorId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type AppointmentVersionAppointmentArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type AppointmentVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type AppointmentVersionClientEditorArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type AppointmentVersionCreateManyAppointmentInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionClientEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionCreateManyAppointmentInputEnvelope = {
  data: Array<AppointmentVersionCreateManyAppointmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentVersionCreateManyBarberEditorInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionAppointmentId?: InputMaybe<Scalars['Int']>;
  versionClientEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<AppointmentVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentVersionCreateManyClientEditorInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionAppointmentId?: InputMaybe<Scalars['Int']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionCreateManyClientEditorInputEnvelope = {
  data: Array<AppointmentVersionCreateManyClientEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentVersionCreateNestedManyWithoutAppointmentInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutAppointmentInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyAppointmentInputEnvelope>;
};

export type AppointmentVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyBarberEditorInputEnvelope>;
};

export type AppointmentVersionCreateNestedManyWithoutClientEditorInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutClientEditorInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutClientEditorInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyClientEditorInputEnvelope>;
};

export type AppointmentVersionCreateOrConnectWithoutAppointmentInput = {
  create: AppointmentVersionCreateWithoutAppointmentInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionCreateOrConnectWithoutBarberEditorInput = {
  create: AppointmentVersionCreateWithoutBarberEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionCreateOrConnectWithoutClientEditorInput = {
  create: AppointmentVersionCreateWithoutClientEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionCreateWithoutAppointmentInput = {
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutAppointmentVersionInput>;
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientEditor?: InputMaybe<ClientCreateNestedOneWithoutAppointmentVersionInput>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionCreateWithoutBarberEditorInput = {
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutAppointmentVersionInput>;
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientEditor?: InputMaybe<ClientCreateNestedOneWithoutAppointmentVersionInput>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionCreateWithoutClientEditorInput = {
  appointment?: InputMaybe<AppointmentCreateNestedOneWithoutAppointmentVersionInput>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutAppointmentVersionInput>;
  barberId?: InputMaybe<Scalars['Int']>;
  barberNote?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isPause?: InputMaybe<Scalars['Boolean']>;
  phoneContact?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  repeatId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type AppointmentVersionListRelationFilter = {
  every?: InputMaybe<AppointmentVersionWhereInput>;
  none?: InputMaybe<AppointmentVersionWhereInput>;
  some?: InputMaybe<AppointmentVersionWhereInput>;
};

export type AppointmentVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentVersionOrderByWithRelationInput = {
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberNote?: InputMaybe<SortOrderInput>;
  clientEditor?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPause?: InputMaybe<SortOrderInput>;
  phoneContact?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  repeatId?: InputMaybe<SortOrderInput>;
  serviceId?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
  versionAppointmentId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionClientEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum AppointmentVersionScalarFieldEnum {
  BarberId = 'barberId',
  BarberNote = 'barberNote',
  ClientId = 'clientId',
  End = 'end',
  Id = 'id',
  IsPause = 'isPause',
  PhoneContact = 'phoneContact',
  Price = 'price',
  RepeatId = 'repeatId',
  ServiceId = 'serviceId',
  Start = 'start',
  VersionAppointmentId = 'versionAppointmentId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionClientEditorId = 'versionClientEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type AppointmentVersionScalarWhereInput = {
  AND?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentVersionUpdateManyMutationInput = {
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  repeatId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentVersionUpdateManyWithWhereWithoutAppointmentInput = {
  data: AppointmentVersionUpdateManyMutationInput;
  where: AppointmentVersionScalarWhereInput;
};

export type AppointmentVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: AppointmentVersionUpdateManyMutationInput;
  where: AppointmentVersionScalarWhereInput;
};

export type AppointmentVersionUpdateManyWithWhereWithoutClientEditorInput = {
  data: AppointmentVersionUpdateManyMutationInput;
  where: AppointmentVersionScalarWhereInput;
};

export type AppointmentVersionUpdateManyWithoutAppointmentNestedInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutAppointmentInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyAppointmentInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentVersionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: InputMaybe<Array<AppointmentVersionUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: InputMaybe<Array<AppointmentVersionUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type AppointmentVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<AppointmentVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<AppointmentVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type AppointmentVersionUpdateManyWithoutClientEditorNestedInput = {
  connect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentVersionCreateOrConnectWithoutClientEditorInput>>;
  create?: InputMaybe<Array<AppointmentVersionCreateWithoutClientEditorInput>>;
  createMany?: InputMaybe<AppointmentVersionCreateManyClientEditorInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentVersionUpdateWithWhereUniqueWithoutClientEditorInput>>;
  updateMany?: InputMaybe<Array<AppointmentVersionUpdateManyWithWhereWithoutClientEditorInput>>;
  upsert?: InputMaybe<Array<AppointmentVersionUpsertWithWhereUniqueWithoutClientEditorInput>>;
};

export type AppointmentVersionUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: AppointmentVersionUpdateWithoutAppointmentInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: AppointmentVersionUpdateWithoutBarberEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionUpdateWithWhereUniqueWithoutClientEditorInput = {
  data: AppointmentVersionUpdateWithoutClientEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionUpdateWithoutAppointmentInput = {
  barberEditor?: InputMaybe<BarberUpdateOneWithoutAppointmentVersionNestedInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditor?: InputMaybe<ClientUpdateOneWithoutAppointmentVersionNestedInput>;
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  repeatId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentVersionUpdateWithoutBarberEditorInput = {
  appointment?: InputMaybe<AppointmentUpdateOneWithoutAppointmentVersionNestedInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditor?: InputMaybe<ClientUpdateOneWithoutAppointmentVersionNestedInput>;
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  repeatId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentVersionUpdateWithoutClientEditorInput = {
  appointment?: InputMaybe<AppointmentUpdateOneWithoutAppointmentVersionNestedInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutAppointmentVersionNestedInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isPause?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phoneContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  repeatId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AppointmentVersionUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: AppointmentVersionCreateWithoutAppointmentInput;
  update: AppointmentVersionUpdateWithoutAppointmentInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: AppointmentVersionCreateWithoutBarberEditorInput;
  update: AppointmentVersionUpdateWithoutBarberEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionUpsertWithWhereUniqueWithoutClientEditorInput = {
  create: AppointmentVersionCreateWithoutClientEditorInput;
  update: AppointmentVersionUpdateWithoutClientEditorInput;
  where: AppointmentVersionWhereUniqueInput;
};

export type AppointmentVersionWhereInput = {
  AND?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  appointment?: InputMaybe<AppointmentNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  appointment?: InputMaybe<AppointmentNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export enum AuditOperation {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type Barber = {
  __typename?: 'Barber';
  AdditionalServiceVersion: Array<AdditionalServiceVersion>;
  AppointmentVersion: Array<AppointmentVersion>;
  BarberEditorVersion: Array<BarberVersion>;
  BarberServiceVersion: Array<BarberServiceVersion>;
  BarberVersion: Array<BarberVersion>;
  BarberWorkTimeOverrideVersion: Array<BarberWorkTimeOverrideVersion>;
  BarberWorkTimeVersion: Array<BarberWorkTimeVersion>;
  Client: Array<Client>;
  ClientVersion: Array<ClientVersion>;
  PenaltyVersion: Array<PenaltyVersion>;
  ServiceVersion: Array<ServiceVersion>;
  _count?: Maybe<BarberCount>;
  active: Scalars['Boolean'];
  appointments: Array<Appointment>;
  barberServices: Array<BarberService>;
  barberWorkTimeOverrides: Array<BarberWorkTimeOverride>;
  barberWorkTimes: Array<BarberWorkTime>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: BarberType;
};


export type BarberAdditionalServiceVersionArgs = {
  cursor?: InputMaybe<AdditionalServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdditionalServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdditionalServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};


export type BarberAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type BarberBarberEditorVersionArgs = {
  cursor?: InputMaybe<BarberVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberBarberServiceVersionArgs = {
  cursor?: InputMaybe<BarberServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};


export type BarberBarberVersionArgs = {
  cursor?: InputMaybe<BarberVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberBarberWorkTimeOverrideVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};


export type BarberBarberWorkTimeVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};


export type BarberClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type BarberClientVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type BarberPenaltyVersionArgs = {
  cursor?: InputMaybe<PenaltyVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PenaltyVersionWhereInput>;
};


export type BarberServiceVersionArgs = {
  cursor?: InputMaybe<ServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type BarberAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type BarberBarberServicesArgs = {
  cursor?: InputMaybe<BarberServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberServiceWhereInput>;
};


export type BarberBarberWorkTimeOverridesArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};


export type BarberBarberWorkTimesArgs = {
  cursor?: InputMaybe<BarberWorkTimeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberAvgAggregate = {
  __typename?: 'BarberAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BarberCount = {
  __typename?: 'BarberCount';
  AdditionalServiceVersion: Scalars['Int'];
  AppointmentVersion: Scalars['Int'];
  BarberEditorVersion: Scalars['Int'];
  BarberServiceVersion: Scalars['Int'];
  BarberVersion: Scalars['Int'];
  BarberWorkTimeOverrideVersion: Scalars['Int'];
  BarberWorkTimeVersion: Scalars['Int'];
  Client: Scalars['Int'];
  ClientVersion: Scalars['Int'];
  PenaltyVersion: Scalars['Int'];
  ServiceVersion: Scalars['Int'];
  appointments: Scalars['Int'];
  barberServices: Scalars['Int'];
  barberWorkTimeOverrides: Scalars['Int'];
  barberWorkTimes: Scalars['Int'];
};


export type BarberCountAdditionalServiceVersionArgs = {
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};


export type BarberCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type BarberCountBarberEditorVersionArgs = {
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberCountBarberServiceVersionArgs = {
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};


export type BarberCountBarberVersionArgs = {
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberCountBarberWorkTimeOverrideVersionArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};


export type BarberCountBarberWorkTimeVersionArgs = {
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};


export type BarberCountClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type BarberCountClientVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type BarberCountPenaltyVersionArgs = {
  where?: InputMaybe<PenaltyVersionWhereInput>;
};


export type BarberCountServiceVersionArgs = {
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type BarberCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type BarberCountBarberServicesArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};


export type BarberCountBarberWorkTimeOverridesArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};


export type BarberCountBarberWorkTimesArgs = {
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberCountAggregate = {
  __typename?: 'BarberCountAggregate';
  _all: Scalars['Int'];
  active: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  uid: Scalars['Int'];
};

export type BarberCreateNestedOneWithoutAdditionalServiceVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAdditionalServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutAdditionalServiceVersionInput>;
};

export type BarberCreateNestedOneWithoutAppointmentVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<BarberCreateWithoutAppointmentVersionInput>;
};

export type BarberCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<BarberCreateWithoutAppointmentsInput>;
};

export type BarberCreateNestedOneWithoutBarberEditorVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberEditorVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberEditorVersionInput>;
};

export type BarberCreateNestedOneWithoutBarberServiceVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberServiceVersionInput>;
};

export type BarberCreateNestedOneWithoutBarberServicesInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberServicesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberServicesInput>;
};

export type BarberCreateNestedOneWithoutBarberVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberVersionInput>;
};

export type BarberCreateNestedOneWithoutBarberWorkTimeOverrideVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeOverrideVersionInput>;
};

export type BarberCreateNestedOneWithoutBarberWorkTimeOverridesInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeOverridesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeOverridesInput>;
};

export type BarberCreateNestedOneWithoutBarberWorkTimeVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeVersionInput>;
};

export type BarberCreateNestedOneWithoutBarberWorkTimesInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimesInput>;
};

export type BarberCreateNestedOneWithoutClientInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<BarberCreateWithoutClientInput>;
};

export type BarberCreateNestedOneWithoutClientVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutClientVersionInput>;
  create?: InputMaybe<BarberCreateWithoutClientVersionInput>;
};

export type BarberCreateNestedOneWithoutPenaltyVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutPenaltyVersionInput>;
  create?: InputMaybe<BarberCreateWithoutPenaltyVersionInput>;
};

export type BarberCreateNestedOneWithoutServiceVersionInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutServiceVersionInput>;
};

export type BarberCreateOrConnectWithoutAdditionalServiceVersionInput = {
  create: BarberCreateWithoutAdditionalServiceVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutAppointmentVersionInput = {
  create: BarberCreateWithoutAppointmentVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutAppointmentsInput = {
  create: BarberCreateWithoutAppointmentsInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberEditorVersionInput = {
  create: BarberCreateWithoutBarberEditorVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberServiceVersionInput = {
  create: BarberCreateWithoutBarberServiceVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberServicesInput = {
  create: BarberCreateWithoutBarberServicesInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberVersionInput = {
  create: BarberCreateWithoutBarberVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput = {
  create: BarberCreateWithoutBarberWorkTimeOverrideVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberWorkTimeOverridesInput = {
  create: BarberCreateWithoutBarberWorkTimeOverridesInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberWorkTimeVersionInput = {
  create: BarberCreateWithoutBarberWorkTimeVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutBarberWorkTimesInput = {
  create: BarberCreateWithoutBarberWorkTimesInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutClientInput = {
  create: BarberCreateWithoutClientInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutClientVersionInput = {
  create: BarberCreateWithoutClientVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutPenaltyVersionInput = {
  create: BarberCreateWithoutPenaltyVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateOrConnectWithoutServiceVersionInput = {
  create: BarberCreateWithoutServiceVersionInput;
  where: BarberWhereUniqueInput;
};

export type BarberCreateWithoutAdditionalServiceVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutAppointmentVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutAppointmentsInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberEditorVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberServiceVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberServicesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberWorkTimeOverrideVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberWorkTimeOverridesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberWorkTimeVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutBarberWorkTimesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutClientInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutClientVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutPenaltyVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberCreateWithoutServiceVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  name: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type BarberMaxAggregate = {
  __typename?: 'BarberMaxAggregate';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BarberType>;
  uid?: Maybe<Scalars['String']>;
};

export type BarberMinAggregate = {
  __typename?: 'BarberMinAggregate';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BarberType>;
  uid?: Maybe<Scalars['String']>;
};

export type BarberNullableRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export type BarberOrderByWithRelationInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionOrderByRelationAggregateInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionOrderByRelationAggregateInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionOrderByRelationAggregateInput>;
  BarberVersion?: InputMaybe<BarberVersionOrderByRelationAggregateInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionOrderByRelationAggregateInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionOrderByRelationAggregateInput>;
  Client?: InputMaybe<ClientOrderByRelationAggregateInput>;
  ClientVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionOrderByRelationAggregateInput>;
  ServiceVersion?: InputMaybe<ServiceVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  barberServices?: InputMaybe<BarberServiceOrderByRelationAggregateInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideOrderByRelationAggregateInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
};

export type BarberRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export enum BarberScalarFieldEnum {
  Active = 'active',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  Uid = 'uid'
}

export type BarberService = {
  __typename?: 'BarberService';
  BarberServiceVersion: Array<BarberServiceVersion>;
  _count?: Maybe<BarberServiceCount>;
  assignedAt: Scalars['DateTimeISO'];
  barber: Barber;
  barberId: Scalars['Int'];
  id: Scalars['Int'];
  service: Service;
  serviceId: Scalars['Int'];
};


export type BarberServiceBarberServiceVersionArgs = {
  cursor?: InputMaybe<BarberServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceBarberIdServiceIdCompoundUniqueInput = {
  barberId: Scalars['Int'];
  serviceId: Scalars['Int'];
};

export type BarberServiceCount = {
  __typename?: 'BarberServiceCount';
  BarberServiceVersion: Scalars['Int'];
};


export type BarberServiceCountBarberServiceVersionArgs = {
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceCreateManyBarberInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['Int']>;
  serviceId: Scalars['Int'];
};

export type BarberServiceCreateManyBarberInputEnvelope = {
  data: Array<BarberServiceCreateManyBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberServiceCreateManyServiceInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barberId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
};

export type BarberServiceCreateManyServiceInputEnvelope = {
  data: Array<BarberServiceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberServiceCreateNestedManyWithoutBarberInput = {
  connect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberServiceCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberServiceCreateManyBarberInputEnvelope>;
};

export type BarberServiceCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<BarberServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<BarberServiceCreateManyServiceInputEnvelope>;
};

export type BarberServiceCreateNestedOneWithoutBarberServiceVersionInput = {
  connect?: InputMaybe<BarberServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberServiceCreateOrConnectWithoutBarberServiceVersionInput>;
  create?: InputMaybe<BarberServiceCreateWithoutBarberServiceVersionInput>;
};

export type BarberServiceCreateOrConnectWithoutBarberInput = {
  create: BarberServiceCreateWithoutBarberInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceCreateOrConnectWithoutBarberServiceVersionInput = {
  create: BarberServiceCreateWithoutBarberServiceVersionInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceCreateOrConnectWithoutServiceInput = {
  create: BarberServiceCreateWithoutServiceInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceCreateWithoutBarberInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberServiceInput>;
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  service: ServiceCreateNestedOneWithoutBarberServicesInput;
};

export type BarberServiceCreateWithoutBarberServiceVersionInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barber: BarberCreateNestedOneWithoutBarberServicesInput;
  service: ServiceCreateNestedOneWithoutBarberServicesInput;
};

export type BarberServiceCreateWithoutServiceInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberServiceInput>;
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barber: BarberCreateNestedOneWithoutBarberServicesInput;
};

export type BarberServiceListRelationFilter = {
  every?: InputMaybe<BarberServiceWhereInput>;
  none?: InputMaybe<BarberServiceWhereInput>;
  some?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceNullableRelationFilter = {
  is?: InputMaybe<BarberServiceWhereInput>;
  isNot?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberServiceOrderByWithRelationInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionOrderByRelationAggregateInput>;
  assignedAt?: InputMaybe<SortOrder>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrder>;
};

export enum BarberServiceScalarFieldEnum {
  AssignedAt = 'assignedAt',
  BarberId = 'barberId',
  Id = 'id',
  ServiceId = 'serviceId'
}

export type BarberServiceScalarWhereInput = {
  AND?: InputMaybe<Array<BarberServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barberId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export type BarberServiceUpdateManyMutationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberServiceUpdateManyWithWhereWithoutBarberInput = {
  data: BarberServiceUpdateManyMutationInput;
  where: BarberServiceScalarWhereInput;
};

export type BarberServiceUpdateManyWithWhereWithoutServiceInput = {
  data: BarberServiceUpdateManyMutationInput;
  where: BarberServiceScalarWhereInput;
};

export type BarberServiceUpdateManyWithoutBarberNestedInput = {
  connect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberServiceCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberServiceCreateManyBarberInputEnvelope>;
  delete?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberServiceUpdateWithWhereUniqueWithoutBarberInput>>;
  updateMany?: InputMaybe<Array<BarberServiceUpdateManyWithWhereWithoutBarberInput>>;
  upsert?: InputMaybe<Array<BarberServiceUpsertWithWhereUniqueWithoutBarberInput>>;
};

export type BarberServiceUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<BarberServiceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<BarberServiceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<BarberServiceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<BarberServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type BarberServiceUpdateOneWithoutBarberServiceVersionNestedInput = {
  connect?: InputMaybe<BarberServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberServiceCreateOrConnectWithoutBarberServiceVersionInput>;
  create?: InputMaybe<BarberServiceCreateWithoutBarberServiceVersionInput>;
  delete?: InputMaybe<BarberServiceWhereInput>;
  disconnect?: InputMaybe<BarberServiceWhereInput>;
  update?: InputMaybe<BarberServiceUpdateToOneWithWhereWithoutBarberServiceVersionInput>;
  upsert?: InputMaybe<BarberServiceUpsertWithoutBarberServiceVersionInput>;
};

export type BarberServiceUpdateToOneWithWhereWithoutBarberServiceVersionInput = {
  data: BarberServiceUpdateWithoutBarberServiceVersionInput;
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceUpdateWithWhereUniqueWithoutBarberInput = {
  data: BarberServiceUpdateWithoutBarberInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: BarberServiceUpdateWithoutServiceInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceUpdateWithoutBarberInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberServiceNestedInput>;
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutBarberServicesNestedInput>;
};

export type BarberServiceUpdateWithoutBarberServiceVersionInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutBarberServicesNestedInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutBarberServicesNestedInput>;
};

export type BarberServiceUpdateWithoutServiceInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberServiceNestedInput>;
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutBarberServicesNestedInput>;
};

export type BarberServiceUpsertWithWhereUniqueWithoutBarberInput = {
  create: BarberServiceCreateWithoutBarberInput;
  update: BarberServiceUpdateWithoutBarberInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: BarberServiceCreateWithoutServiceInput;
  update: BarberServiceUpdateWithoutServiceInput;
  where: BarberServiceWhereUniqueInput;
};

export type BarberServiceUpsertWithoutBarberServiceVersionInput = {
  create: BarberServiceCreateWithoutBarberServiceVersionInput;
  update: BarberServiceUpdateWithoutBarberServiceVersionInput;
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceVersion = {
  __typename?: 'BarberServiceVersion';
  assignedAt?: Maybe<Scalars['DateTimeISO']>;
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']>;
  barberService?: Maybe<BarberService>;
  id: Scalars['Int'];
  serviceId?: Maybe<Scalars['Int']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionBarberServiceId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type BarberServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberServiceVersionBarberServiceArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceVersionCreateManyBarberEditorInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barberId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  serviceId?: InputMaybe<Scalars['Int']>;
  versionBarberServiceId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberServiceVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<BarberServiceVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberServiceVersionCreateManyBarberServiceInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barberId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  serviceId?: InputMaybe<Scalars['Int']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberServiceVersionCreateManyBarberServiceInputEnvelope = {
  data: Array<BarberServiceVersionCreateManyBarberServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberServiceVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberServiceVersionCreateManyBarberEditorInputEnvelope>;
};

export type BarberServiceVersionCreateNestedManyWithoutBarberServiceInput = {
  connect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceVersionCreateOrConnectWithoutBarberServiceInput>>;
  create?: InputMaybe<Array<BarberServiceVersionCreateWithoutBarberServiceInput>>;
  createMany?: InputMaybe<BarberServiceVersionCreateManyBarberServiceInputEnvelope>;
};

export type BarberServiceVersionCreateOrConnectWithoutBarberEditorInput = {
  create: BarberServiceVersionCreateWithoutBarberEditorInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionCreateOrConnectWithoutBarberServiceInput = {
  create: BarberServiceVersionCreateWithoutBarberServiceInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionCreateWithoutBarberEditorInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barberId?: InputMaybe<Scalars['Int']>;
  barberService?: InputMaybe<BarberServiceCreateNestedOneWithoutBarberServiceVersionInput>;
  id: Scalars['Int'];
  serviceId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberServiceVersionCreateWithoutBarberServiceInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutBarberServiceVersionInput>;
  barberId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  serviceId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberServiceVersionListRelationFilter = {
  every?: InputMaybe<BarberServiceVersionWhereInput>;
  none?: InputMaybe<BarberServiceVersionWhereInput>;
  some?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberServiceVersionOrderByWithRelationInput = {
  assignedAt?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberService?: InputMaybe<BarberServiceOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberServiceId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberServiceVersionScalarFieldEnum {
  AssignedAt = 'assignedAt',
  BarberId = 'barberId',
  Id = 'id',
  ServiceId = 'serviceId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberServiceId = 'versionBarberServiceId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberServiceVersionScalarWhereInput = {
  AND?: InputMaybe<Array<BarberServiceVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceVersionUpdateManyMutationInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberServiceVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: BarberServiceVersionUpdateManyMutationInput;
  where: BarberServiceVersionScalarWhereInput;
};

export type BarberServiceVersionUpdateManyWithWhereWithoutBarberServiceInput = {
  data: BarberServiceVersionUpdateManyMutationInput;
  where: BarberServiceVersionScalarWhereInput;
};

export type BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberServiceVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<BarberServiceVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<BarberServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type BarberServiceVersionUpdateManyWithoutBarberServiceNestedInput = {
  connect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberServiceVersionCreateOrConnectWithoutBarberServiceInput>>;
  create?: InputMaybe<Array<BarberServiceVersionCreateWithoutBarberServiceInput>>;
  createMany?: InputMaybe<BarberServiceVersionCreateManyBarberServiceInputEnvelope>;
  delete?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberServiceVersionUpdateWithWhereUniqueWithoutBarberServiceInput>>;
  updateMany?: InputMaybe<Array<BarberServiceVersionUpdateManyWithWhereWithoutBarberServiceInput>>;
  upsert?: InputMaybe<Array<BarberServiceVersionUpsertWithWhereUniqueWithoutBarberServiceInput>>;
};

export type BarberServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: BarberServiceVersionUpdateWithoutBarberEditorInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionUpdateWithWhereUniqueWithoutBarberServiceInput = {
  data: BarberServiceVersionUpdateWithoutBarberServiceInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionUpdateWithoutBarberEditorInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberService?: InputMaybe<BarberServiceUpdateOneWithoutBarberServiceVersionNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberServiceVersionUpdateWithoutBarberServiceInput = {
  assignedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutBarberServiceVersionNestedInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  serviceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: BarberServiceVersionCreateWithoutBarberEditorInput;
  update: BarberServiceVersionUpdateWithoutBarberEditorInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionUpsertWithWhereUniqueWithoutBarberServiceInput = {
  create: BarberServiceVersionCreateWithoutBarberServiceInput;
  update: BarberServiceVersionUpdateWithoutBarberServiceInput;
  where: BarberServiceVersionWhereUniqueInput;
};

export type BarberServiceVersionWhereInput = {
  AND?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberService?: InputMaybe<BarberServiceNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberService?: InputMaybe<BarberServiceNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceWhereInput = {
  AND?: InputMaybe<Array<BarberServiceWhereInput>>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberServiceWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export type BarberServiceWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberServiceWhereInput>>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberServiceWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberId_serviceId?: InputMaybe<BarberServiceBarberIdServiceIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export type BarberSumAggregate = {
  __typename?: 'BarberSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export enum BarberType {
  Admin = 'ADMIN',
  Normal = 'NORMAL'
}

export type BarberUpdateInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateOneRequiredWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<BarberCreateWithoutAppointmentsInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutAppointmentsInput>;
  upsert?: InputMaybe<BarberUpsertWithoutAppointmentsInput>;
};

export type BarberUpdateOneRequiredWithoutBarberServicesNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberServicesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberServicesInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberServicesInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberServicesInput>;
};

export type BarberUpdateOneRequiredWithoutBarberWorkTimeOverridesNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeOverridesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeOverridesInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberWorkTimeOverridesInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberWorkTimeOverridesInput>;
};

export type BarberUpdateOneRequiredWithoutBarberWorkTimesNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimesInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimesInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberWorkTimesInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberWorkTimesInput>;
};

export type BarberUpdateOneWithoutAdditionalServiceVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAdditionalServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutAdditionalServiceVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutAdditionalServiceVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutAdditionalServiceVersionInput>;
};

export type BarberUpdateOneWithoutAppointmentVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<BarberCreateWithoutAppointmentVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutAppointmentVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutAppointmentVersionInput>;
};

export type BarberUpdateOneWithoutBarberEditorVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberEditorVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberEditorVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberEditorVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberEditorVersionInput>;
};

export type BarberUpdateOneWithoutBarberServiceVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberServiceVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberServiceVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberServiceVersionInput>;
};

export type BarberUpdateOneWithoutBarberVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberVersionInput>;
};

export type BarberUpdateOneWithoutBarberWorkTimeOverrideVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeOverrideVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberWorkTimeOverrideVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberWorkTimeOverrideVersionInput>;
};

export type BarberUpdateOneWithoutBarberWorkTimeVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutBarberWorkTimeVersionInput>;
  create?: InputMaybe<BarberCreateWithoutBarberWorkTimeVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutBarberWorkTimeVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutBarberWorkTimeVersionInput>;
};

export type BarberUpdateOneWithoutClientNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<BarberCreateWithoutClientInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutClientInput>;
  upsert?: InputMaybe<BarberUpsertWithoutClientInput>;
};

export type BarberUpdateOneWithoutClientVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutClientVersionInput>;
  create?: InputMaybe<BarberCreateWithoutClientVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutClientVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutClientVersionInput>;
};

export type BarberUpdateOneWithoutPenaltyVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutPenaltyVersionInput>;
  create?: InputMaybe<BarberCreateWithoutPenaltyVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutPenaltyVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutPenaltyVersionInput>;
};

export type BarberUpdateOneWithoutServiceVersionNestedInput = {
  connect?: InputMaybe<BarberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberCreateOrConnectWithoutServiceVersionInput>;
  create?: InputMaybe<BarberCreateWithoutServiceVersionInput>;
  delete?: InputMaybe<BarberWhereInput>;
  disconnect?: InputMaybe<BarberWhereInput>;
  update?: InputMaybe<BarberUpdateToOneWithWhereWithoutServiceVersionInput>;
  upsert?: InputMaybe<BarberUpsertWithoutServiceVersionInput>;
};

export type BarberUpdateToOneWithWhereWithoutAdditionalServiceVersionInput = {
  data: BarberUpdateWithoutAdditionalServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutAppointmentVersionInput = {
  data: BarberUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutAppointmentsInput = {
  data: BarberUpdateWithoutAppointmentsInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberEditorVersionInput = {
  data: BarberUpdateWithoutBarberEditorVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberServiceVersionInput = {
  data: BarberUpdateWithoutBarberServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberServicesInput = {
  data: BarberUpdateWithoutBarberServicesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberVersionInput = {
  data: BarberUpdateWithoutBarberVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberWorkTimeOverrideVersionInput = {
  data: BarberUpdateWithoutBarberWorkTimeOverrideVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberWorkTimeOverridesInput = {
  data: BarberUpdateWithoutBarberWorkTimeOverridesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberWorkTimeVersionInput = {
  data: BarberUpdateWithoutBarberWorkTimeVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutBarberWorkTimesInput = {
  data: BarberUpdateWithoutBarberWorkTimesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutClientInput = {
  data: BarberUpdateWithoutClientInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutClientVersionInput = {
  data: BarberUpdateWithoutClientVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutPenaltyVersionInput = {
  data: BarberUpdateWithoutPenaltyVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateToOneWithWhereWithoutServiceVersionInput = {
  data: BarberUpdateWithoutServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpdateWithoutAdditionalServiceVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutAppointmentVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutAppointmentsInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberEditorVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberServiceVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberServicesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberWorkTimeOverrideVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberWorkTimeOverridesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberWorkTimeVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutBarberWorkTimesInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutClientInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutClientVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutPenaltyVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpdateWithoutServiceVersionInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberVersion?: InputMaybe<BarberVersionUpdateManyWithoutBarberNestedInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput>;
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteBarberNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutBarberEditorNestedInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutBarberEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutBarberNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutBarberNestedInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeUpdateManyWithoutBarberNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBarberTypeFieldUpdateOperationsInput>;
};

export type BarberUpsertWithoutAdditionalServiceVersionInput = {
  create: BarberCreateWithoutAdditionalServiceVersionInput;
  update: BarberUpdateWithoutAdditionalServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutAppointmentVersionInput = {
  create: BarberCreateWithoutAppointmentVersionInput;
  update: BarberUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutAppointmentsInput = {
  create: BarberCreateWithoutAppointmentsInput;
  update: BarberUpdateWithoutAppointmentsInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberEditorVersionInput = {
  create: BarberCreateWithoutBarberEditorVersionInput;
  update: BarberUpdateWithoutBarberEditorVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberServiceVersionInput = {
  create: BarberCreateWithoutBarberServiceVersionInput;
  update: BarberUpdateWithoutBarberServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberServicesInput = {
  create: BarberCreateWithoutBarberServicesInput;
  update: BarberUpdateWithoutBarberServicesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberVersionInput = {
  create: BarberCreateWithoutBarberVersionInput;
  update: BarberUpdateWithoutBarberVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberWorkTimeOverrideVersionInput = {
  create: BarberCreateWithoutBarberWorkTimeOverrideVersionInput;
  update: BarberUpdateWithoutBarberWorkTimeOverrideVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberWorkTimeOverridesInput = {
  create: BarberCreateWithoutBarberWorkTimeOverridesInput;
  update: BarberUpdateWithoutBarberWorkTimeOverridesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberWorkTimeVersionInput = {
  create: BarberCreateWithoutBarberWorkTimeVersionInput;
  update: BarberUpdateWithoutBarberWorkTimeVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutBarberWorkTimesInput = {
  create: BarberCreateWithoutBarberWorkTimesInput;
  update: BarberUpdateWithoutBarberWorkTimesInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutClientInput = {
  create: BarberCreateWithoutClientInput;
  update: BarberUpdateWithoutClientInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutClientVersionInput = {
  create: BarberCreateWithoutClientVersionInput;
  update: BarberUpdateWithoutClientVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutPenaltyVersionInput = {
  create: BarberCreateWithoutPenaltyVersionInput;
  update: BarberUpdateWithoutPenaltyVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberUpsertWithoutServiceVersionInput = {
  create: BarberCreateWithoutServiceVersionInput;
  update: BarberUpdateWithoutServiceVersionInput;
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberVersion = {
  __typename?: 'BarberVersion';
  active?: Maybe<Scalars['Boolean']>;
  barber?: Maybe<Barber>;
  barberEditor?: Maybe<Barber>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BarberType>;
  uid?: Maybe<Scalars['String']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionBarberId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type BarberVersionBarberArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberVersionCreateManyBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BarberType>;
  uid?: InputMaybe<Scalars['String']>;
  versionBarberId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<BarberVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberVersionCreateManyBarberInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BarberType>;
  uid?: InputMaybe<Scalars['String']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberVersionCreateManyBarberInputEnvelope = {
  data: Array<BarberVersionCreateManyBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberVersionCreateManyBarberEditorInputEnvelope>;
};

export type BarberVersionCreateNestedManyWithoutBarberInput = {
  connect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberVersionCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberVersionCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberVersionCreateManyBarberInputEnvelope>;
};

export type BarberVersionCreateOrConnectWithoutBarberEditorInput = {
  create: BarberVersionCreateWithoutBarberEditorInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionCreateOrConnectWithoutBarberInput = {
  create: BarberVersionCreateWithoutBarberInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionCreateWithoutBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  barber?: InputMaybe<BarberCreateNestedOneWithoutBarberVersionInput>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BarberType>;
  uid?: InputMaybe<Scalars['String']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberVersionCreateWithoutBarberInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutBarberEditorVersionInput>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BarberType>;
  uid?: InputMaybe<Scalars['String']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberVersionListRelationFilter = {
  every?: InputMaybe<BarberVersionWhereInput>;
  none?: InputMaybe<BarberVersionWhereInput>;
  some?: InputMaybe<BarberVersionWhereInput>;
};

export type BarberVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  uid?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberVersionScalarFieldEnum {
  Active = 'active',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  Uid = 'uid',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberId = 'versionBarberId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberVersionScalarWhereInput = {
  AND?: InputMaybe<Array<BarberVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionScalarWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberVersionUpdateManyMutationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumBarberTypeFieldUpdateOperationsInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: BarberVersionUpdateManyMutationInput;
  where: BarberVersionScalarWhereInput;
};

export type BarberVersionUpdateManyWithWhereWithoutBarberInput = {
  data: BarberVersionUpdateManyMutationInput;
  where: BarberVersionScalarWhereInput;
};

export type BarberVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<BarberVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<BarberVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type BarberVersionUpdateManyWithoutBarberNestedInput = {
  connect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberVersionCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberVersionCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberVersionCreateManyBarberInputEnvelope>;
  delete?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberVersionUpdateWithWhereUniqueWithoutBarberInput>>;
  updateMany?: InputMaybe<Array<BarberVersionUpdateManyWithWhereWithoutBarberInput>>;
  upsert?: InputMaybe<Array<BarberVersionUpsertWithWhereUniqueWithoutBarberInput>>;
};

export type BarberVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: BarberVersionUpdateWithoutBarberEditorInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionUpdateWithWhereUniqueWithoutBarberInput = {
  data: BarberVersionUpdateWithoutBarberInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionUpdateWithoutBarberEditorInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  barber?: InputMaybe<BarberUpdateOneWithoutBarberVersionNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumBarberTypeFieldUpdateOperationsInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberVersionUpdateWithoutBarberInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutBarberEditorVersionNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumBarberTypeFieldUpdateOperationsInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: BarberVersionCreateWithoutBarberEditorInput;
  update: BarberVersionUpdateWithoutBarberEditorInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionUpsertWithWhereUniqueWithoutBarberInput = {
  create: BarberVersionCreateWithoutBarberInput;
  update: BarberVersionUpdateWithoutBarberInput;
  where: BarberVersionWhereUniqueInput;
};

export type BarberVersionWhereInput = {
  AND?: InputMaybe<Array<BarberVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barber?: InputMaybe<BarberNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barber?: InputMaybe<BarberNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWhereInput = {
  AND?: InputMaybe<Array<BarberWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  BarberEditorVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  BarberVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  Client?: InputMaybe<ClientListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWhereInput>>;
  OR?: InputMaybe<Array<BarberWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideListRelationFilter>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBarberTypeFilter>;
  uid?: InputMaybe<StringFilter>;
};

export type BarberWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  BarberEditorVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  BarberVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  Client?: InputMaybe<ClientListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWhereInput>>;
  OR?: InputMaybe<Array<BarberWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideListRelationFilter>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeListRelationFilter>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBarberTypeFilter>;
  uid?: InputMaybe<Scalars['String']>;
};

export type BarberWorkTime = {
  __typename?: 'BarberWorkTime';
  BarberWorkTimeVersion: Array<BarberWorkTimeVersion>;
  _count?: Maybe<BarberWorkTimeCount>;
  barber: Barber;
  barberId: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  end: Scalars['DateTimeISO'];
  id: Scalars['Int'];
  start: Scalars['DateTimeISO'];
};


export type BarberWorkTimeBarberWorkTimeVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeBarberIdDayOfWeekStartEndCompoundUniqueInput = {
  barberId: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  end: Scalars['DateTimeISO'];
  start: Scalars['DateTimeISO'];
};

export type BarberWorkTimeCount = {
  __typename?: 'BarberWorkTimeCount';
  BarberWorkTimeVersion: Scalars['Int'];
};


export type BarberWorkTimeCountBarberWorkTimeVersionArgs = {
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeCreateManyBarberInput = {
  dayOfWeek: Scalars['Int'];
  end: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTimeISO'];
};

export type BarberWorkTimeCreateManyBarberInputEnvelope = {
  data: Array<BarberWorkTimeCreateManyBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeCreateNestedManyWithoutBarberInput = {
  connect?: InputMaybe<Array<BarberWorkTimeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberWorkTimeCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberWorkTimeCreateManyBarberInputEnvelope>;
};

export type BarberWorkTimeCreateNestedOneWithoutBarberWorkTimeVersionInput = {
  connect?: InputMaybe<BarberWorkTimeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberWorkTimeCreateOrConnectWithoutBarberWorkTimeVersionInput>;
  create?: InputMaybe<BarberWorkTimeCreateWithoutBarberWorkTimeVersionInput>;
};

export type BarberWorkTimeCreateOrConnectWithoutBarberInput = {
  create: BarberWorkTimeCreateWithoutBarberInput;
  where: BarberWorkTimeWhereUniqueInput;
};

export type BarberWorkTimeCreateOrConnectWithoutBarberWorkTimeVersionInput = {
  create: BarberWorkTimeCreateWithoutBarberWorkTimeVersionInput;
  where: BarberWorkTimeWhereUniqueInput;
};

export type BarberWorkTimeCreateWithoutBarberInput = {
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberWorkTimeInput>;
  dayOfWeek: Scalars['Int'];
  end: Scalars['DateTimeISO'];
  start: Scalars['DateTimeISO'];
};

export type BarberWorkTimeCreateWithoutBarberWorkTimeVersionInput = {
  barber: BarberCreateNestedOneWithoutBarberWorkTimesInput;
  dayOfWeek: Scalars['Int'];
  end: Scalars['DateTimeISO'];
  start: Scalars['DateTimeISO'];
};

export type BarberWorkTimeListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeWhereInput>;
  none?: InputMaybe<BarberWorkTimeWhereInput>;
  some?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOrderByWithRelationInput = {
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  dayOfWeek?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverride = {
  __typename?: 'BarberWorkTimeOverride';
  BarberWorkTimeOverrideVersion: Array<BarberWorkTimeOverrideVersion>;
  _count?: Maybe<BarberWorkTimeOverrideCount>;
  barber: Barber;
  barberId: Scalars['Int'];
  date: Scalars['DateTimeISO'];
  end?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTimeISO']>;
};


export type BarberWorkTimeOverrideBarberWorkTimeOverrideVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideCount = {
  __typename?: 'BarberWorkTimeOverrideCount';
  BarberWorkTimeOverrideVersion: Scalars['Int'];
};


export type BarberWorkTimeOverrideCountBarberWorkTimeOverrideVersionArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideCreateInput = {
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberWorkTimeOverrideInput>;
  barber: BarberCreateNestedOneWithoutBarberWorkTimeOverridesInput;
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BarberWorkTimeOverrideCreateManyBarberInput = {
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['Int']>;
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BarberWorkTimeOverrideCreateManyBarberInputEnvelope = {
  data: Array<BarberWorkTimeOverrideCreateManyBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideCreateManyBarberInputEnvelope>;
};

export type BarberWorkTimeOverrideCreateNestedOneWithoutBarberWorkTimeOverrideVersionInput = {
  connect?: InputMaybe<BarberWorkTimeOverrideWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberWorkTimeOverrideCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput>;
  create?: InputMaybe<BarberWorkTimeOverrideCreateWithoutBarberWorkTimeOverrideVersionInput>;
};

export type BarberWorkTimeOverrideCreateOrConnectWithoutBarberInput = {
  create: BarberWorkTimeOverrideCreateWithoutBarberInput;
  where: BarberWorkTimeOverrideWhereUniqueInput;
};

export type BarberWorkTimeOverrideCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput = {
  create: BarberWorkTimeOverrideCreateWithoutBarberWorkTimeOverrideVersionInput;
  where: BarberWorkTimeOverrideWhereUniqueInput;
};

export type BarberWorkTimeOverrideCreateWithoutBarberInput = {
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberWorkTimeOverrideInput>;
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BarberWorkTimeOverrideCreateWithoutBarberWorkTimeOverrideVersionInput = {
  barber: BarberCreateNestedOneWithoutBarberWorkTimeOverridesInput;
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BarberWorkTimeOverrideListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverrideOrderByWithRelationInput = {
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFreeDay?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
};

export enum BarberWorkTimeOverrideScalarFieldEnum {
  BarberId = 'barberId',
  Date = 'date',
  End = 'end',
  Id = 'id',
  IsFreeDay = 'isFreeDay',
  Start = 'start'
}

export type BarberWorkTimeOverrideScalarWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideScalarWhereInput>>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export type BarberWorkTimeOverrideUpdateManyMutationInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideUpdateManyWithWhereWithoutBarberInput = {
  data: BarberWorkTimeOverrideUpdateManyMutationInput;
  where: BarberWorkTimeOverrideScalarWhereInput;
};

export type BarberWorkTimeOverrideUpdateManyWithoutBarberNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideCreateManyBarberInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeOverrideWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeOverrideScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeOverrideWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeOverrideWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeOverrideUpdateWithWhereUniqueWithoutBarberInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeOverrideUpdateManyWithWhereWithoutBarberInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeOverrideUpsertWithWhereUniqueWithoutBarberInput>>;
};

export type BarberWorkTimeOverrideUpdateOneWithoutBarberWorkTimeOverrideVersionNestedInput = {
  connect?: InputMaybe<BarberWorkTimeOverrideWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberWorkTimeOverrideCreateOrConnectWithoutBarberWorkTimeOverrideVersionInput>;
  create?: InputMaybe<BarberWorkTimeOverrideCreateWithoutBarberWorkTimeOverrideVersionInput>;
  delete?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  disconnect?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  update?: InputMaybe<BarberWorkTimeOverrideUpdateToOneWithWhereWithoutBarberWorkTimeOverrideVersionInput>;
  upsert?: InputMaybe<BarberWorkTimeOverrideUpsertWithoutBarberWorkTimeOverrideVersionInput>;
};

export type BarberWorkTimeOverrideUpdateToOneWithWhereWithoutBarberWorkTimeOverrideVersionInput = {
  data: BarberWorkTimeOverrideUpdateWithoutBarberWorkTimeOverrideVersionInput;
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideUpdateWithWhereUniqueWithoutBarberInput = {
  data: BarberWorkTimeOverrideUpdateWithoutBarberInput;
  where: BarberWorkTimeOverrideWhereUniqueInput;
};

export type BarberWorkTimeOverrideUpdateWithoutBarberInput = {
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionUpdateManyWithoutBarberWorkTimeOverrideNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideUpdateWithoutBarberWorkTimeOverrideVersionInput = {
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutBarberWorkTimeOverridesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideUpsertWithWhereUniqueWithoutBarberInput = {
  create: BarberWorkTimeOverrideCreateWithoutBarberInput;
  update: BarberWorkTimeOverrideUpdateWithoutBarberInput;
  where: BarberWorkTimeOverrideWhereUniqueInput;
};

export type BarberWorkTimeOverrideUpsertWithoutBarberWorkTimeOverrideVersionInput = {
  create: BarberWorkTimeOverrideCreateWithoutBarberWorkTimeOverrideVersionInput;
  update: BarberWorkTimeOverrideUpdateWithoutBarberWorkTimeOverrideVersionInput;
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideVersion = {
  __typename?: 'BarberWorkTimeOverrideVersion';
  barberEditor?: Maybe<Barber>;
  barberId: Scalars['Int'];
  barberWorkTimeOverride?: Maybe<BarberWorkTimeOverride>;
  date: Scalars['DateTimeISO'];
  end?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTimeISO']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionBarberWorkTimeOverrideId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type BarberWorkTimeOverrideVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberWorkTimeOverrideVersionBarberWorkTimeOverrideArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideVersionCreateManyBarberEditorInput = {
  barberId: Scalars['Int'];
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionBarberWorkTimeOverrideId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeOverrideVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<BarberWorkTimeOverrideVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeOverrideVersionCreateManyBarberWorkTimeOverrideInput = {
  barberId: Scalars['Int'];
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeOverrideVersionCreateManyBarberWorkTimeOverrideInputEnvelope = {
  data: Array<BarberWorkTimeOverrideVersionCreateManyBarberWorkTimeOverrideInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideVersionCreateManyBarberEditorInputEnvelope>;
};

export type BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberWorkTimeOverrideInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberWorkTimeOverrideInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateWithoutBarberWorkTimeOverrideInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideVersionCreateManyBarberWorkTimeOverrideInputEnvelope>;
};

export type BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberEditorInput = {
  create: BarberWorkTimeOverrideVersionCreateWithoutBarberEditorInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberWorkTimeOverrideInput = {
  create: BarberWorkTimeOverrideVersionCreateWithoutBarberWorkTimeOverrideInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionCreateWithoutBarberEditorInput = {
  barberId: Scalars['Int'];
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideCreateNestedOneWithoutBarberWorkTimeOverrideVersionInput>;
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeOverrideVersionCreateWithoutBarberWorkTimeOverrideInput = {
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutBarberWorkTimeOverrideVersionInput>;
  barberId: Scalars['Int'];
  date: Scalars['DateTimeISO'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  isFreeDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeOverrideVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverrideVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideOrderByWithRelationInput>;
  date?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFreeDay?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberWorkTimeOverrideId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberWorkTimeOverrideVersionScalarFieldEnum {
  BarberId = 'barberId',
  Date = 'date',
  End = 'end',
  Id = 'id',
  IsFreeDay = 'isFreeDay',
  Start = 'start',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberWorkTimeOverrideId = 'versionBarberWorkTimeOverrideId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberWorkTimeOverrideVersionScalarWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarWhereInput>>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideVersionUpdateManyMutationInput = {
  barberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: BarberWorkTimeOverrideVersionUpdateManyMutationInput;
  where: BarberWorkTimeOverrideVersionScalarWhereInput;
};

export type BarberWorkTimeOverrideVersionUpdateManyWithWhereWithoutBarberWorkTimeOverrideInput = {
  data: BarberWorkTimeOverrideVersionUpdateManyMutationInput;
  where: BarberWorkTimeOverrideVersionScalarWhereInput;
};

export type BarberWorkTimeOverrideVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type BarberWorkTimeOverrideVersionUpdateManyWithoutBarberWorkTimeOverrideNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateOrConnectWithoutBarberWorkTimeOverrideInput>>;
  create?: InputMaybe<Array<BarberWorkTimeOverrideVersionCreateWithoutBarberWorkTimeOverrideInput>>;
  createMany?: InputMaybe<BarberWorkTimeOverrideVersionCreateManyBarberWorkTimeOverrideInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpdateWithWhereUniqueWithoutBarberWorkTimeOverrideInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpdateManyWithWhereWithoutBarberWorkTimeOverrideInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeOverrideVersionUpsertWithWhereUniqueWithoutBarberWorkTimeOverrideInput>>;
};

export type BarberWorkTimeOverrideVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: BarberWorkTimeOverrideVersionUpdateWithoutBarberEditorInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionUpdateWithWhereUniqueWithoutBarberWorkTimeOverrideInput = {
  data: BarberWorkTimeOverrideVersionUpdateWithoutBarberWorkTimeOverrideInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionUpdateWithoutBarberEditorInput = {
  barberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideUpdateOneWithoutBarberWorkTimeOverrideVersionNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideVersionUpdateWithoutBarberWorkTimeOverrideInput = {
  barberEditor?: InputMaybe<BarberUpdateOneWithoutBarberWorkTimeOverrideVersionNestedInput>;
  barberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFreeDay?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeOverrideVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: BarberWorkTimeOverrideVersionCreateWithoutBarberEditorInput;
  update: BarberWorkTimeOverrideVersionUpdateWithoutBarberEditorInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionUpsertWithWhereUniqueWithoutBarberWorkTimeOverrideInput = {
  create: BarberWorkTimeOverrideVersionCreateWithoutBarberWorkTimeOverrideInput;
  update: BarberWorkTimeOverrideVersionUpdateWithoutBarberWorkTimeOverrideInput;
  where: BarberWorkTimeOverrideVersionWhereUniqueInput;
};

export type BarberWorkTimeOverrideVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideNullableRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideNullableRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export type BarberWorkTimeOverrideWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['Int']>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export enum BarberWorkTimeScalarFieldEnum {
  BarberId = 'barberId',
  DayOfWeek = 'dayOfWeek',
  End = 'end',
  Id = 'id',
  Start = 'start'
}

export type BarberWorkTimeScalarWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeScalarWhereInput>>;
  barberId?: InputMaybe<IntFilter>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeUpdateManyMutationInput = {
  dayOfWeek?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeUpdateManyWithWhereWithoutBarberInput = {
  data: BarberWorkTimeUpdateManyMutationInput;
  where: BarberWorkTimeScalarWhereInput;
};

export type BarberWorkTimeUpdateManyWithoutBarberNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeCreateOrConnectWithoutBarberInput>>;
  create?: InputMaybe<Array<BarberWorkTimeCreateWithoutBarberInput>>;
  createMany?: InputMaybe<BarberWorkTimeCreateManyBarberInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeUpdateWithWhereUniqueWithoutBarberInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeUpdateManyWithWhereWithoutBarberInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeUpsertWithWhereUniqueWithoutBarberInput>>;
};

export type BarberWorkTimeUpdateOneWithoutBarberWorkTimeVersionNestedInput = {
  connect?: InputMaybe<BarberWorkTimeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BarberWorkTimeCreateOrConnectWithoutBarberWorkTimeVersionInput>;
  create?: InputMaybe<BarberWorkTimeCreateWithoutBarberWorkTimeVersionInput>;
  delete?: InputMaybe<BarberWorkTimeWhereInput>;
  disconnect?: InputMaybe<BarberWorkTimeWhereInput>;
  update?: InputMaybe<BarberWorkTimeUpdateToOneWithWhereWithoutBarberWorkTimeVersionInput>;
  upsert?: InputMaybe<BarberWorkTimeUpsertWithoutBarberWorkTimeVersionInput>;
};

export type BarberWorkTimeUpdateToOneWithWhereWithoutBarberWorkTimeVersionInput = {
  data: BarberWorkTimeUpdateWithoutBarberWorkTimeVersionInput;
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeUpdateWithWhereUniqueWithoutBarberInput = {
  data: BarberWorkTimeUpdateWithoutBarberInput;
  where: BarberWorkTimeWhereUniqueInput;
};

export type BarberWorkTimeUpdateWithoutBarberInput = {
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionUpdateManyWithoutBarberWorkTimeNestedInput>;
  dayOfWeek?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeUpdateWithoutBarberWorkTimeVersionInput = {
  barber?: InputMaybe<BarberUpdateOneRequiredWithoutBarberWorkTimesNestedInput>;
  dayOfWeek?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  start?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeUpsertWithWhereUniqueWithoutBarberInput = {
  create: BarberWorkTimeCreateWithoutBarberInput;
  update: BarberWorkTimeUpdateWithoutBarberInput;
  where: BarberWorkTimeWhereUniqueInput;
};

export type BarberWorkTimeUpsertWithoutBarberWorkTimeVersionInput = {
  create: BarberWorkTimeCreateWithoutBarberWorkTimeVersionInput;
  update: BarberWorkTimeUpdateWithoutBarberWorkTimeVersionInput;
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeVersion = {
  __typename?: 'BarberWorkTimeVersion';
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']>;
  barberWorkTime?: Maybe<BarberWorkTime>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  start?: Maybe<Scalars['DateTimeISO']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionBarberWorkTimeId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type BarberWorkTimeVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberWorkTimeVersionBarberWorkTimeArgs = {
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeVersionCreateManyBarberEditorInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionBarberWorkTimeId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<BarberWorkTimeVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeVersionCreateManyBarberWorkTimeInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeVersionCreateManyBarberWorkTimeInputEnvelope = {
  data: Array<BarberWorkTimeVersionCreateManyBarberWorkTimeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberWorkTimeVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberWorkTimeVersionCreateManyBarberEditorInputEnvelope>;
};

export type BarberWorkTimeVersionCreateNestedManyWithoutBarberWorkTimeInput = {
  connect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeVersionCreateOrConnectWithoutBarberWorkTimeInput>>;
  create?: InputMaybe<Array<BarberWorkTimeVersionCreateWithoutBarberWorkTimeInput>>;
  createMany?: InputMaybe<BarberWorkTimeVersionCreateManyBarberWorkTimeInputEnvelope>;
};

export type BarberWorkTimeVersionCreateOrConnectWithoutBarberEditorInput = {
  create: BarberWorkTimeVersionCreateWithoutBarberEditorInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionCreateOrConnectWithoutBarberWorkTimeInput = {
  create: BarberWorkTimeVersionCreateWithoutBarberWorkTimeInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionCreateWithoutBarberEditorInput = {
  barberId?: InputMaybe<Scalars['Int']>;
  barberWorkTime?: InputMaybe<BarberWorkTimeCreateNestedOneWithoutBarberWorkTimeVersionInput>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeVersionCreateWithoutBarberWorkTimeInput = {
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutBarberWorkTimeVersionInput>;
  barberId?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTimeISO']>;
  id: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTimeISO']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type BarberWorkTimeVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberWorkTime?: InputMaybe<BarberWorkTimeOrderByWithRelationInput>;
  dayOfWeek?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberWorkTimeId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberWorkTimeVersionScalarFieldEnum {
  BarberId = 'barberId',
  DayOfWeek = 'dayOfWeek',
  End = 'end',
  Id = 'id',
  Start = 'start',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberWorkTimeId = 'versionBarberWorkTimeId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberWorkTimeVersionScalarWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionScalarWhereInput>>;
  barberId?: InputMaybe<IntNullableFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeVersionUpdateManyMutationInput = {
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dayOfWeek?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: BarberWorkTimeVersionUpdateManyMutationInput;
  where: BarberWorkTimeVersionScalarWhereInput;
};

export type BarberWorkTimeVersionUpdateManyWithWhereWithoutBarberWorkTimeInput = {
  data: BarberWorkTimeVersionUpdateManyMutationInput;
  where: BarberWorkTimeVersionScalarWhereInput;
};

export type BarberWorkTimeVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<BarberWorkTimeVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<BarberWorkTimeVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type BarberWorkTimeVersionUpdateManyWithoutBarberWorkTimeNestedInput = {
  connect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BarberWorkTimeVersionCreateOrConnectWithoutBarberWorkTimeInput>>;
  create?: InputMaybe<Array<BarberWorkTimeVersionCreateWithoutBarberWorkTimeInput>>;
  createMany?: InputMaybe<BarberWorkTimeVersionCreateManyBarberWorkTimeInputEnvelope>;
  delete?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BarberWorkTimeVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<BarberWorkTimeVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<BarberWorkTimeVersionUpdateWithWhereUniqueWithoutBarberWorkTimeInput>>;
  updateMany?: InputMaybe<Array<BarberWorkTimeVersionUpdateManyWithWhereWithoutBarberWorkTimeInput>>;
  upsert?: InputMaybe<Array<BarberWorkTimeVersionUpsertWithWhereUniqueWithoutBarberWorkTimeInput>>;
};

export type BarberWorkTimeVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: BarberWorkTimeVersionUpdateWithoutBarberEditorInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionUpdateWithWhereUniqueWithoutBarberWorkTimeInput = {
  data: BarberWorkTimeVersionUpdateWithoutBarberWorkTimeInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionUpdateWithoutBarberEditorInput = {
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  barberWorkTime?: InputMaybe<BarberWorkTimeUpdateOneWithoutBarberWorkTimeVersionNestedInput>;
  dayOfWeek?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeVersionUpdateWithoutBarberWorkTimeInput = {
  barberEditor?: InputMaybe<BarberUpdateOneWithoutBarberWorkTimeVersionNestedInput>;
  barberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dayOfWeek?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BarberWorkTimeVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: BarberWorkTimeVersionCreateWithoutBarberEditorInput;
  update: BarberWorkTimeVersionUpdateWithoutBarberEditorInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionUpsertWithWhereUniqueWithoutBarberWorkTimeInput = {
  create: BarberWorkTimeVersionCreateWithoutBarberWorkTimeInput;
  update: BarberWorkTimeVersionUpdateWithoutBarberWorkTimeInput;
  where: BarberWorkTimeVersionWhereUniqueInput;
};

export type BarberWorkTimeVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberWorkTime?: InputMaybe<BarberWorkTimeNullableRelationFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberWorkTime?: InputMaybe<BarberWorkTimeNullableRelationFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberId_dayOfWeek_start_end?: InputMaybe<BarberWorkTimeBarberIdDayOfWeekStartEndCompoundUniqueInput>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type ChangeBarberEmailInput = {
  email: Scalars['String'];
};

export type ChangeBarberPasswordInput = {
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  AppointmentVersion: Array<AppointmentVersion>;
  ClientVersion: Array<ClientVersion>;
  _count?: Maybe<ClientCount>;
  active: Scalars['Boolean'];
  appointments: Array<Appointment>;
  blocked: Scalars['Boolean'];
  blockedReason?: Maybe<Scalars['String']>;
  clientEditorVersion: Array<ClientVersion>;
  favouriteBarber?: Maybe<Barber>;
  favouriteBarberId?: Maybe<Scalars['Int']>;
  favouriteService?: Maybe<Service>;
  favouriteServiceId?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  penalties: Array<Penalty>;
  phone?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['Int']>;
  smsNotification: Scalars['Boolean'];
  tac?: Maybe<Scalars['Boolean']>;
  userUID?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


export type ClientAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type ClientClientVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ClientClientEditorVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientFavouriteBarberArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ClientFavouriteServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};


export type ClientPenaltiesArgs = {
  cursor?: InputMaybe<PenaltyWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PenaltyWhereInput>;
};

export type ClientAvgAggregate = {
  __typename?: 'ClientAvgAggregate';
  favouriteBarberId?: Maybe<Scalars['Float']>;
  favouriteServiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referrerId?: Maybe<Scalars['Float']>;
};

export type ClientCount = {
  __typename?: 'ClientCount';
  AppointmentVersion: Scalars['Int'];
  ClientVersion: Scalars['Int'];
  appointments: Scalars['Int'];
  clientEditorVersion: Scalars['Int'];
  penalties: Scalars['Int'];
};


export type ClientCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type ClientCountClientVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ClientCountClientEditorVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientCountPenaltiesArgs = {
  where?: InputMaybe<PenaltyWhereInput>;
};

export type ClientCountAggregate = {
  __typename?: 'ClientCountAggregate';
  _all: Scalars['Int'];
  active: Scalars['Int'];
  blocked: Scalars['Int'];
  blockedReason: Scalars['Int'];
  favouriteBarberId: Scalars['Int'];
  favouriteServiceId: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  lastName: Scalars['Int'];
  name: Scalars['Int'];
  phone: Scalars['Int'];
  referralCode: Scalars['Int'];
  referrerId: Scalars['Int'];
  smsNotification: Scalars['Int'];
  tac: Scalars['Int'];
  userUID: Scalars['Int'];
  verified: Scalars['Int'];
};

export type ClientCreateInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateManyFavouriteBarberInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateManyFavouriteBarberInputEnvelope = {
  data: Array<ClientCreateManyFavouriteBarberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateManyFavouriteServiceInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateManyFavouriteServiceInputEnvelope = {
  data: Array<ClientCreateManyFavouriteServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateNestedManyWithoutFavouriteBarberInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutFavouriteBarberInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutFavouriteBarberInput>>;
  createMany?: InputMaybe<ClientCreateManyFavouriteBarberInputEnvelope>;
};

export type ClientCreateNestedManyWithoutFavouriteServiceInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutFavouriteServiceInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutFavouriteServiceInput>>;
  createMany?: InputMaybe<ClientCreateManyFavouriteServiceInputEnvelope>;
};

export type ClientCreateNestedOneWithoutAppointmentVersionInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<ClientCreateWithoutAppointmentVersionInput>;
};

export type ClientCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<ClientCreateWithoutAppointmentsInput>;
};

export type ClientCreateNestedOneWithoutClientEditorVersionInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientEditorVersionInput>;
  create?: InputMaybe<ClientCreateWithoutClientEditorVersionInput>;
};

export type ClientCreateNestedOneWithoutClientVersionInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientVersionInput>;
  create?: InputMaybe<ClientCreateWithoutClientVersionInput>;
};

export type ClientCreateNestedOneWithoutPenaltiesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutPenaltiesInput>;
  create?: InputMaybe<ClientCreateWithoutPenaltiesInput>;
};

export type ClientCreateOrConnectWithoutAppointmentVersionInput = {
  create: ClientCreateWithoutAppointmentVersionInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutAppointmentsInput = {
  create: ClientCreateWithoutAppointmentsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutClientEditorVersionInput = {
  create: ClientCreateWithoutClientEditorVersionInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutClientVersionInput = {
  create: ClientCreateWithoutClientVersionInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutFavouriteBarberInput = {
  create: ClientCreateWithoutFavouriteBarberInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutFavouriteServiceInput = {
  create: ClientCreateWithoutFavouriteServiceInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPenaltiesInput = {
  create: ClientCreateWithoutPenaltiesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateWithoutAppointmentVersionInput = {
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutAppointmentsInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutClientEditorVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutClientVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutFavouriteBarberInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutFavouriteServiceInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<PenaltyCreateNestedManyWithoutClientInput>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateWithoutPenaltiesInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutClientEditorInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditorVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutClientEditorInput>;
  favouriteBarber?: InputMaybe<BarberCreateNestedOneWithoutClientInput>;
  favouriteService?: InputMaybe<ServiceCreateNestedOneWithoutClientInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type ClientListRelationFilter = {
  every?: InputMaybe<ClientWhereInput>;
  none?: InputMaybe<ClientWhereInput>;
  some?: InputMaybe<ClientWhereInput>;
};

export type ClientMaxAggregate = {
  __typename?: 'ClientMaxAggregate';
  active?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  blockedReason?: Maybe<Scalars['String']>;
  favouriteBarberId?: Maybe<Scalars['Int']>;
  favouriteServiceId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['Int']>;
  smsNotification?: Maybe<Scalars['Boolean']>;
  tac?: Maybe<Scalars['Boolean']>;
  userUID?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type ClientMinAggregate = {
  __typename?: 'ClientMinAggregate';
  active?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  blockedReason?: Maybe<Scalars['String']>;
  favouriteBarberId?: Maybe<Scalars['Int']>;
  favouriteServiceId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['Int']>;
  smsNotification?: Maybe<Scalars['Boolean']>;
  tac?: Maybe<Scalars['Boolean']>;
  userUID?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type ClientNullableRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientOrderByWithRelationInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  ClientVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  blocked?: InputMaybe<SortOrder>;
  blockedReason?: InputMaybe<SortOrderInput>;
  clientEditorVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  favouriteBarber?: InputMaybe<BarberOrderByWithRelationInput>;
  favouriteBarberId?: InputMaybe<SortOrderInput>;
  favouriteService?: InputMaybe<ServiceOrderByWithRelationInput>;
  favouriteServiceId?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  penalties?: InputMaybe<PenaltyOrderByRelationAggregateInput>;
  phone?: InputMaybe<SortOrderInput>;
  referralCode?: InputMaybe<SortOrderInput>;
  referrerId?: InputMaybe<SortOrderInput>;
  smsNotification?: InputMaybe<SortOrder>;
  tac?: InputMaybe<SortOrderInput>;
  userUID?: InputMaybe<SortOrderInput>;
  verified?: InputMaybe<SortOrder>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export enum ClientScalarFieldEnum {
  Active = 'active',
  Blocked = 'blocked',
  BlockedReason = 'blockedReason',
  FavouriteBarberId = 'favouriteBarberId',
  FavouriteServiceId = 'favouriteServiceId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Name = 'name',
  Phone = 'phone',
  ReferralCode = 'referralCode',
  ReferrerId = 'referrerId',
  SmsNotification = 'smsNotification',
  Tac = 'tac',
  UserUid = 'userUID',
  Verified = 'verified'
}

export type ClientScalarWhereInput = {
  AND?: InputMaybe<Array<ClientScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
};

export type ClientSumAggregate = {
  __typename?: 'ClientSumAggregate';
  favouriteBarberId?: Maybe<Scalars['Int']>;
  favouriteServiceId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referrerId?: Maybe<Scalars['Int']>;
};

export type ClientUpdateInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateManyWithWhereWithoutFavouriteBarberInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutFavouriteServiceInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithoutFavouriteBarberNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutFavouriteBarberInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutFavouriteBarberInput>>;
  createMany?: InputMaybe<ClientCreateManyFavouriteBarberInputEnvelope>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutFavouriteBarberInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutFavouriteBarberInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutFavouriteBarberInput>>;
};

export type ClientUpdateManyWithoutFavouriteServiceNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutFavouriteServiceInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutFavouriteServiceInput>>;
  createMany?: InputMaybe<ClientCreateManyFavouriteServiceInputEnvelope>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutFavouriteServiceInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutFavouriteServiceInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutFavouriteServiceInput>>;
};

export type ClientUpdateOneRequiredWithoutPenaltiesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutPenaltiesInput>;
  create?: InputMaybe<ClientCreateWithoutPenaltiesInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutPenaltiesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutPenaltiesInput>;
};

export type ClientUpdateOneWithoutAppointmentVersionNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAppointmentVersionInput>;
  create?: InputMaybe<ClientCreateWithoutAppointmentVersionInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutAppointmentVersionInput>;
  upsert?: InputMaybe<ClientUpsertWithoutAppointmentVersionInput>;
};

export type ClientUpdateOneWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<ClientCreateWithoutAppointmentsInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutAppointmentsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutAppointmentsInput>;
};

export type ClientUpdateOneWithoutClientEditorVersionNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientEditorVersionInput>;
  create?: InputMaybe<ClientCreateWithoutClientEditorVersionInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutClientEditorVersionInput>;
  upsert?: InputMaybe<ClientUpsertWithoutClientEditorVersionInput>;
};

export type ClientUpdateOneWithoutClientVersionNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientVersionInput>;
  create?: InputMaybe<ClientCreateWithoutClientVersionInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutClientVersionInput>;
  upsert?: InputMaybe<ClientUpsertWithoutClientVersionInput>;
};

export type ClientUpdateToOneWithWhereWithoutAppointmentVersionInput = {
  data: ClientUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutAppointmentsInput = {
  data: ClientUpdateWithoutAppointmentsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutClientEditorVersionInput = {
  data: ClientUpdateWithoutClientEditorVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutClientVersionInput = {
  data: ClientUpdateWithoutClientVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutPenaltiesInput = {
  data: ClientUpdateWithoutPenaltiesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateWithWhereUniqueWithoutFavouriteBarberInput = {
  data: ClientUpdateWithoutFavouriteBarberInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutFavouriteServiceInput = {
  data: ClientUpdateWithoutFavouriteServiceInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithoutAppointmentVersionInput = {
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutAppointmentsInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutClientEditorVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutClientVersionInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutFavouriteBarberInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutFavouriteServiceInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  penalties?: InputMaybe<PenaltyUpdateManyWithoutClientNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutPenaltiesInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionUpdateManyWithoutClientEditorNestedInput>;
  ClientVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditorVersion?: InputMaybe<ClientVersionUpdateManyWithoutClientEditorNestedInput>;
  favouriteBarber?: InputMaybe<BarberUpdateOneWithoutClientNestedInput>;
  favouriteService?: InputMaybe<ServiceUpdateOneWithoutClientNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ClientUpsertWithWhereUniqueWithoutFavouriteBarberInput = {
  create: ClientCreateWithoutFavouriteBarberInput;
  update: ClientUpdateWithoutFavouriteBarberInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutFavouriteServiceInput = {
  create: ClientCreateWithoutFavouriteServiceInput;
  update: ClientUpdateWithoutFavouriteServiceInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithoutAppointmentVersionInput = {
  create: ClientCreateWithoutAppointmentVersionInput;
  update: ClientUpdateWithoutAppointmentVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutAppointmentsInput = {
  create: ClientCreateWithoutAppointmentsInput;
  update: ClientUpdateWithoutAppointmentsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutClientEditorVersionInput = {
  create: ClientCreateWithoutClientEditorVersionInput;
  update: ClientUpdateWithoutClientEditorVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutClientVersionInput = {
  create: ClientCreateWithoutClientVersionInput;
  update: ClientUpdateWithoutClientVersionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutPenaltiesInput = {
  create: ClientCreateWithoutPenaltiesInput;
  update: ClientUpdateWithoutPenaltiesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientVersion = {
  __typename?: 'ClientVersion';
  active?: Maybe<Scalars['Boolean']>;
  barberEditor?: Maybe<Barber>;
  blocked?: Maybe<Scalars['Boolean']>;
  blockedReason?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientEditor?: Maybe<Client>;
  favouriteBarberId?: Maybe<Scalars['Int']>;
  favouriteServiceId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['Int']>;
  smsNotification?: Maybe<Scalars['Boolean']>;
  tac?: Maybe<Scalars['Boolean']>;
  userUID?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionClientEditorId?: Maybe<Scalars['Int']>;
  versionClientId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type ClientVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ClientVersionClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ClientVersionClientEditorArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientVersionCreateManyBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionClientEditorId?: InputMaybe<Scalars['Int']>;
  versionClientId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<ClientVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientVersionCreateManyClientEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionClientId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionCreateManyClientEditorInputEnvelope = {
  data: Array<ClientVersionCreateManyClientEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientVersionCreateManyClientInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionClientEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionCreateManyClientInputEnvelope = {
  data: Array<ClientVersionCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyBarberEditorInputEnvelope>;
};

export type ClientVersionCreateNestedManyWithoutClientEditorInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutClientEditorInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutClientEditorInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyClientEditorInputEnvelope>;
};

export type ClientVersionCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyClientInputEnvelope>;
};

export type ClientVersionCreateOrConnectWithoutBarberEditorInput = {
  create: ClientVersionCreateWithoutBarberEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionCreateOrConnectWithoutClientEditorInput = {
  create: ClientVersionCreateWithoutClientEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionCreateOrConnectWithoutClientInput = {
  create: ClientVersionCreateWithoutClientInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionCreateWithoutBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutClientVersionInput>;
  clientEditor?: InputMaybe<ClientCreateNestedOneWithoutClientEditorVersionInput>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionCreateWithoutClientEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutClientVersionInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientCreateNestedOneWithoutClientVersionInput>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionCreateWithoutClientInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutClientVersionInput>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  clientEditor?: InputMaybe<ClientCreateNestedOneWithoutClientEditorVersionInput>;
  favouriteBarberId?: InputMaybe<Scalars['Int']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['Int']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  tac?: InputMaybe<Scalars['Boolean']>;
  userUID?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ClientVersionListRelationFilter = {
  every?: InputMaybe<ClientVersionWhereInput>;
  none?: InputMaybe<ClientVersionWhereInput>;
  some?: InputMaybe<ClientVersionWhereInput>;
};

export type ClientVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  blocked?: InputMaybe<SortOrderInput>;
  blockedReason?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientEditor?: InputMaybe<ClientOrderByWithRelationInput>;
  favouriteBarberId?: InputMaybe<SortOrderInput>;
  favouriteServiceId?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  referralCode?: InputMaybe<SortOrderInput>;
  referrerId?: InputMaybe<SortOrderInput>;
  smsNotification?: InputMaybe<SortOrderInput>;
  tac?: InputMaybe<SortOrderInput>;
  userUID?: InputMaybe<SortOrderInput>;
  verified?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionClientEditorId?: InputMaybe<SortOrderInput>;
  versionClientId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum ClientVersionScalarFieldEnum {
  Active = 'active',
  Blocked = 'blocked',
  BlockedReason = 'blockedReason',
  FavouriteBarberId = 'favouriteBarberId',
  FavouriteServiceId = 'favouriteServiceId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Name = 'name',
  Phone = 'phone',
  ReferralCode = 'referralCode',
  ReferrerId = 'referrerId',
  SmsNotification = 'smsNotification',
  Tac = 'tac',
  UserUid = 'userUID',
  Verified = 'verified',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionClientEditorId = 'versionClientEditorId',
  VersionClientId = 'versionClientId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type ClientVersionScalarWhereInput = {
  AND?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientVersionUpdateManyMutationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  favouriteBarberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  favouriteServiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: ClientVersionUpdateManyMutationInput;
  where: ClientVersionScalarWhereInput;
};

export type ClientVersionUpdateManyWithWhereWithoutClientEditorInput = {
  data: ClientVersionUpdateManyMutationInput;
  where: ClientVersionScalarWhereInput;
};

export type ClientVersionUpdateManyWithWhereWithoutClientInput = {
  data: ClientVersionUpdateManyMutationInput;
  where: ClientVersionScalarWhereInput;
};

export type ClientVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<ClientVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<ClientVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type ClientVersionUpdateManyWithoutClientEditorNestedInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutClientEditorInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutClientEditorInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyClientEditorInputEnvelope>;
  delete?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientVersionUpdateWithWhereUniqueWithoutClientEditorInput>>;
  updateMany?: InputMaybe<Array<ClientVersionUpdateManyWithWhereWithoutClientEditorInput>>;
  upsert?: InputMaybe<Array<ClientVersionUpsertWithWhereUniqueWithoutClientEditorInput>>;
};

export type ClientVersionUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientVersionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientVersionCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientVersionCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientVersionUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ClientVersionUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ClientVersionUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: ClientVersionUpdateWithoutBarberEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionUpdateWithWhereUniqueWithoutClientEditorInput = {
  data: ClientVersionUpdateWithoutClientEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientVersionUpdateWithoutClientInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionUpdateWithoutBarberEditorInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutClientVersionNestedInput>;
  clientEditor?: InputMaybe<ClientUpdateOneWithoutClientEditorVersionNestedInput>;
  favouriteBarberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  favouriteServiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientVersionUpdateWithoutClientEditorInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutClientVersionNestedInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneWithoutClientVersionNestedInput>;
  favouriteBarberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  favouriteServiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientVersionUpdateWithoutClientInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutClientVersionNestedInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  blockedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientEditor?: InputMaybe<ClientUpdateOneWithoutClientEditorVersionNestedInput>;
  favouriteBarberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  favouriteServiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referrerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  smsNotification?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tac?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  userUID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  verified?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: ClientVersionCreateWithoutBarberEditorInput;
  update: ClientVersionUpdateWithoutBarberEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionUpsertWithWhereUniqueWithoutClientEditorInput = {
  create: ClientVersionCreateWithoutClientEditorInput;
  update: ClientVersionUpdateWithoutClientEditorInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientVersionCreateWithoutClientInput;
  update: ClientVersionUpdateWithoutClientInput;
  where: ClientVersionWhereUniqueInput;
};

export type ClientVersionWhereInput = {
  AND?: InputMaybe<Array<ClientVersionWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientVersionWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  clientEditorVersion?: InputMaybe<ClientVersionListRelationFilter>;
  favouriteBarber?: InputMaybe<BarberNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteService?: InputMaybe<ServiceNullableRelationFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  penalties?: InputMaybe<PenaltyListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
};

export type ClientWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  clientEditorVersion?: InputMaybe<ClientVersionListRelationFilter>;
  favouriteBarber?: InputMaybe<BarberNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteService?: InputMaybe<ServiceNullableRelationFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  penalties?: InputMaybe<PenaltyListRelationFilter>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
};

export type CreateBarberInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberVersion?: InputMaybe<BarberVersionCreateNestedManyWithoutBarberInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionCreateNestedManyWithoutBarberEditorInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionCreateNestedManyWithoutBarberEditorInput>;
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteBarberInput>;
  ClientVersion?: InputMaybe<ClientVersionCreateNestedManyWithoutBarberEditorInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutBarberEditorInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutBarberEditorInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutBarberInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutBarberInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideCreateNestedManyWithoutBarberInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeCreateNestedManyWithoutBarberInput>;
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  type?: InputMaybe<BarberType>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type EnumAuditOperationFieldUpdateOperationsInput = {
  set?: InputMaybe<AuditOperation>;
};

export type EnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type EnumBarberTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BarberType>;
};

export type EnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type EnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export enum EventType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeBarberEmail: Scalars['Boolean'];
  changeBarberPassword: Scalars['Boolean'];
  createBarber: Barber;
  createOneAdditionalService: AdditionalService;
  createOneAppointment: Appointment;
  createOneBarberWorkTimeOverride: BarberWorkTimeOverride;
  createOneClient: Client;
  createOneService: Service;
  createRepeatableAppointment: Scalars['Float'];
  deleteManyAppointment: AffectedRowsOutput;
  deleteOneAdditionalService?: Maybe<AdditionalService>;
  deleteOneAppointment?: Maybe<Appointment>;
  updateOneAdditionalService?: Maybe<AdditionalService>;
  updateOneAppointment?: Maybe<Appointment>;
  updateOneBarber?: Maybe<Barber>;
  updateOneClient?: Maybe<Client>;
  updateOneService?: Maybe<Service>;
};


export type MutationChangeBarberEmailArgs = {
  data: ChangeBarberEmailInput;
  where: BarberWhereUniqueInput;
};


export type MutationChangeBarberPasswordArgs = {
  data: ChangeBarberPasswordInput;
  where: BarberWhereUniqueInput;
};


export type MutationCreateBarberArgs = {
  data: CreateBarberInput;
};


export type MutationCreateOneAdditionalServiceArgs = {
  data: AdditionalServiceCreateInput;
};


export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};


export type MutationCreateOneBarberWorkTimeOverrideArgs = {
  data: BarberWorkTimeOverrideCreateInput;
};


export type MutationCreateOneClientArgs = {
  data: ClientCreateInput;
};


export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationCreateRepeatableAppointmentArgs = {
  data: AppointmentCreateRepeatableInput;
};


export type MutationDeleteManyAppointmentArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type MutationDeleteOneAdditionalServiceArgs = {
  where: AdditionalServiceWhereUniqueInput;
};


export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationUpdateOneAdditionalServiceArgs = {
  data: AdditionalServiceUpdateInput;
  where: AdditionalServiceWhereUniqueInput;
};


export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};


export type MutationUpdateOneBarberArgs = {
  data: BarberUpdateInput;
  where: BarberWhereUniqueInput;
};


export type MutationUpdateOneClientArgs = {
  data: ClientUpdateInput;
  where: ClientWhereUniqueInput;
};


export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type NestedEnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedEnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  appointmentId: Scalars['Float'];
  appointmentStart?: Maybe<Scalars['DateTimeISO']>;
  barberEditorId?: Maybe<Scalars['Float']>;
  barberId: Scalars['Float'];
  eventType: EventType;
  previousAppointmentStart?: Maybe<Scalars['DateTimeISO']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type NullableEnumBarberTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BarberType>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Penalty = {
  __typename?: 'Penalty';
  PenaltyVersion: Array<PenaltyVersion>;
  _count?: Maybe<PenaltyCount>;
  client: Client;
  clientId: Scalars['Int'];
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type PenaltyPenaltyVersionArgs = {
  cursor?: InputMaybe<PenaltyVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyCount = {
  __typename?: 'PenaltyCount';
  PenaltyVersion: Scalars['Int'];
};


export type PenaltyCountPenaltyVersionArgs = {
  where?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyCreateManyClientInput = {
  id?: InputMaybe<Scalars['Int']>;
  reason: Scalars['String'];
};

export type PenaltyCreateManyClientInputEnvelope = {
  data: Array<PenaltyCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PenaltyCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<PenaltyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<PenaltyCreateWithoutClientInput>>;
  createMany?: InputMaybe<PenaltyCreateManyClientInputEnvelope>;
};

export type PenaltyCreateNestedOneWithoutPenaltyVersionInput = {
  connect?: InputMaybe<PenaltyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PenaltyCreateOrConnectWithoutPenaltyVersionInput>;
  create?: InputMaybe<PenaltyCreateWithoutPenaltyVersionInput>;
};

export type PenaltyCreateOrConnectWithoutClientInput = {
  create: PenaltyCreateWithoutClientInput;
  where: PenaltyWhereUniqueInput;
};

export type PenaltyCreateOrConnectWithoutPenaltyVersionInput = {
  create: PenaltyCreateWithoutPenaltyVersionInput;
  where: PenaltyWhereUniqueInput;
};

export type PenaltyCreateWithoutClientInput = {
  PenaltyVersion?: InputMaybe<PenaltyVersionCreateNestedManyWithoutPenaltyInput>;
  reason: Scalars['String'];
};

export type PenaltyCreateWithoutPenaltyVersionInput = {
  client: ClientCreateNestedOneWithoutPenaltiesInput;
  reason: Scalars['String'];
};

export type PenaltyListRelationFilter = {
  every?: InputMaybe<PenaltyWhereInput>;
  none?: InputMaybe<PenaltyWhereInput>;
  some?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyNullableRelationFilter = {
  is?: InputMaybe<PenaltyWhereInput>;
  isNot?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PenaltyOrderByWithRelationInput = {
  PenaltyVersion?: InputMaybe<PenaltyVersionOrderByRelationAggregateInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
};

export enum PenaltyScalarFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Reason = 'reason'
}

export type PenaltyScalarWhereInput = {
  AND?: InputMaybe<Array<PenaltyScalarWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyScalarWhereInput>>;
  OR?: InputMaybe<Array<PenaltyScalarWhereInput>>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  reason?: InputMaybe<StringFilter>;
};

export type PenaltyUpdateManyMutationInput = {
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PenaltyUpdateManyWithWhereWithoutClientInput = {
  data: PenaltyUpdateManyMutationInput;
  where: PenaltyScalarWhereInput;
};

export type PenaltyUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<PenaltyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<PenaltyCreateWithoutClientInput>>;
  createMany?: InputMaybe<PenaltyCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<PenaltyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PenaltyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PenaltyWhereUniqueInput>>;
  set?: InputMaybe<Array<PenaltyWhereUniqueInput>>;
  update?: InputMaybe<Array<PenaltyUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<PenaltyUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<PenaltyUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PenaltyUpdateOneWithoutPenaltyVersionNestedInput = {
  connect?: InputMaybe<PenaltyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PenaltyCreateOrConnectWithoutPenaltyVersionInput>;
  create?: InputMaybe<PenaltyCreateWithoutPenaltyVersionInput>;
  delete?: InputMaybe<PenaltyWhereInput>;
  disconnect?: InputMaybe<PenaltyWhereInput>;
  update?: InputMaybe<PenaltyUpdateToOneWithWhereWithoutPenaltyVersionInput>;
  upsert?: InputMaybe<PenaltyUpsertWithoutPenaltyVersionInput>;
};

export type PenaltyUpdateToOneWithWhereWithoutPenaltyVersionInput = {
  data: PenaltyUpdateWithoutPenaltyVersionInput;
  where?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyUpdateWithWhereUniqueWithoutClientInput = {
  data: PenaltyUpdateWithoutClientInput;
  where: PenaltyWhereUniqueInput;
};

export type PenaltyUpdateWithoutClientInput = {
  PenaltyVersion?: InputMaybe<PenaltyVersionUpdateManyWithoutPenaltyNestedInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PenaltyUpdateWithoutPenaltyVersionInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutPenaltiesNestedInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PenaltyUpsertWithWhereUniqueWithoutClientInput = {
  create: PenaltyCreateWithoutClientInput;
  update: PenaltyUpdateWithoutClientInput;
  where: PenaltyWhereUniqueInput;
};

export type PenaltyUpsertWithoutPenaltyVersionInput = {
  create: PenaltyCreateWithoutPenaltyVersionInput;
  update: PenaltyUpdateWithoutPenaltyVersionInput;
  where?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyVersion = {
  __typename?: 'PenaltyVersion';
  barberEditor?: Maybe<Barber>;
  clientId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  penalty?: Maybe<Penalty>;
  reason?: Maybe<Scalars['String']>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionPenaltyId?: Maybe<Scalars['Int']>;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type PenaltyVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type PenaltyVersionPenaltyArgs = {
  where?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyVersionCreateManyBarberEditorInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionPenaltyId?: InputMaybe<Scalars['Int']>;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type PenaltyVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<PenaltyVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PenaltyVersionCreateManyPenaltyInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type PenaltyVersionCreateManyPenaltyInputEnvelope = {
  data: Array<PenaltyVersionCreateManyPenaltyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PenaltyVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<PenaltyVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<PenaltyVersionCreateManyBarberEditorInputEnvelope>;
};

export type PenaltyVersionCreateNestedManyWithoutPenaltyInput = {
  connect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyVersionCreateOrConnectWithoutPenaltyInput>>;
  create?: InputMaybe<Array<PenaltyVersionCreateWithoutPenaltyInput>>;
  createMany?: InputMaybe<PenaltyVersionCreateManyPenaltyInputEnvelope>;
};

export type PenaltyVersionCreateOrConnectWithoutBarberEditorInput = {
  create: PenaltyVersionCreateWithoutBarberEditorInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionCreateOrConnectWithoutPenaltyInput = {
  create: PenaltyVersionCreateWithoutPenaltyInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionCreateWithoutBarberEditorInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  penalty?: InputMaybe<PenaltyCreateNestedOneWithoutPenaltyVersionInput>;
  reason?: InputMaybe<Scalars['String']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type PenaltyVersionCreateWithoutPenaltyInput = {
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutPenaltyVersionInput>;
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type PenaltyVersionListRelationFilter = {
  every?: InputMaybe<PenaltyVersionWhereInput>;
  none?: InputMaybe<PenaltyVersionWhereInput>;
  some?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PenaltyVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  penalty?: InputMaybe<PenaltyOrderByWithRelationInput>;
  reason?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionPenaltyId?: InputMaybe<SortOrderInput>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum PenaltyVersionScalarFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Reason = 'reason',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionPenaltyId = 'versionPenaltyId',
  VersionTimestamp = 'versionTimestamp'
}

export type PenaltyVersionScalarWhereInput = {
  AND?: InputMaybe<Array<PenaltyVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionScalarWhereInput>>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyVersionUpdateManyMutationInput = {
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PenaltyVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: PenaltyVersionUpdateManyMutationInput;
  where: PenaltyVersionScalarWhereInput;
};

export type PenaltyVersionUpdateManyWithWhereWithoutPenaltyInput = {
  data: PenaltyVersionUpdateManyMutationInput;
  where: PenaltyVersionScalarWhereInput;
};

export type PenaltyVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<PenaltyVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<PenaltyVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PenaltyVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<PenaltyVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<PenaltyVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<PenaltyVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type PenaltyVersionUpdateManyWithoutPenaltyNestedInput = {
  connect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PenaltyVersionCreateOrConnectWithoutPenaltyInput>>;
  create?: InputMaybe<Array<PenaltyVersionCreateWithoutPenaltyInput>>;
  createMany?: InputMaybe<PenaltyVersionCreateManyPenaltyInputEnvelope>;
  delete?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PenaltyVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<PenaltyVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<PenaltyVersionUpdateWithWhereUniqueWithoutPenaltyInput>>;
  updateMany?: InputMaybe<Array<PenaltyVersionUpdateManyWithWhereWithoutPenaltyInput>>;
  upsert?: InputMaybe<Array<PenaltyVersionUpsertWithWhereUniqueWithoutPenaltyInput>>;
};

export type PenaltyVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: PenaltyVersionUpdateWithoutBarberEditorInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionUpdateWithWhereUniqueWithoutPenaltyInput = {
  data: PenaltyVersionUpdateWithoutPenaltyInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionUpdateWithoutBarberEditorInput = {
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  penalty?: InputMaybe<PenaltyUpdateOneWithoutPenaltyVersionNestedInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PenaltyVersionUpdateWithoutPenaltyInput = {
  barberEditor?: InputMaybe<BarberUpdateOneWithoutPenaltyVersionNestedInput>;
  clientId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PenaltyVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: PenaltyVersionCreateWithoutBarberEditorInput;
  update: PenaltyVersionUpdateWithoutBarberEditorInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionUpsertWithWhereUniqueWithoutPenaltyInput = {
  create: PenaltyVersionCreateWithoutPenaltyInput;
  update: PenaltyVersionUpdateWithoutPenaltyInput;
  where: PenaltyVersionWhereUniqueInput;
};

export type PenaltyVersionWhereInput = {
  AND?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  penalty?: InputMaybe<PenaltyNullableRelationFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  penalty?: InputMaybe<PenaltyNullableRelationFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyWhereInput = {
  AND?: InputMaybe<Array<PenaltyWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyWhereInput>>;
  OR?: InputMaybe<Array<PenaltyWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  reason?: InputMaybe<StringFilter>;
};

export type PenaltyWhereUniqueInput = {
  AND?: InputMaybe<Array<PenaltyWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyWhereInput>>;
  OR?: InputMaybe<Array<PenaltyWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<StringFilter>;
};

export type Query = {
  __typename?: 'Query';
  additionalService?: Maybe<AdditionalService>;
  additionalServices: Array<AdditionalService>;
  aggregateAdditionalService: AggregateAdditionalService;
  aggregateAppointment: AggregateAppointment;
  aggregateBarber: AggregateBarber;
  aggregateClient: AggregateClient;
  aggregateService: AggregateService;
  appointment?: Maybe<Appointment>;
  appointments: Array<Appointment>;
  barber?: Maybe<Barber>;
  barberTypes: Array<BarberType>;
  barberWorkTimeOverrides: Array<BarberWorkTimeOverride>;
  barberWorkTimes: Array<BarberWorkTime>;
  barbers: Array<Barber>;
  client?: Maybe<Client>;
  clients: Array<Client>;
  penalties: Array<Penalty>;
  service?: Maybe<Service>;
  services: Array<Service>;
};


export type QueryAdditionalServiceArgs = {
  where: AdditionalServiceWhereUniqueInput;
};


export type QueryAdditionalServicesArgs = {
  cursor?: InputMaybe<AdditionalServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdditionalServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdditionalServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdditionalServiceWhereInput>;
};


export type QueryAggregateAdditionalServiceArgs = {
  cursor?: InputMaybe<AdditionalServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AdditionalServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdditionalServiceWhereInput>;
};


export type QueryAggregateAppointmentArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type QueryAggregateBarberArgs = {
  cursor?: InputMaybe<BarberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BarberOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWhereInput>;
};


export type QueryAggregateClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryAggregateServiceArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QueryAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type QueryAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type QueryBarberArgs = {
  where: BarberWhereUniqueInput;
};


export type QueryBarberWorkTimeOverridesArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};


export type QueryBarberWorkTimesArgs = {
  cursor?: InputMaybe<BarberWorkTimeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};


export type QueryBarbersArgs = {
  cursor?: InputMaybe<BarberWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberWhereInput>;
};


export type QueryClientArgs = {
  where: ClientWhereUniqueInput;
};


export type QueryClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryPenaltiesArgs = {
  cursor?: InputMaybe<PenaltyWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PenaltyWhereInput>;
};


export type QueryServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type QueryServicesArgs = {
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RepeatEnum {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export type Service = {
  __typename?: 'Service';
  Client: Array<Client>;
  ServiceVersion: Array<ServiceVersion>;
  _count?: Maybe<ServiceCount>;
  active: Scalars['Boolean'];
  appointments: Array<Appointment>;
  barberServices: Array<BarberService>;
  bookable: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: Maybe<Scalars['Boolean']>;
};


export type ServiceClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type ServiceServiceVersionArgs = {
  cursor?: InputMaybe<ServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type ServiceAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ServiceBarberServicesArgs = {
  cursor?: InputMaybe<BarberServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type ServiceAvgAggregate = {
  __typename?: 'ServiceAvgAggregate';
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderNo?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  Client: Scalars['Int'];
  ServiceVersion: Scalars['Int'];
  appointments: Scalars['Int'];
  barberServices: Scalars['Int'];
};


export type ServiceCountClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ServiceCountServiceVersionArgs = {
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type ServiceCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ServiceCountBarberServicesArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type ServiceCountAggregate = {
  __typename?: 'ServiceCountAggregate';
  _all: Scalars['Int'];
  active: Scalars['Int'];
  bookable: Scalars['Int'];
  description: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  orderNo: Scalars['Int'];
  price: Scalars['Int'];
  priority: Scalars['Int'];
};

export type ServiceCreateInput = {
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteServiceInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutServiceInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutServiceInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutServiceInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<ServiceCreateWithoutAppointmentsInput>;
};

export type ServiceCreateNestedOneWithoutBarberServicesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutBarberServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutBarberServicesInput>;
};

export type ServiceCreateNestedOneWithoutClientInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<ServiceCreateWithoutClientInput>;
};

export type ServiceCreateNestedOneWithoutServiceVersionInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceVersionInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceVersionInput>;
};

export type ServiceCreateOrConnectWithoutAppointmentsInput = {
  create: ServiceCreateWithoutAppointmentsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutBarberServicesInput = {
  create: ServiceCreateWithoutBarberServicesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutClientInput = {
  create: ServiceCreateWithoutClientInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceVersionInput = {
  create: ServiceCreateWithoutServiceVersionInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateWithoutAppointmentsInput = {
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteServiceInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutServiceInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutServiceInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateWithoutBarberServicesInput = {
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteServiceInput>;
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutServiceInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutServiceInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateWithoutClientInput = {
  ServiceVersion?: InputMaybe<ServiceVersionCreateNestedManyWithoutServiceInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutServiceInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutServiceInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateWithoutServiceVersionInput = {
  Client?: InputMaybe<ClientCreateNestedManyWithoutFavouriteServiceInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutServiceInput>;
  barberServices?: InputMaybe<BarberServiceCreateNestedManyWithoutServiceInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  name: Scalars['String'];
  orderNo?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceMaxAggregate = {
  __typename?: 'ServiceMaxAggregate';
  active?: Maybe<Scalars['Boolean']>;
  bookable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Boolean']>;
};

export type ServiceMinAggregate = {
  __typename?: 'ServiceMinAggregate';
  active?: Maybe<Scalars['Boolean']>;
  bookable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Boolean']>;
};

export type ServiceNullableRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceOrderByWithRelationInput = {
  Client?: InputMaybe<ClientOrderByRelationAggregateInput>;
  ServiceVersion?: InputMaybe<ServiceVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  barberServices?: InputMaybe<BarberServiceOrderByRelationAggregateInput>;
  bookable?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orderNo?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrderInput>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export enum ServiceScalarFieldEnum {
  Active = 'active',
  Bookable = 'bookable',
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  OrderNo = 'orderNo',
  Price = 'price',
  Priority = 'priority'
}

export type ServiceSumAggregate = {
  __typename?: 'ServiceSumAggregate';
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderNo?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

export type ServiceUpdateInput = {
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteServiceNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutServiceNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutServiceNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutServiceNestedInput>;
  bookable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ServiceUpdateOneRequiredWithoutBarberServicesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutBarberServicesInput>;
  create?: InputMaybe<ServiceCreateWithoutBarberServicesInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutBarberServicesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutBarberServicesInput>;
};

export type ServiceUpdateOneWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<ServiceCreateWithoutAppointmentsInput>;
  delete?: InputMaybe<ServiceWhereInput>;
  disconnect?: InputMaybe<ServiceWhereInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutAppointmentsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutAppointmentsInput>;
};

export type ServiceUpdateOneWithoutClientNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<ServiceCreateWithoutClientInput>;
  delete?: InputMaybe<ServiceWhereInput>;
  disconnect?: InputMaybe<ServiceWhereInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutClientInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutClientInput>;
};

export type ServiceUpdateOneWithoutServiceVersionNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceVersionInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceVersionInput>;
  delete?: InputMaybe<ServiceWhereInput>;
  disconnect?: InputMaybe<ServiceWhereInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutServiceVersionInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutServiceVersionInput>;
};

export type ServiceUpdateToOneWithWhereWithoutAppointmentsInput = {
  data: ServiceUpdateWithoutAppointmentsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutBarberServicesInput = {
  data: ServiceUpdateWithoutBarberServicesInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutClientInput = {
  data: ServiceUpdateWithoutClientInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutServiceVersionInput = {
  data: ServiceUpdateWithoutServiceVersionInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateWithoutAppointmentsInput = {
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteServiceNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutServiceNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutServiceNestedInput>;
  bookable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutBarberServicesInput = {
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteServiceNestedInput>;
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutServiceNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutServiceNestedInput>;
  bookable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutClientInput = {
  ServiceVersion?: InputMaybe<ServiceVersionUpdateManyWithoutServiceNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutServiceNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutServiceNestedInput>;
  bookable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutServiceVersionInput = {
  Client?: InputMaybe<ClientUpdateManyWithoutFavouriteServiceNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appointments?: InputMaybe<AppointmentUpdateManyWithoutServiceNestedInput>;
  barberServices?: InputMaybe<BarberServiceUpdateManyWithoutServiceNestedInput>;
  bookable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ServiceUpsertWithoutAppointmentsInput = {
  create: ServiceCreateWithoutAppointmentsInput;
  update: ServiceUpdateWithoutAppointmentsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutBarberServicesInput = {
  create: ServiceCreateWithoutBarberServicesInput;
  update: ServiceUpdateWithoutBarberServicesInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutClientInput = {
  create: ServiceCreateWithoutClientInput;
  update: ServiceUpdateWithoutClientInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutServiceVersionInput = {
  create: ServiceCreateWithoutServiceVersionInput;
  update: ServiceUpdateWithoutServiceVersionInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceVersion = {
  __typename?: 'ServiceVersion';
  active?: Maybe<Scalars['Boolean']>;
  barberEditor?: Maybe<Barber>;
  bookable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Boolean']>;
  service?: Maybe<Service>;
  versionBarberEditorId?: Maybe<Scalars['Int']>;
  versionId: Scalars['Int'];
  versionOperation: AuditOperation;
  versionServiceId?: Maybe<Scalars['Int']>;
  versionTimestamp: Scalars['DateTimeISO'];
};


export type ServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ServiceVersionServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceVersionCreateManyBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionServiceId?: InputMaybe<Scalars['Int']>;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ServiceVersionCreateManyBarberEditorInputEnvelope = {
  data: Array<ServiceVersionCreateManyBarberEditorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceVersionCreateManyServiceInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Boolean']>;
  versionBarberEditorId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ServiceVersionCreateManyServiceInputEnvelope = {
  data: Array<ServiceVersionCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceVersionCreateNestedManyWithoutBarberEditorInput = {
  connect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<ServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<ServiceVersionCreateManyBarberEditorInputEnvelope>;
};

export type ServiceVersionCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceVersionCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<ServiceVersionCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceVersionCreateManyServiceInputEnvelope>;
};

export type ServiceVersionCreateOrConnectWithoutBarberEditorInput = {
  create: ServiceVersionCreateWithoutBarberEditorInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionCreateOrConnectWithoutServiceInput = {
  create: ServiceVersionCreateWithoutServiceInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionCreateWithoutBarberEditorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutServiceVersionInput>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ServiceVersionCreateWithoutServiceInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  barberEditor?: InputMaybe<BarberCreateNestedOneWithoutServiceVersionInput>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Boolean']>;
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO'];
};

export type ServiceVersionListRelationFilter = {
  every?: InputMaybe<ServiceVersionWhereInput>;
  none?: InputMaybe<ServiceVersionWhereInput>;
  some?: InputMaybe<ServiceVersionWhereInput>;
};

export type ServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  bookable?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  orderNo?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  priority?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionServiceId?: InputMaybe<SortOrderInput>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum ServiceVersionScalarFieldEnum {
  Active = 'active',
  Bookable = 'bookable',
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  OrderNo = 'orderNo',
  Price = 'price',
  Priority = 'priority',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionServiceId = 'versionServiceId',
  VersionTimestamp = 'versionTimestamp'
}

export type ServiceVersionScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionScalarWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceVersionUpdateManyMutationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  bookable?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceVersionUpdateManyWithWhereWithoutBarberEditorInput = {
  data: ServiceVersionUpdateManyMutationInput;
  where: ServiceVersionScalarWhereInput;
};

export type ServiceVersionUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceVersionUpdateManyMutationInput;
  where: ServiceVersionScalarWhereInput;
};

export type ServiceVersionUpdateManyWithoutBarberEditorNestedInput = {
  connect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceVersionCreateOrConnectWithoutBarberEditorInput>>;
  create?: InputMaybe<Array<ServiceVersionCreateWithoutBarberEditorInput>>;
  createMany?: InputMaybe<ServiceVersionCreateManyBarberEditorInputEnvelope>;
  delete?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput>>;
  updateMany?: InputMaybe<Array<ServiceVersionUpdateManyWithWhereWithoutBarberEditorInput>>;
  upsert?: InputMaybe<Array<ServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput>>;
};

export type ServiceVersionUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceVersionCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<ServiceVersionCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceVersionCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceVersionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<ServiceVersionUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<ServiceVersionUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceVersionUpdateWithWhereUniqueWithoutBarberEditorInput = {
  data: ServiceVersionUpdateWithoutBarberEditorInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceVersionUpdateWithoutServiceInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionUpdateWithoutBarberEditorInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  bookable?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutServiceVersionNestedInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceVersionUpdateWithoutServiceInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  barberEditor?: InputMaybe<BarberUpdateOneWithoutServiceVersionNestedInput>;
  bookable?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  versionOperation?: InputMaybe<EnumAuditOperationFieldUpdateOperationsInput>;
  versionTimestamp?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceVersionUpsertWithWhereUniqueWithoutBarberEditorInput = {
  create: ServiceVersionCreateWithoutBarberEditorInput;
  update: ServiceVersionUpdateWithoutBarberEditorInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceVersionCreateWithoutServiceInput;
  update: ServiceVersionUpdateWithoutServiceInput;
  where: ServiceVersionWhereUniqueInput;
};

export type ServiceVersionWhereInput = {
  AND?: InputMaybe<Array<ServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  Client?: InputMaybe<ClientListRelationFilter>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  bookable?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
};

export type ServiceWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  Client?: InputMaybe<ClientListRelationFilter>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  bookable?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  appointmentMutationNotification: Notification;
};

export type EditAdditionalService_FragmentFragment = { __typename?: 'AdditionalService', id: number, name: string, price: number, description: string, duration: number };

export type Appointment_FragmentFragment = { __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null };

export type EditBarber_FragmentFragment = { __typename?: 'Barber', id: number, active: boolean, name: string, type: BarberType, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }>, barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', id: number, start?: any | null, end?: any | null, date: any, isFreeDay?: boolean | null, barberId: number }>, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number, serviceId: number }> };

export type EditClient_FragmentFragment = { __typename?: 'Client', id: number, firstName: string, lastName: string, name?: string | null, phone?: string | null, blocked: boolean, blockedReason?: string | null, verified: boolean, active: boolean, favouriteBarberId?: number | null, favouriteServiceId?: number | null, smsNotification: boolean, penalties: Array<{ __typename?: 'Penalty', reason: string }> };

export type EditService_FragmentFragment = { __typename?: 'Service', id: number, name: string, price: number, description?: string | null, duration: number, active: boolean, priority?: boolean | null, bookable: boolean, orderNo?: number | null, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number }> };

export type ChangeBarberEmailMutationVariables = Exact<{
  data: ChangeBarberEmailInput;
  where: BarberWhereUniqueInput;
}>;


export type ChangeBarberEmailMutation = { __typename?: 'Mutation', changeBarberEmail: boolean };

export type ChangeBarberPasswordMutationVariables = Exact<{
  data: ChangeBarberPasswordInput;
  where: BarberWhereUniqueInput;
}>;


export type ChangeBarberPasswordMutation = { __typename?: 'Mutation', changeBarberPassword: boolean };

export type CreateAdditionalServiceMutationVariables = Exact<{
  data: AdditionalServiceCreateInput;
}>;


export type CreateAdditionalServiceMutation = { __typename?: 'Mutation', createOneAdditionalService: { __typename?: 'AdditionalService', id: number } };

export type CreateAppointmentMutationVariables = Exact<{
  data: AppointmentCreateInput;
}>;


export type CreateAppointmentMutation = { __typename?: 'Mutation', createOneAppointment: { __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null } };

export type CreateRepeatableAppointmentMutationVariables = Exact<{
  data: AppointmentCreateRepeatableInput;
}>;


export type CreateRepeatableAppointmentMutation = { __typename?: 'Mutation', createRepeatableAppointment: number };

export type CreateBarberMutationVariables = Exact<{
  data: CreateBarberInput;
}>;


export type CreateBarberMutation = { __typename?: 'Mutation', createBarber: { __typename?: 'Barber', id: number } };

export type CreateClientMutationVariables = Exact<{
  data: ClientCreateInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createOneClient: { __typename?: 'Client', id: number } };

export type CreateServiceMutationVariables = Exact<{
  data: ServiceCreateInput;
}>;


export type CreateServiceMutation = { __typename?: 'Mutation', createOneService: { __typename?: 'Service', id: number } };

export type DeleteAppointmentMutationVariables = Exact<{
  where: AppointmentWhereUniqueInput;
}>;


export type DeleteAppointmentMutation = { __typename?: 'Mutation', deleteOneAppointment?: { __typename?: 'Appointment', id: number } | null };

export type DeleteManyAppointmentMutationVariables = Exact<{
  where: AppointmentWhereInput;
}>;


export type DeleteManyAppointmentMutation = { __typename?: 'Mutation', deleteManyAppointment: { __typename?: 'AffectedRowsOutput', count: number } };

export type UpdateOneAdditionalServiceMutationVariables = Exact<{
  data: AdditionalServiceUpdateInput;
  where: AdditionalServiceWhereUniqueInput;
}>;


export type UpdateOneAdditionalServiceMutation = { __typename?: 'Mutation', updateOneAdditionalService?: { __typename?: 'AdditionalService', id: number, name: string, price: number, description: string, duration: number } | null };

export type UpdateAppointmentMutationVariables = Exact<{
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
}>;


export type UpdateAppointmentMutation = { __typename?: 'Mutation', updateOneAppointment?: { __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null } | null };

export type UpdateOneBarberMutationVariables = Exact<{
  data: BarberUpdateInput;
  where: BarberWhereUniqueInput;
}>;


export type UpdateOneBarberMutation = { __typename?: 'Mutation', updateOneBarber?: { __typename?: 'Barber', id: number, active: boolean, name: string, type: BarberType, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }>, barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', id: number, start?: any | null, end?: any | null, date: any, isFreeDay?: boolean | null, barberId: number }>, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number, serviceId: number }> } | null };

export type UpdateOneClientMutationVariables = Exact<{
  data: ClientUpdateInput;
  where: ClientWhereUniqueInput;
}>;


export type UpdateOneClientMutation = { __typename?: 'Mutation', updateOneClient?: { __typename?: 'Client', id: number, firstName: string, lastName: string, name?: string | null, phone?: string | null, blocked: boolean, blockedReason?: string | null, verified: boolean, active: boolean, favouriteBarberId?: number | null, favouriteServiceId?: number | null, smsNotification: boolean, penalties: Array<{ __typename?: 'Penalty', reason: string }> } | null };

export type UpdateOneServiceMutationVariables = Exact<{
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
}>;


export type UpdateOneServiceMutation = { __typename?: 'Mutation', updateOneService?: { __typename?: 'Service', id: number, name: string, price: number, description?: string | null, duration: number, active: boolean, priority?: boolean | null, bookable: boolean, orderNo?: number | null, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number }> } | null };

export type AdditionalServiceQueryVariables = Exact<{
  where: AdditionalServiceWhereUniqueInput;
}>;


export type AdditionalServiceQuery = { __typename?: 'Query', additionalService?: { __typename?: 'AdditionalService', id: number, name: string, price: number, description: string, duration: number } | null };

export type AdditionalServicesQueryVariables = Exact<{
  where?: InputMaybe<AdditionalServiceWhereInput>;
  orderBy?: InputMaybe<Array<AdditionalServiceOrderByWithRelationInput> | AdditionalServiceOrderByWithRelationInput>;
  cursor?: InputMaybe<AdditionalServiceWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AdditionalServicesQuery = { __typename?: 'Query', additionalServices: Array<{ __typename?: 'AdditionalService', id: number, name: string, price: number, description: string, duration: number }>, aggregateAdditionalService: { __typename?: 'AggregateAdditionalService', _count?: { __typename?: 'AdditionalServiceCountAggregate', _all: number } | null } };

export type AppointmentsQueryVariables = Exact<{
  where?: InputMaybe<AppointmentWhereInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput> | AppointmentOrderByWithRelationInput>;
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum> | AppointmentScalarFieldEnum>;
}>;


export type AppointmentsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null }>, aggregateAppointment: { __typename?: 'AggregateAppointment', _count?: { __typename?: 'AppointmentCountAggregate', _all: number } | null } };

export type AppointmentsWithoutAggregateQueryVariables = Exact<{
  where?: InputMaybe<AppointmentWhereInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput> | AppointmentOrderByWithRelationInput>;
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum> | AppointmentScalarFieldEnum>;
}>;


export type AppointmentsWithoutAggregateQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null }> };

export type AppointmentQueryVariables = Exact<{
  where: AppointmentWhereUniqueInput;
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null } | null };

export type CheckIfAppointmentExistsQueryVariables = Exact<{
  barberId: IntFilter;
  start: Scalars['DateTimeISO'];
  end: Scalars['DateTimeISO'];
}>;


export type CheckIfAppointmentExistsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: number }> };

export type AppointmentsDateChangeQueryVariables = Exact<{
  where?: InputMaybe<AppointmentWhereInput>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput> | AppointmentOrderByWithRelationInput>;
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum> | AppointmentScalarFieldEnum>;
  barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput;
}>;


export type AppointmentsDateChangeQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: number, start: any, end: any, price: any, barberId: number, barberNote?: string | null, repeatId?: string | null, isPause?: boolean | null, phoneContact?: string | null, barber: { __typename?: 'Barber', id: number, name: string }, client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, phone?: string | null, name?: string | null } | null, service?: { __typename?: 'Service', id: number, name: string } | null }>, barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', barberId: number, start?: any | null, end?: any | null, isFreeDay?: boolean | null, date: any }> };

export type AppointmentFormBarbersAndServicesQueryVariables = Exact<{
  whereBarber?: InputMaybe<BarberWhereInput>;
  orderByBarber?: InputMaybe<Array<BarberOrderByWithRelationInput> | BarberOrderByWithRelationInput>;
  whereService?: InputMaybe<ServiceWhereInput>;
  orderByService?: InputMaybe<Array<ServiceOrderByWithRelationInput> | ServiceOrderByWithRelationInput>;
}>;


export type AppointmentFormBarbersAndServicesQuery = { __typename?: 'Query', barbers: Array<{ __typename?: 'Barber', id: number, name: string, type: BarberType, active: boolean, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }> }>, services: Array<{ __typename?: 'Service', id: number, name: string, price: number, description?: string | null, duration: number, active: boolean, priority?: boolean | null, bookable: boolean, orderNo?: number | null }> };

export type BarberQueryVariables = Exact<{
  where: BarberWhereUniqueInput;
}>;


export type BarberQuery = { __typename?: 'Query', barber?: { __typename?: 'Barber', id: number, active: boolean, name: string, type: BarberType, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }>, barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', id: number, start?: any | null, end?: any | null, date: any, isFreeDay?: boolean | null, barberId: number }>, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number, serviceId: number }> } | null };

export type BarberTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type BarberTypesQuery = { __typename?: 'Query', barberTypes: Array<BarberType> };

export type BarbersQueryVariables = Exact<{
  where?: InputMaybe<BarberWhereInput>;
  orderBy?: InputMaybe<Array<BarberOrderByWithRelationInput> | BarberOrderByWithRelationInput>;
  cursor?: InputMaybe<BarberWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type BarbersQuery = { __typename?: 'Query', barbers: Array<{ __typename?: 'Barber', id: number, name: string, type: BarberType, active: boolean }>, aggregateBarber: { __typename?: 'AggregateBarber', _count?: { __typename?: 'BarberCountAggregate', _all: number } | null } };

export type AppointmentBarbersQueryVariables = Exact<{
  where?: InputMaybe<BarberWhereInput>;
  orderBy?: InputMaybe<Array<BarberOrderByWithRelationInput> | BarberOrderByWithRelationInput>;
  cursor?: InputMaybe<BarberWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AppointmentBarbersQuery = { __typename?: 'Query', barbers: Array<{ __typename?: 'Barber', id: number, name: string, type: BarberType, active: boolean, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }> }> };

export type AppointmentBarberWorkTimesQueryVariables = Exact<{
  barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput;
}>;


export type AppointmentBarberWorkTimesQuery = { __typename?: 'Query', barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', barberId: number, start?: any | null, end?: any | null, isFreeDay?: boolean | null, date: any }> };

export type BarberWorkTimesQueryVariables = Exact<{
  barberWorkTimeWhereInput: BarberWorkTimeWhereInput;
  barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput;
}>;


export type BarberWorkTimesQuery = { __typename?: 'Query', barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', barberId: number, start?: any | null, end?: any | null, isFreeDay?: boolean | null, date: any }>, barberWorkTimes: Array<{ __typename?: 'BarberWorkTime', id: number, start: any, end: any, dayOfWeek: number, barberId: number }> };

export type BarberWorkTimeOverridesQueryVariables = Exact<{
  barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput;
}>;


export type BarberWorkTimeOverridesQuery = { __typename?: 'Query', barberWorkTimeOverrides: Array<{ __typename?: 'BarberWorkTimeOverride', barberId: number, start?: any | null, end?: any | null, isFreeDay?: boolean | null, date: any }> };

export type ClientQueryVariables = Exact<{
  where: ClientWhereUniqueInput;
}>;


export type ClientQuery = { __typename?: 'Query', client?: { __typename?: 'Client', id: number, firstName: string, lastName: string, name?: string | null, phone?: string | null, blocked: boolean, blockedReason?: string | null, verified: boolean, active: boolean, favouriteBarberId?: number | null, favouriteServiceId?: number | null, smsNotification: boolean, penalties: Array<{ __typename?: 'Penalty', reason: string }> } | null };

export type ClientsQueryVariables = Exact<{
  where?: InputMaybe<ClientWhereInput>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput> | ClientOrderByWithRelationInput>;
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', id: number, firstName: string, lastName: string, name?: string | null, phone?: string | null, blocked: boolean, blockedReason?: string | null, verified: boolean, active: boolean }>, aggregateClient: { __typename?: 'AggregateClient', _count?: { __typename?: 'ClientCountAggregate', _all: number } | null } };

export type ClientPenaltiesQueryVariables = Exact<{
  where?: InputMaybe<PenaltyWhereInput>;
  orderBy?: InputMaybe<Array<PenaltyOrderByWithRelationInput> | PenaltyOrderByWithRelationInput>;
  cursor?: InputMaybe<PenaltyWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<PenaltyScalarFieldEnum> | PenaltyScalarFieldEnum>;
}>;


export type ClientPenaltiesQuery = { __typename?: 'Query', penalties: Array<{ __typename?: 'Penalty', id: number, reason: string }> };

export type ServiceQueryVariables = Exact<{
  where: ServiceWhereUniqueInput;
}>;


export type ServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: number, name: string, price: number, description?: string | null, duration: number, active: boolean, priority?: boolean | null, bookable: boolean, orderNo?: number | null, barberServices: Array<{ __typename?: 'BarberService', id: number, barberId: number }> } | null };

export type ServicesQueryVariables = Exact<{
  where?: InputMaybe<ServiceWhereInput>;
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput> | ServiceOrderByWithRelationInput>;
  cursor?: InputMaybe<ServiceWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type ServicesQuery = { __typename?: 'Query', services: Array<{ __typename?: 'Service', id: number, name: string, price: number, description?: string | null, duration: number, active: boolean, priority?: boolean | null, bookable: boolean, orderNo?: number | null }>, aggregateService: { __typename?: 'AggregateService', _count?: { __typename?: 'ServiceCountAggregate', _all: number } | null } };

export type AppointmentSubscribeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AppointmentSubscribeSubscription = { __typename?: 'Subscription', appointmentMutationNotification: { __typename?: 'Notification', eventType: EventType, appointmentId: number, barberEditorId?: number | null, barberId: number, appointmentStart?: any | null, previousAppointmentStart?: any | null } };

export const EditAdditionalService_FragmentFragmentDoc = gql`
    fragment EditAdditionalService_Fragment on AdditionalService {
  id
  name
  price
  description
  duration
}
    `;
export const Appointment_FragmentFragmentDoc = gql`
    fragment Appointment_Fragment on Appointment {
  id
  start
  end
  price
  barberId
  barberNote
  repeatId
  isPause
  phoneContact
  barber {
    id
    name
  }
  client {
    id
    firstName
    lastName
    phone
    name
  }
  service {
    id
    name
  }
}
    `;
export const EditBarber_FragmentFragmentDoc = gql`
    fragment EditBarber_Fragment on Barber {
  id
  active
  name
  type
  barberWorkTimes(orderBy: [{dayOfWeek: asc}, {start: asc}]) {
    id
    start
    end
    dayOfWeek
    barberId
  }
  barberWorkTimeOverrides {
    id
    start
    end
    date
    isFreeDay
    barberId
  }
  barberServices {
    id
    barberId
    serviceId
  }
}
    `;
export const EditClient_FragmentFragmentDoc = gql`
    fragment EditClient_Fragment on Client {
  id
  firstName
  lastName
  name
  phone
  blocked
  blockedReason
  verified
  active
  favouriteBarberId
  favouriteServiceId
  smsNotification
  penalties {
    reason
  }
}
    `;
export const EditService_FragmentFragmentDoc = gql`
    fragment EditService_Fragment on Service {
  id
  name
  price
  description
  duration
  active
  priority
  bookable
  orderNo
  barberServices {
    id
    barberId
  }
}
    `;
export const ChangeBarberEmailDocument = gql`
    mutation changeBarberEmail($data: ChangeBarberEmailInput!, $where: BarberWhereUniqueInput!) {
  changeBarberEmail(data: $data, where: $where)
}
    `;
export type ChangeBarberEmailMutationFn = Apollo.MutationFunction<ChangeBarberEmailMutation, ChangeBarberEmailMutationVariables>;

/**
 * __useChangeBarberEmailMutation__
 *
 * To run a mutation, you first call `useChangeBarberEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBarberEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBarberEmailMutation, { data, loading, error }] = useChangeBarberEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChangeBarberEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBarberEmailMutation, ChangeBarberEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBarberEmailMutation, ChangeBarberEmailMutationVariables>(ChangeBarberEmailDocument, options);
      }
export type ChangeBarberEmailMutationHookResult = ReturnType<typeof useChangeBarberEmailMutation>;
export type ChangeBarberEmailMutationResult = Apollo.MutationResult<ChangeBarberEmailMutation>;
export type ChangeBarberEmailMutationOptions = Apollo.BaseMutationOptions<ChangeBarberEmailMutation, ChangeBarberEmailMutationVariables>;
export const ChangeBarberPasswordDocument = gql`
    mutation changeBarberPassword($data: ChangeBarberPasswordInput!, $where: BarberWhereUniqueInput!) {
  changeBarberPassword(data: $data, where: $where)
}
    `;
export type ChangeBarberPasswordMutationFn = Apollo.MutationFunction<ChangeBarberPasswordMutation, ChangeBarberPasswordMutationVariables>;

/**
 * __useChangeBarberPasswordMutation__
 *
 * To run a mutation, you first call `useChangeBarberPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBarberPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBarberPasswordMutation, { data, loading, error }] = useChangeBarberPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChangeBarberPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBarberPasswordMutation, ChangeBarberPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBarberPasswordMutation, ChangeBarberPasswordMutationVariables>(ChangeBarberPasswordDocument, options);
      }
export type ChangeBarberPasswordMutationHookResult = ReturnType<typeof useChangeBarberPasswordMutation>;
export type ChangeBarberPasswordMutationResult = Apollo.MutationResult<ChangeBarberPasswordMutation>;
export type ChangeBarberPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeBarberPasswordMutation, ChangeBarberPasswordMutationVariables>;
export const CreateAdditionalServiceDocument = gql`
    mutation createAdditionalService($data: AdditionalServiceCreateInput!) {
  createOneAdditionalService(data: $data) {
    id
  }
}
    `;
export type CreateAdditionalServiceMutationFn = Apollo.MutationFunction<CreateAdditionalServiceMutation, CreateAdditionalServiceMutationVariables>;

/**
 * __useCreateAdditionalServiceMutation__
 *
 * To run a mutation, you first call `useCreateAdditionalServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdditionalServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdditionalServiceMutation, { data, loading, error }] = useCreateAdditionalServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdditionalServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdditionalServiceMutation, CreateAdditionalServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdditionalServiceMutation, CreateAdditionalServiceMutationVariables>(CreateAdditionalServiceDocument, options);
      }
export type CreateAdditionalServiceMutationHookResult = ReturnType<typeof useCreateAdditionalServiceMutation>;
export type CreateAdditionalServiceMutationResult = Apollo.MutationResult<CreateAdditionalServiceMutation>;
export type CreateAdditionalServiceMutationOptions = Apollo.BaseMutationOptions<CreateAdditionalServiceMutation, CreateAdditionalServiceMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation createAppointment($data: AppointmentCreateInput!) {
  createOneAppointment(data: $data) {
    ...Appointment_Fragment
  }
}
    ${Appointment_FragmentFragmentDoc}`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const CreateRepeatableAppointmentDocument = gql`
    mutation createRepeatableAppointment($data: AppointmentCreateRepeatableInput!) {
  createRepeatableAppointment(data: $data)
}
    `;
export type CreateRepeatableAppointmentMutationFn = Apollo.MutationFunction<CreateRepeatableAppointmentMutation, CreateRepeatableAppointmentMutationVariables>;

/**
 * __useCreateRepeatableAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateRepeatableAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRepeatableAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRepeatableAppointmentMutation, { data, loading, error }] = useCreateRepeatableAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRepeatableAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRepeatableAppointmentMutation, CreateRepeatableAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRepeatableAppointmentMutation, CreateRepeatableAppointmentMutationVariables>(CreateRepeatableAppointmentDocument, options);
      }
export type CreateRepeatableAppointmentMutationHookResult = ReturnType<typeof useCreateRepeatableAppointmentMutation>;
export type CreateRepeatableAppointmentMutationResult = Apollo.MutationResult<CreateRepeatableAppointmentMutation>;
export type CreateRepeatableAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateRepeatableAppointmentMutation, CreateRepeatableAppointmentMutationVariables>;
export const CreateBarberDocument = gql`
    mutation createBarber($data: CreateBarberInput!) {
  createBarber(data: $data) {
    id
  }
}
    `;
export type CreateBarberMutationFn = Apollo.MutationFunction<CreateBarberMutation, CreateBarberMutationVariables>;

/**
 * __useCreateBarberMutation__
 *
 * To run a mutation, you first call `useCreateBarberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBarberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBarberMutation, { data, loading, error }] = useCreateBarberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBarberMutation(baseOptions?: Apollo.MutationHookOptions<CreateBarberMutation, CreateBarberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBarberMutation, CreateBarberMutationVariables>(CreateBarberDocument, options);
      }
export type CreateBarberMutationHookResult = ReturnType<typeof useCreateBarberMutation>;
export type CreateBarberMutationResult = Apollo.MutationResult<CreateBarberMutation>;
export type CreateBarberMutationOptions = Apollo.BaseMutationOptions<CreateBarberMutation, CreateBarberMutationVariables>;
export const CreateClientDocument = gql`
    mutation createClient($data: ClientCreateInput!) {
  createOneClient(data: $data) {
    id
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const CreateServiceDocument = gql`
    mutation createService($data: ServiceCreateInput!) {
  createOneService(data: $data) {
    id
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const DeleteAppointmentDocument = gql`
    mutation deleteAppointment($where: AppointmentWhereUniqueInput!) {
  deleteOneAppointment(where: $where) {
    id
  }
}
    `;
export type DeleteAppointmentMutationFn = Apollo.MutationFunction<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(DeleteAppointmentDocument, options);
      }
export type DeleteAppointmentMutationHookResult = ReturnType<typeof useDeleteAppointmentMutation>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>;
export const DeleteManyAppointmentDocument = gql`
    mutation deleteManyAppointment($where: AppointmentWhereInput!) {
  deleteManyAppointment(where: $where) {
    count
  }
}
    `;
export type DeleteManyAppointmentMutationFn = Apollo.MutationFunction<DeleteManyAppointmentMutation, DeleteManyAppointmentMutationVariables>;

/**
 * __useDeleteManyAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteManyAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyAppointmentMutation, { data, loading, error }] = useDeleteManyAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyAppointmentMutation, DeleteManyAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyAppointmentMutation, DeleteManyAppointmentMutationVariables>(DeleteManyAppointmentDocument, options);
      }
export type DeleteManyAppointmentMutationHookResult = ReturnType<typeof useDeleteManyAppointmentMutation>;
export type DeleteManyAppointmentMutationResult = Apollo.MutationResult<DeleteManyAppointmentMutation>;
export type DeleteManyAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteManyAppointmentMutation, DeleteManyAppointmentMutationVariables>;
export const UpdateOneAdditionalServiceDocument = gql`
    mutation updateOneAdditionalService($data: AdditionalServiceUpdateInput!, $where: AdditionalServiceWhereUniqueInput!) {
  updateOneAdditionalService(data: $data, where: $where) {
    ...EditAdditionalService_Fragment
  }
}
    ${EditAdditionalService_FragmentFragmentDoc}`;
export type UpdateOneAdditionalServiceMutationFn = Apollo.MutationFunction<UpdateOneAdditionalServiceMutation, UpdateOneAdditionalServiceMutationVariables>;

/**
 * __useUpdateOneAdditionalServiceMutation__
 *
 * To run a mutation, you first call `useUpdateOneAdditionalServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAdditionalServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAdditionalServiceMutation, { data, loading, error }] = useUpdateOneAdditionalServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneAdditionalServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAdditionalServiceMutation, UpdateOneAdditionalServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAdditionalServiceMutation, UpdateOneAdditionalServiceMutationVariables>(UpdateOneAdditionalServiceDocument, options);
      }
export type UpdateOneAdditionalServiceMutationHookResult = ReturnType<typeof useUpdateOneAdditionalServiceMutation>;
export type UpdateOneAdditionalServiceMutationResult = Apollo.MutationResult<UpdateOneAdditionalServiceMutation>;
export type UpdateOneAdditionalServiceMutationOptions = Apollo.BaseMutationOptions<UpdateOneAdditionalServiceMutation, UpdateOneAdditionalServiceMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation updateAppointment($data: AppointmentUpdateInput!, $where: AppointmentWhereUniqueInput!) {
  updateOneAppointment(data: $data, where: $where) {
    ...Appointment_Fragment
  }
}
    ${Appointment_FragmentFragmentDoc}`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdateOneBarberDocument = gql`
    mutation updateOneBarber($data: BarberUpdateInput!, $where: BarberWhereUniqueInput!) {
  updateOneBarber(data: $data, where: $where) {
    ...EditBarber_Fragment
  }
}
    ${EditBarber_FragmentFragmentDoc}`;
export type UpdateOneBarberMutationFn = Apollo.MutationFunction<UpdateOneBarberMutation, UpdateOneBarberMutationVariables>;

/**
 * __useUpdateOneBarberMutation__
 *
 * To run a mutation, you first call `useUpdateOneBarberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneBarberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBarberMutation, { data, loading, error }] = useUpdateOneBarberMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneBarberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneBarberMutation, UpdateOneBarberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneBarberMutation, UpdateOneBarberMutationVariables>(UpdateOneBarberDocument, options);
      }
export type UpdateOneBarberMutationHookResult = ReturnType<typeof useUpdateOneBarberMutation>;
export type UpdateOneBarberMutationResult = Apollo.MutationResult<UpdateOneBarberMutation>;
export type UpdateOneBarberMutationOptions = Apollo.BaseMutationOptions<UpdateOneBarberMutation, UpdateOneBarberMutationVariables>;
export const UpdateOneClientDocument = gql`
    mutation updateOneClient($data: ClientUpdateInput!, $where: ClientWhereUniqueInput!) {
  updateOneClient(data: $data, where: $where) {
    ...EditClient_Fragment
  }
}
    ${EditClient_FragmentFragmentDoc}`;
export type UpdateOneClientMutationFn = Apollo.MutationFunction<UpdateOneClientMutation, UpdateOneClientMutationVariables>;

/**
 * __useUpdateOneClientMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClientMutation, { data, loading, error }] = useUpdateOneClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClientMutation, UpdateOneClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClientMutation, UpdateOneClientMutationVariables>(UpdateOneClientDocument, options);
      }
export type UpdateOneClientMutationHookResult = ReturnType<typeof useUpdateOneClientMutation>;
export type UpdateOneClientMutationResult = Apollo.MutationResult<UpdateOneClientMutation>;
export type UpdateOneClientMutationOptions = Apollo.BaseMutationOptions<UpdateOneClientMutation, UpdateOneClientMutationVariables>;
export const UpdateOneServiceDocument = gql`
    mutation updateOneService($data: ServiceUpdateInput!, $where: ServiceWhereUniqueInput!) {
  updateOneService(data: $data, where: $where) {
    ...EditService_Fragment
  }
}
    ${EditService_FragmentFragmentDoc}`;
export type UpdateOneServiceMutationFn = Apollo.MutationFunction<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>;

/**
 * __useUpdateOneServiceMutation__
 *
 * To run a mutation, you first call `useUpdateOneServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneServiceMutation, { data, loading, error }] = useUpdateOneServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>(UpdateOneServiceDocument, options);
      }
export type UpdateOneServiceMutationHookResult = ReturnType<typeof useUpdateOneServiceMutation>;
export type UpdateOneServiceMutationResult = Apollo.MutationResult<UpdateOneServiceMutation>;
export type UpdateOneServiceMutationOptions = Apollo.BaseMutationOptions<UpdateOneServiceMutation, UpdateOneServiceMutationVariables>;
export const AdditionalServiceDocument = gql`
    query additionalService($where: AdditionalServiceWhereUniqueInput!) {
  additionalService(where: $where) {
    ...EditAdditionalService_Fragment
  }
}
    ${EditAdditionalService_FragmentFragmentDoc}`;

/**
 * __useAdditionalServiceQuery__
 *
 * To run a query within a React component, call `useAdditionalServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdditionalServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdditionalServiceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdditionalServiceQuery(baseOptions: Apollo.QueryHookOptions<AdditionalServiceQuery, AdditionalServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdditionalServiceQuery, AdditionalServiceQueryVariables>(AdditionalServiceDocument, options);
      }
export function useAdditionalServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdditionalServiceQuery, AdditionalServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdditionalServiceQuery, AdditionalServiceQueryVariables>(AdditionalServiceDocument, options);
        }
export type AdditionalServiceQueryHookResult = ReturnType<typeof useAdditionalServiceQuery>;
export type AdditionalServiceLazyQueryHookResult = ReturnType<typeof useAdditionalServiceLazyQuery>;
export type AdditionalServiceQueryResult = Apollo.QueryResult<AdditionalServiceQuery, AdditionalServiceQueryVariables>;
export const AdditionalServicesDocument = gql`
    query additionalServices($where: AdditionalServiceWhereInput, $orderBy: [AdditionalServiceOrderByWithRelationInput!], $cursor: AdditionalServiceWhereUniqueInput, $take: Int, $skip: Int) {
  additionalServices(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    id
    name
    price
    description
    duration
  }
  aggregateAdditionalService(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useAdditionalServicesQuery__
 *
 * To run a query within a React component, call `useAdditionalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdditionalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdditionalServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAdditionalServicesQuery(baseOptions?: Apollo.QueryHookOptions<AdditionalServicesQuery, AdditionalServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdditionalServicesQuery, AdditionalServicesQueryVariables>(AdditionalServicesDocument, options);
      }
export function useAdditionalServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdditionalServicesQuery, AdditionalServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdditionalServicesQuery, AdditionalServicesQueryVariables>(AdditionalServicesDocument, options);
        }
export type AdditionalServicesQueryHookResult = ReturnType<typeof useAdditionalServicesQuery>;
export type AdditionalServicesLazyQueryHookResult = ReturnType<typeof useAdditionalServicesLazyQuery>;
export type AdditionalServicesQueryResult = Apollo.QueryResult<AdditionalServicesQuery, AdditionalServicesQueryVariables>;
export const AppointmentsDocument = gql`
    query appointments($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int, $distinct: [AppointmentScalarFieldEnum!]) {
  appointments(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...Appointment_Fragment
  }
  aggregateAppointment(where: $where) {
    _count {
      _all
    }
  }
}
    ${Appointment_FragmentFragmentDoc}`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const AppointmentsWithoutAggregateDocument = gql`
    query appointmentsWithoutAggregate($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int, $distinct: [AppointmentScalarFieldEnum!]) {
  appointments(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...Appointment_Fragment
  }
}
    ${Appointment_FragmentFragmentDoc}`;

/**
 * __useAppointmentsWithoutAggregateQuery__
 *
 * To run a query within a React component, call `useAppointmentsWithoutAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsWithoutAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsWithoutAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useAppointmentsWithoutAggregateQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsWithoutAggregateQuery, AppointmentsWithoutAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsWithoutAggregateQuery, AppointmentsWithoutAggregateQueryVariables>(AppointmentsWithoutAggregateDocument, options);
      }
export function useAppointmentsWithoutAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsWithoutAggregateQuery, AppointmentsWithoutAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsWithoutAggregateQuery, AppointmentsWithoutAggregateQueryVariables>(AppointmentsWithoutAggregateDocument, options);
        }
export type AppointmentsWithoutAggregateQueryHookResult = ReturnType<typeof useAppointmentsWithoutAggregateQuery>;
export type AppointmentsWithoutAggregateLazyQueryHookResult = ReturnType<typeof useAppointmentsWithoutAggregateLazyQuery>;
export type AppointmentsWithoutAggregateQueryResult = Apollo.QueryResult<AppointmentsWithoutAggregateQuery, AppointmentsWithoutAggregateQueryVariables>;
export const AppointmentDocument = gql`
    query appointment($where: AppointmentWhereUniqueInput!) {
  appointment(where: $where) {
    ...Appointment_Fragment
  }
}
    ${Appointment_FragmentFragmentDoc}`;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const CheckIfAppointmentExistsDocument = gql`
    query checkIfAppointmentExists($barberId: IntFilter!, $start: DateTimeISO!, $end: DateTimeISO!) {
  appointments(
    where: {barberId: $barberId, AND: [{start: {lt: $end}}, {end: {gt: $start}}]}
  ) {
    id
  }
}
    `;

/**
 * __useCheckIfAppointmentExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfAppointmentExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfAppointmentExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfAppointmentExistsQuery({
 *   variables: {
 *      barberId: // value for 'barberId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCheckIfAppointmentExistsQuery(baseOptions: Apollo.QueryHookOptions<CheckIfAppointmentExistsQuery, CheckIfAppointmentExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIfAppointmentExistsQuery, CheckIfAppointmentExistsQueryVariables>(CheckIfAppointmentExistsDocument, options);
      }
export function useCheckIfAppointmentExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIfAppointmentExistsQuery, CheckIfAppointmentExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIfAppointmentExistsQuery, CheckIfAppointmentExistsQueryVariables>(CheckIfAppointmentExistsDocument, options);
        }
export type CheckIfAppointmentExistsQueryHookResult = ReturnType<typeof useCheckIfAppointmentExistsQuery>;
export type CheckIfAppointmentExistsLazyQueryHookResult = ReturnType<typeof useCheckIfAppointmentExistsLazyQuery>;
export type CheckIfAppointmentExistsQueryResult = Apollo.QueryResult<CheckIfAppointmentExistsQuery, CheckIfAppointmentExistsQueryVariables>;
export const AppointmentsDateChangeDocument = gql`
    query appointmentsDateChange($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int, $distinct: [AppointmentScalarFieldEnum!], $barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput!) {
  appointments(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...Appointment_Fragment
  }
  barberWorkTimeOverrides(where: $barberWorkTimeOverrideWhereInput) {
    barberId
    start
    end
    isFreeDay
    date
  }
}
    ${Appointment_FragmentFragmentDoc}`;

/**
 * __useAppointmentsDateChangeQuery__
 *
 * To run a query within a React component, call `useAppointmentsDateChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsDateChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsDateChangeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *      barberWorkTimeOverrideWhereInput: // value for 'barberWorkTimeOverrideWhereInput'
 *   },
 * });
 */
export function useAppointmentsDateChangeQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsDateChangeQuery, AppointmentsDateChangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsDateChangeQuery, AppointmentsDateChangeQueryVariables>(AppointmentsDateChangeDocument, options);
      }
export function useAppointmentsDateChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsDateChangeQuery, AppointmentsDateChangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsDateChangeQuery, AppointmentsDateChangeQueryVariables>(AppointmentsDateChangeDocument, options);
        }
export type AppointmentsDateChangeQueryHookResult = ReturnType<typeof useAppointmentsDateChangeQuery>;
export type AppointmentsDateChangeLazyQueryHookResult = ReturnType<typeof useAppointmentsDateChangeLazyQuery>;
export type AppointmentsDateChangeQueryResult = Apollo.QueryResult<AppointmentsDateChangeQuery, AppointmentsDateChangeQueryVariables>;
export const AppointmentFormBarbersAndServicesDocument = gql`
    query appointmentFormBarbersAndServices($whereBarber: BarberWhereInput, $orderByBarber: [BarberOrderByWithRelationInput!], $whereService: ServiceWhereInput, $orderByService: [ServiceOrderByWithRelationInput!]) {
  barbers(where: $whereBarber, orderBy: $orderByBarber) {
    id
    name
    type
    active
    barberWorkTimes {
      id
      start
      end
      dayOfWeek
      barberId
    }
  }
  services(where: $whereService, orderBy: $orderByService) {
    id
    name
    price
    description
    duration
    active
    priority
    bookable
    orderNo
  }
}
    `;

/**
 * __useAppointmentFormBarbersAndServicesQuery__
 *
 * To run a query within a React component, call `useAppointmentFormBarbersAndServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentFormBarbersAndServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentFormBarbersAndServicesQuery({
 *   variables: {
 *      whereBarber: // value for 'whereBarber'
 *      orderByBarber: // value for 'orderByBarber'
 *      whereService: // value for 'whereService'
 *      orderByService: // value for 'orderByService'
 *   },
 * });
 */
export function useAppointmentFormBarbersAndServicesQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentFormBarbersAndServicesQuery, AppointmentFormBarbersAndServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentFormBarbersAndServicesQuery, AppointmentFormBarbersAndServicesQueryVariables>(AppointmentFormBarbersAndServicesDocument, options);
      }
export function useAppointmentFormBarbersAndServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentFormBarbersAndServicesQuery, AppointmentFormBarbersAndServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentFormBarbersAndServicesQuery, AppointmentFormBarbersAndServicesQueryVariables>(AppointmentFormBarbersAndServicesDocument, options);
        }
export type AppointmentFormBarbersAndServicesQueryHookResult = ReturnType<typeof useAppointmentFormBarbersAndServicesQuery>;
export type AppointmentFormBarbersAndServicesLazyQueryHookResult = ReturnType<typeof useAppointmentFormBarbersAndServicesLazyQuery>;
export type AppointmentFormBarbersAndServicesQueryResult = Apollo.QueryResult<AppointmentFormBarbersAndServicesQuery, AppointmentFormBarbersAndServicesQueryVariables>;
export const BarberDocument = gql`
    query barber($where: BarberWhereUniqueInput!) {
  barber(where: $where) {
    ...EditBarber_Fragment
  }
}
    ${EditBarber_FragmentFragmentDoc}`;

/**
 * __useBarberQuery__
 *
 * To run a query within a React component, call `useBarberQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBarberQuery(baseOptions: Apollo.QueryHookOptions<BarberQuery, BarberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarberQuery, BarberQueryVariables>(BarberDocument, options);
      }
export function useBarberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarberQuery, BarberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarberQuery, BarberQueryVariables>(BarberDocument, options);
        }
export type BarberQueryHookResult = ReturnType<typeof useBarberQuery>;
export type BarberLazyQueryHookResult = ReturnType<typeof useBarberLazyQuery>;
export type BarberQueryResult = Apollo.QueryResult<BarberQuery, BarberQueryVariables>;
export const BarberTypesDocument = gql`
    query BarberTypes {
  barberTypes
}
    `;

/**
 * __useBarberTypesQuery__
 *
 * To run a query within a React component, call `useBarberTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarberTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarberTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBarberTypesQuery(baseOptions?: Apollo.QueryHookOptions<BarberTypesQuery, BarberTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarberTypesQuery, BarberTypesQueryVariables>(BarberTypesDocument, options);
      }
export function useBarberTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarberTypesQuery, BarberTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarberTypesQuery, BarberTypesQueryVariables>(BarberTypesDocument, options);
        }
export type BarberTypesQueryHookResult = ReturnType<typeof useBarberTypesQuery>;
export type BarberTypesLazyQueryHookResult = ReturnType<typeof useBarberTypesLazyQuery>;
export type BarberTypesQueryResult = Apollo.QueryResult<BarberTypesQuery, BarberTypesQueryVariables>;
export const BarbersDocument = gql`
    query barbers($where: BarberWhereInput, $orderBy: [BarberOrderByWithRelationInput!], $cursor: BarberWhereUniqueInput, $take: Int, $skip: Int) {
  barbers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    id
    name
    type
    active
  }
  aggregateBarber(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useBarbersQuery__
 *
 * To run a query within a React component, call `useBarbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarbersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useBarbersQuery(baseOptions?: Apollo.QueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
      }
export function useBarbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
        }
export type BarbersQueryHookResult = ReturnType<typeof useBarbersQuery>;
export type BarbersLazyQueryHookResult = ReturnType<typeof useBarbersLazyQuery>;
export type BarbersQueryResult = Apollo.QueryResult<BarbersQuery, BarbersQueryVariables>;
export const AppointmentBarbersDocument = gql`
    query appointmentBarbers($where: BarberWhereInput, $orderBy: [BarberOrderByWithRelationInput!], $cursor: BarberWhereUniqueInput, $take: Int, $skip: Int) {
  barbers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    id
    name
    type
    active
    barberWorkTimes {
      id
      start
      end
      dayOfWeek
      barberId
    }
  }
}
    `;

/**
 * __useAppointmentBarbersQuery__
 *
 * To run a query within a React component, call `useAppointmentBarbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentBarbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentBarbersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAppointmentBarbersQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentBarbersQuery, AppointmentBarbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentBarbersQuery, AppointmentBarbersQueryVariables>(AppointmentBarbersDocument, options);
      }
export function useAppointmentBarbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentBarbersQuery, AppointmentBarbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentBarbersQuery, AppointmentBarbersQueryVariables>(AppointmentBarbersDocument, options);
        }
export type AppointmentBarbersQueryHookResult = ReturnType<typeof useAppointmentBarbersQuery>;
export type AppointmentBarbersLazyQueryHookResult = ReturnType<typeof useAppointmentBarbersLazyQuery>;
export type AppointmentBarbersQueryResult = Apollo.QueryResult<AppointmentBarbersQuery, AppointmentBarbersQueryVariables>;
export const AppointmentBarberWorkTimesDocument = gql`
    query appointmentBarberWorkTimes($barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput!) {
  barberWorkTimeOverrides(where: $barberWorkTimeOverrideWhereInput) {
    barberId
    start
    end
    isFreeDay
    date
  }
}
    `;

/**
 * __useAppointmentBarberWorkTimesQuery__
 *
 * To run a query within a React component, call `useAppointmentBarberWorkTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentBarberWorkTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentBarberWorkTimesQuery({
 *   variables: {
 *      barberWorkTimeOverrideWhereInput: // value for 'barberWorkTimeOverrideWhereInput'
 *   },
 * });
 */
export function useAppointmentBarberWorkTimesQuery(baseOptions: Apollo.QueryHookOptions<AppointmentBarberWorkTimesQuery, AppointmentBarberWorkTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentBarberWorkTimesQuery, AppointmentBarberWorkTimesQueryVariables>(AppointmentBarberWorkTimesDocument, options);
      }
export function useAppointmentBarberWorkTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentBarberWorkTimesQuery, AppointmentBarberWorkTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentBarberWorkTimesQuery, AppointmentBarberWorkTimesQueryVariables>(AppointmentBarberWorkTimesDocument, options);
        }
export type AppointmentBarberWorkTimesQueryHookResult = ReturnType<typeof useAppointmentBarberWorkTimesQuery>;
export type AppointmentBarberWorkTimesLazyQueryHookResult = ReturnType<typeof useAppointmentBarberWorkTimesLazyQuery>;
export type AppointmentBarberWorkTimesQueryResult = Apollo.QueryResult<AppointmentBarberWorkTimesQuery, AppointmentBarberWorkTimesQueryVariables>;
export const BarberWorkTimesDocument = gql`
    query barberWorkTimes($barberWorkTimeWhereInput: BarberWorkTimeWhereInput!, $barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput!) {
  barberWorkTimeOverrides(where: $barberWorkTimeOverrideWhereInput) {
    barberId
    start
    end
    isFreeDay
    date
  }
  barberWorkTimes(where: $barberWorkTimeWhereInput) {
    id
    start
    end
    dayOfWeek
    barberId
  }
}
    `;

/**
 * __useBarberWorkTimesQuery__
 *
 * To run a query within a React component, call `useBarberWorkTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarberWorkTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarberWorkTimesQuery({
 *   variables: {
 *      barberWorkTimeWhereInput: // value for 'barberWorkTimeWhereInput'
 *      barberWorkTimeOverrideWhereInput: // value for 'barberWorkTimeOverrideWhereInput'
 *   },
 * });
 */
export function useBarberWorkTimesQuery(baseOptions: Apollo.QueryHookOptions<BarberWorkTimesQuery, BarberWorkTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarberWorkTimesQuery, BarberWorkTimesQueryVariables>(BarberWorkTimesDocument, options);
      }
export function useBarberWorkTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarberWorkTimesQuery, BarberWorkTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarberWorkTimesQuery, BarberWorkTimesQueryVariables>(BarberWorkTimesDocument, options);
        }
export type BarberWorkTimesQueryHookResult = ReturnType<typeof useBarberWorkTimesQuery>;
export type BarberWorkTimesLazyQueryHookResult = ReturnType<typeof useBarberWorkTimesLazyQuery>;
export type BarberWorkTimesQueryResult = Apollo.QueryResult<BarberWorkTimesQuery, BarberWorkTimesQueryVariables>;
export const BarberWorkTimeOverridesDocument = gql`
    query barberWorkTimeOverrides($barberWorkTimeOverrideWhereInput: BarberWorkTimeOverrideWhereInput!) {
  barberWorkTimeOverrides(where: $barberWorkTimeOverrideWhereInput) {
    barberId
    start
    end
    isFreeDay
    date
  }
}
    `;

/**
 * __useBarberWorkTimeOverridesQuery__
 *
 * To run a query within a React component, call `useBarberWorkTimeOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarberWorkTimeOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarberWorkTimeOverridesQuery({
 *   variables: {
 *      barberWorkTimeOverrideWhereInput: // value for 'barberWorkTimeOverrideWhereInput'
 *   },
 * });
 */
export function useBarberWorkTimeOverridesQuery(baseOptions: Apollo.QueryHookOptions<BarberWorkTimeOverridesQuery, BarberWorkTimeOverridesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarberWorkTimeOverridesQuery, BarberWorkTimeOverridesQueryVariables>(BarberWorkTimeOverridesDocument, options);
      }
export function useBarberWorkTimeOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarberWorkTimeOverridesQuery, BarberWorkTimeOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarberWorkTimeOverridesQuery, BarberWorkTimeOverridesQueryVariables>(BarberWorkTimeOverridesDocument, options);
        }
export type BarberWorkTimeOverridesQueryHookResult = ReturnType<typeof useBarberWorkTimeOverridesQuery>;
export type BarberWorkTimeOverridesLazyQueryHookResult = ReturnType<typeof useBarberWorkTimeOverridesLazyQuery>;
export type BarberWorkTimeOverridesQueryResult = Apollo.QueryResult<BarberWorkTimeOverridesQuery, BarberWorkTimeOverridesQueryVariables>;
export const ClientDocument = gql`
    query client($where: ClientWhereUniqueInput!) {
  client(where: $where) {
    ...EditClient_Fragment
  }
}
    ${EditClient_FragmentFragmentDoc}`;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientsDocument = gql`
    query clients($where: ClientWhereInput, $orderBy: [ClientOrderByWithRelationInput!], $cursor: ClientWhereUniqueInput, $take: Int, $skip: Int) {
  clients(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    id
    firstName
    lastName
    name
    phone
    blocked
    blockedReason
    verified
    active
  }
  aggregateClient(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ClientPenaltiesDocument = gql`
    query clientPenalties($where: PenaltyWhereInput, $orderBy: [PenaltyOrderByWithRelationInput!], $cursor: PenaltyWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PenaltyScalarFieldEnum!]) {
  penalties(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    reason
  }
}
    `;

/**
 * __useClientPenaltiesQuery__
 *
 * To run a query within a React component, call `useClientPenaltiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPenaltiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPenaltiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useClientPenaltiesQuery(baseOptions?: Apollo.QueryHookOptions<ClientPenaltiesQuery, ClientPenaltiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPenaltiesQuery, ClientPenaltiesQueryVariables>(ClientPenaltiesDocument, options);
      }
export function useClientPenaltiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPenaltiesQuery, ClientPenaltiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPenaltiesQuery, ClientPenaltiesQueryVariables>(ClientPenaltiesDocument, options);
        }
export type ClientPenaltiesQueryHookResult = ReturnType<typeof useClientPenaltiesQuery>;
export type ClientPenaltiesLazyQueryHookResult = ReturnType<typeof useClientPenaltiesLazyQuery>;
export type ClientPenaltiesQueryResult = Apollo.QueryResult<ClientPenaltiesQuery, ClientPenaltiesQueryVariables>;
export const ServiceDocument = gql`
    query service($where: ServiceWhereUniqueInput!) {
  service(where: $where) {
    ...EditService_Fragment
  }
}
    ${EditService_FragmentFragmentDoc}`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const ServicesDocument = gql`
    query services($where: ServiceWhereInput, $orderBy: [ServiceOrderByWithRelationInput!], $cursor: ServiceWhereUniqueInput, $take: Int, $skip: Int) {
  services(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    id
    name
    price
    description
    duration
    active
    priority
    bookable
    orderNo
  }
  aggregateService(where: $where) {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const AppointmentSubscribeDocument = gql`
    subscription appointmentSubscribe {
  appointmentMutationNotification {
    eventType
    appointmentId
    barberEditorId
    barberId
    appointmentStart
    previousAppointmentStart
  }
}
    `;

/**
 * __useAppointmentSubscribeSubscription__
 *
 * To run a query within a React component, call `useAppointmentSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AppointmentSubscribeSubscription, AppointmentSubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AppointmentSubscribeSubscription, AppointmentSubscribeSubscriptionVariables>(AppointmentSubscribeDocument, options);
      }
export type AppointmentSubscribeSubscriptionHookResult = ReturnType<typeof useAppointmentSubscribeSubscription>;
export type AppointmentSubscribeSubscriptionResult = Apollo.SubscriptionResult<AppointmentSubscribeSubscription>;