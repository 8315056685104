import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { Button } from 'components/common/buttons/Button/Button';
import { Table } from 'components/common/Table/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalServicesViewModel } from './AdditionalServicePage.vm';

const AdditionalServicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { columns, data, handleTableChange, navigate, loading, pagination } = AdditionalServicesViewModel();

  return (
    <>
      <PageTitle>{t('common.additionalServices')}</PageTitle>
      <S.TablesWrapper>
        <S.Card
          id="basic-table"
          title={t('common.additionalServices')}
          extra={
            <Button onClick={() => navigate('/additional-services/create')}>
              {t('common.additionalServiceCreate')}
            </Button>
          }
          padding="1.25rem 1.25rem 0"
        >
          <Table
            columns={columns}
            dataSource={data?.additionalServices}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
            rowKey={'id'}
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};

export default AdditionalServicesPage;
