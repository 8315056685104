import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {
  ClientWhereInput,
  SortOrder,
  useAppointmentFormBarbersAndServicesQuery,
  useCheckIfAppointmentExistsLazyQuery,
  useClientLazyQuery,
  useClientsLazyQuery,
} from '@app/graphql/generated';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Form } from 'antd';
import { assign, capitalize, cloneDeep, debounce, isEqual, lowerCase, omit } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setFormVisible } from '../../store/appointmentFormSlice';
import {
  AppointmentFormFields,
  AppointmentRepeatFormFields,
  FormViewModelReturnType,
  FormViewModelType,
} from './Form.types';

export const FormViewModel: FormViewModelType = (appointment, onFinish) => {
  const dispatch = useAppDispatch();

  const [clientModalVisible, setClientModalVisible] = useState<FormViewModelReturnType['clientModalVisible']>(false);
  const [form] = BaseButtonsForm.useForm<AppointmentFormFields>();
  const [serviceChanged, setServiceChanged] = useState<boolean>(false);

  const [checkIfAppointmentExists, { data: appointmentExistsData }] = useCheckIfAppointmentExistsLazyQuery();

  const [fetchClient, { data: clientData }] = useClientLazyQuery();

  const clientId = Form.useWatch('clientId', form);
  const isPause = Form.useWatch('isPause', form);

  useEffect(() => {
    if (clientId) {
      fetchClient({
        variables: {
          where: {
            id: clientId,
          },
        },
      });
    }
  }, [clientId, fetchClient]);

  const { data: barbersAndServicesData } = useAppointmentFormBarbersAndServicesQuery({
    fetchPolicy: 'cache-only',
    variables: {
      orderByService: [
        {
          orderNo: {
            sort: SortOrder.Asc,
          },
        },
        {
          name: SortOrder.Asc,
        },
      ],
      whereBarber: {
        active: {
          equals: true,
        },
      },
      whereService: {
        active: {
          equals: true,
        },
        bookable: {
          equals: true,
        },
      },
    },
  });

  const [fetchClients, { data: clientsData }] = useClientsLazyQuery();

  useEffect(() => {
    if (appointment.clientId) {
      fetchClients({
        variables: {
          where: {
            id: {
              equals: appointment.clientId,
            },
          },
        },
      });
    }
  }, [appointment.clientId, fetchClients]);

  const clientOptions: FormViewModelReturnType['clientOptions'] = useMemo(() => {
    const options =
      clientsData?.clients.map((client) => ({
        label: `${client.firstName} ${client.lastName} - ${client.phone}${client.name ? ` / ( ${client.name} )` : ''}`,
        value: client.id,
      })) || [];

    return options;
  }, [clientsData?.clients]);

  const createSearchOR = (
    key: keyof ClientWhereInput,
    searchValue: string,
    noSplitting = false,
  ): ClientWhereInput['OR'] => {
    const whereOr: ClientWhereInput['OR'] = [];

    const handleWord = (word: string) => {
      const capitalizedSearchWord = capitalize(word);
      const lowerCaseSearchWord = lowerCase(word);

      whereOr.push({ [key]: { contains: capitalizedSearchWord } });
      whereOr.push({ [key]: { contains: lowerCaseSearchWord } });
      whereOr.push({ [key]: { contains: word } });
    };

    if (noSplitting) {
      handleWord(searchValue);
      return whereOr;
    }

    searchValue.split(' ').forEach((word) => {
      if (word) {
        handleWord(word);
      }
    });

    return whereOr;
  };

  const debounceFetchClients: FormViewModelReturnType['debounceFetchClients'] = useMemo(() => {
    const loadOptions = async (searchValue: string) => {
      if (!searchValue) {
        return;
      }

      const searchOR: ClientWhereInput['OR'] = [
        {
          phone: {
            contains: searchValue,
          },
        },
      ];

      const searchORFirstName = createSearchOR('firstName', searchValue);
      const searchORLastName = createSearchOR('lastName', searchValue);
      const searchORName = createSearchOR('name', searchValue, true);

      fetchClients({
        variables: {
          where: {
            OR: searchOR
              .concat(searchORFirstName || [])
              .concat(searchORLastName || [])
              .concat(searchORName || []),
            active: {
              equals: true,
            },
          },
        },
      });
    };

    return debounce(loadOptions, 700);
  }, [fetchClients]);

  const onServiceChange: FormViewModelReturnType['onServiceChange'] = useCallback(
    (value) => {
      const service = barbersAndServicesData?.services.find((service) => service.id === value);
      if (service) {
        form.setFieldsValue({ price: service?.price });
        const dateRange = form.getFieldValue('dateRange');
        if (dateRange && dateRange.length > 0) {
          form.setFieldsValue({
            dateRange: [moment(dateRange[0]), moment(moment(dateRange[0]).add(service.duration, 'minutes'))],
          });
          setServiceChanged(true);
        }
      }
    },
    [form, barbersAndServicesData?.services],
  );

  const appointmentTime: FormViewModelReturnType['appointmentTime'] = useMemo(() => {
    return [moment(appointment.start), moment(appointment.end)];
  }, [appointment.start, appointment.end]);

  const openClientModal: FormViewModelReturnType['openClientModal'] = () => {
    setClientModalVisible(true);
  };

  const closeClientModal: FormViewModelReturnType['closeClientModal'] = () => {
    setClientModalVisible(false);
  };

  const onFormFinish: FormViewModelReturnType['onFormFinish'] = useCallback(
    async (name, info) => {
      const values = info.values as AppointmentFormFields;
      const withoutDateRange = omit(cloneDeep(values), ['dateRange']);
      const appointmentCopy = assign(
        {
          end: values.dateRange[1],
          start: values.dateRange[0],
        },
        withoutDateRange,
      );

      if (isEqual(omit(appointment, ['id']), appointmentCopy)) {
        dispatch(setFormVisible(false));
        return;
      }

      const repeatableFormfields = info.forms.repeatableForm?.getFieldsValue([
        'repeated',
        'repeatEndDate',
        'repeatOption',
        'repeatFrequency',
        'customRepeatWeekDays',
      ]) as AppointmentRepeatFormFields | undefined;

      onFinish(
        {
          barberId: values.barberId,
          barberNote: values.barberNote,
          clientId: values.clientId,
          end: values.dateRange[1]
            .set({
              milliseconds: 0,
              seconds: 0,
            })
            .toISOString(),
          id: appointment.id,
          isPause: values.isPause,
          phoneContact: values.phoneContact || '',
          price: values.price,
          serviceId: values.serviceId,
          start: values.dateRange[0]
            .set({
              milliseconds: 0,
              seconds: 0,
            })
            .toISOString(),
        },
        form.resetFields,
        repeatableFormfields,
      );
    },
    [appointment, dispatch, form.resetFields, onFinish],
  );

  const barberId = Form.useWatch('barberId', form);
  const dateRange = Form.useWatch('dateRange', form);
  const serviceId = Form.useWatch('serviceId', form);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (dateRange && barberId) {
      timeoutId = setTimeout(() => {
        checkIfAppointmentExists({
          variables: {
            barberId: {
              equals: barberId,
            },
            end: dateRange[1].toISOString(),
            start: dateRange[0].toISOString(),
          },
        });
      }, 2000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [barberId, checkIfAppointmentExists, dateRange]);

  const appointmentExists = useMemo(() => {
    if (
      appointmentExistsData?.appointments.length &&
      appointmentExistsData?.appointments[0]?.id &&
      barberId &&
      dateRange?.length &&
      dateRange[0]
    ) {
      if (appointmentExistsData?.appointments.length > 1) {
        return true;
      }

      if (appointmentExistsData?.appointments[0]?.id === appointment.id) {
        return false;
      }

      return true;
    }

    return false;
  }, [appointment.id, appointmentExistsData?.appointments, barberId, dateRange]);

  // set serviceChanged to true when service is changed and after 5 seconds change it to false
  useEffect(() => {
    if (serviceId) {
      setTimeout(() => {
        setServiceChanged(false);
      }, 7000);
    }
  }, [serviceId]);

  const response = useMemo(
    () => ({
      appointmentExists,
      appointmentTime,
      clientData,
      clientModalVisible,
      clientOptions,
      closeClientModal,
      debounceFetchClients,
      form,
      isPause,
      onFormFinish,
      onServiceChange,
      openClientModal,
      serviceChanged,
    }),
    [
      appointmentTime,
      appointmentExists,
      clientData,
      clientModalVisible,
      clientOptions,
      debounceFetchClients,
      form,
      isPause,
      onFormFinish,
      onServiceChange,
      serviceChanged,
    ],
  );

  return response;
};
