import { Modal } from '@app/components/common/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { AppointmentForm } from '@devexpress/dx-react-scheduler';
import { FC, useCallback, useEffect } from 'react';
import { setFormVisible } from '../../store/appointmentFormSlice';
import Form from '../Form/Form';
import { ManageAppointmentViewModel } from './ManageAppointment.vm';

export const ManageAppointment: FC<AppointmentForm.BasicLayoutProps> = (props) => {
  const { onFinish, appointment, loading } = ManageAppointmentViewModel(props.appointmentData);

  useEffect(() => {
    const el = (document.getElementsByClassName('Layout-stickyContainer') as unknown as HTMLDivElement[])[0];
    if (el) {
      el.style.display = 'none';
    }
  }, []);

  if (!appointment) return null;

  return <Form onFinish={onFinish} loading={loading} appointment={appointment} />;
};

export const AppointmentFormOverlay: FC<AppointmentForm.OverlayProps> = (props) => {
  const dispatch = useAppDispatch();
  const formVisible = useAppSelector((state) => state.appointmentForm.formVisible);

  const onCancel = useCallback(() => {
    dispatch(setFormVisible(false));
  }, [dispatch]);

  return (
    <Modal
      bodyStyle={{
        height: '80vh',
        maxHeight: '80vh',
        overflow: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
      }}
      open={formVisible}
      onCancel={onCancel}
      footer={null}
    >
      {props.children}
    </Modal>
  );
};
