import { GlobalLoadingContext } from '@app/context/GlobalLoadingContext';
import { LinearProgress, LinearProgressProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useContext } from 'react';

export const AppLoadingProgress = styled(LinearProgress)(() => ({
  left: '0',
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: 100000,
}));

export const AppLoading: FC<LinearProgressProps> = (props) => {
  const context = useContext(GlobalLoadingContext);

  if (!context.loading) return null;

  return <AppLoadingProgress color="primary" {...props} />;
};
