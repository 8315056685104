import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import appointmentFormReducer from '@app/pages/AppointmentsPages/AppointmentsPage/store/appointmentFormSlice';
import appointmentsReducer from '@app/pages/AppointmentsPages/AppointmentsPage/store/appointmentsSlice';

export default {
  appointmentForm: appointmentFormReducer,
  appointments: appointmentsReducer,
  nightMode: nightModeReducer,
  pwa: pwaReducer,
  theme: themeReducer,
};
