import { Pagination } from '@app/api/table.api';
import { Badge } from '@app/components/common/Badge/Badge';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Status } from '@app/pages/BarberPages/components/Status/Status';
import * as S from './BarbersPage.styles';
import { Table } from 'components/common/Table/Table';
import { capitalize, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditOutlined } from '@ant-design/icons';
import { useGetColumnDropdownProps } from '@app/components/tables/components/filterDropdown';
import { useGetColumnSearchProps } from '@app/components/tables/components/filterSearch';
import { Priority } from '@app/constants/enums/priorities';
import {
  BarberOrderByWithRelationInput,
  BarbersQuery,
  BarberType,
  BarberWhereInput,
  SortOrder,
  useBarbersLazyQuery,
  useBarberTypesQuery,
} from '@app/graphql/generated';
import { ROUTES } from '@app/utils/constants';
import { defineColorByPriority } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Button } from 'components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

type Barber = BarbersQuery['barbers'][0];

const BarbersPage: React.FC = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [fetchBarbers, { data, loading }] = useBarbersLazyQuery();
  const { data: barberTypesData } = useBarberTypesQuery();

  const { t } = useTranslation();

  const oldWhere = useRef<BarberWhereInput | null>(null);

  const createWhere = (filters?: Record<keyof BarberWhereInput, FilterValue | null>) => {
    let where: BarberWhereInput | null = null;

    if (filters) {
      where = {
        AND: [],
      };
      (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
        const value = filters[key];
        if (where?.AND && value) {
          const OR: BarberWhereInput['OR'] = [];

          OR.push({ [key]: { contains: value[0] } });
          OR.push({ [key]: { contains: String(value[0]).toLowerCase() } });
          OR.push({ [key]: { contains: capitalize(String(value[0])) } });
          where.AND.push({ OR });
        }
      });
    }

    return where;
  };
  const createOrderBy = (sorter?: SorterResult<Barber> | SorterResult<Barber>[]) => {
    let orderBy: BarberOrderByWithRelationInput | null = null;
    const sorterIsArray = Array.isArray(sorter);

    if (sorter && !sorterIsArray) {
      orderBy = sorter.field
        ? {
            ...(typeof orderBy === 'object' ? orderBy : {}),
            [sorter.field as keyof Barber]: sorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc,
          }
        : null;
    }

    return orderBy;
  };

  const fetch = useCallback(
    async (
      pagination: Pagination,
      filters?: Record<keyof BarberWhereInput, FilterValue | null>,
      sorter?: SorterResult<Barber> | SorterResult<Barber>[],
    ) => {
      const where = createWhere(filters);
      const orderBy = createOrderBy(sorter);

      const response = await fetchBarbers({
        variables: {
          skip: pagination.current ? pagination.current - 1 : undefined,
          take: pagination.pageSize,
          ...(where ? { where } : null),
          ...(orderBy ? { orderBy } : null),
        },
      });

      setPagination({ ...pagination, total: response.data?.aggregateBarber._count?._all || 0 });
      if (!isEqual(where, oldWhere.current)) {
        oldWhere.current = where;
      }
    },
    [fetchBarbers],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange: TableProps<Barber>['onChange'] = (pagination, filters, sorter) => {
    fetch(pagination, filters, sorter);
  };

  const columns: ColumnsType<Barber> = [
    {
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text}</span>,
      sorter: true,
      title: t('barbersList.name'),
      ...useGetColumnSearchProps('name'),
    },
    {
      dataIndex: 'type',
      filterDropdown: true,
      filterMode: 'tree',
      render: (value: string) => (
        <Row gutter={[10, 10]}>
          <Col key={value}>
            <Status
              color={defineColorByPriority(value === BarberType.Admin ? Priority.INFO : Priority.MEDIUM)}
              text={value}
            />
          </Col>
        </Row>
      ),
      title: t('barbersList.type'),
      ...useGetColumnDropdownProps(
        'type',
        barberTypesData?.barberTypes.map((bt) => ({
          label: bt,
          value: bt,
        })) || [],
      ),
    },
    {
      dataIndex: 'active',
      render: (active: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>
              <Badge status={active ? 'success' : 'error'} text={active ? t('barbersList.yes') : t('barbersList.no')} />
            </Col>
          </Row>
        );
      },
      title: t('barbersList.active'),
    },
    {
      dataIndex: 'actions',
      render: (_text, record: Barber) => {
        return (
          <Space>
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() => navigate(ROUTES.BARBERS.children.EDIT.navigateTo(record.id))}
            >
              {t('tables.edit')}
            </Button>
          </Space>
        );
      },
      title: t('tables.actions'),
      width: '15%',
    },
  ];

  return (
    <>
      <PageTitle>{t('common.barbers')}</PageTitle>
      <S.TablesWrapper>
        <S.Card
          id="basic-table"
          title={t('common.barbers')}
          extra={
            <Space>
              <Button onClick={() => navigate(ROUTES.BARBERS.children.CREATE.route)}>
                {t('common.barbersCreate')}
              </Button>
            </Space>
          }
          padding="1.25rem 1.25rem 0"
        >
          <Table
            columns={columns}
            dataSource={data?.barbers}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
            rowKey={'id'}
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};

export default BarbersPage;
