import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import hr_HR from 'antd/lib/locale/hr_HR';
import 'moment/locale/de';
import 'moment/locale/hr';
import { AuthProvider } from './context/AuthContext';

import React, { useMemo } from 'react';
import 'typeface-lato';
import 'typeface-montserrat';
import { AppRouter } from './components/router/AppRouter';
import { ApolloWrapper } from './context/ApolloContext';
import { useAppSelector } from './hooks/reduxHooks';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { useLanguage } from './hooks/useLanguage';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import GlobalStyle from './styles/GlobalStyle';
import { themeObject } from './styles/themes/themeVariables';
import { GlobalLoadingProvider } from './context/GlobalLoadingContext';
import { AppLoading } from './components/common/Loading/AppLoading';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  const locale = useMemo(() => {
    switch (language) {
      case 'hr':
        return hr_HR;
      case 'en':
        return enUS;
      case 'de':
        return deDe;

      default:
        return hr_HR;
    }
  }, [language]);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <ConfigProvider locale={locale}>
        <GlobalLoadingProvider>
          <ApolloWrapper>
            <AuthProvider>
              <AppLoading />
              <AppRouter />
            </AuthProvider>
          </ApolloWrapper>
        </GlobalLoadingProvider>
      </ConfigProvider>
    </>
  );
};

export default App;
