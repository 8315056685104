// import { useTranslation } from 'react-i18next';
import { Button, Image } from 'antd';
import serverError from '@app/assets/images/server-error.svg';
import { ErrorFallbackWrapper } from './ErrorFallback.styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ErrorFallback: FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <ErrorFallbackWrapper>
      <h1>{t('errorFallback.somethingWentWrong')}</h1>
      <Image preview={false} src={serverError} />

      <Button type="link" style={{ marginTop: '10px' }} onClick={handleClick}>
        {t('errorFallback.returnToHomepage')}
      </Button>
    </ErrorFallbackWrapper>
  );
};

export default ErrorFallback;
