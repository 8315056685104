import { Modal } from '@app/components/common/Modal/Modal';
import { Table, Tag } from 'antd';
import { FC } from 'react';
import { DragNDropDifferences } from '../../Appointments.types';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  data: DragNDropDifferences;
}

const ChangedModal: FC<IProps> = ({ data, visible, onOk, onCancel }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: 'property',
      key: 'property',
      title: t('appointment.changedModal.property'),
    },
    {
      dataIndex: 'previousValue',
      key: 'previousValue',
      title: t('appointment.changedModal.previousValue'),
    },
    {
      dataIndex: 'newValue',
      key: 'newValue',
      title: t('appointment.changedModal.newValue'),
    },
    {
      dataIndex: 'difference',
      key: 'difference',
      render: (_text: unknown, record: IProps['data'][0]) => (
        <span>
          {record.fieldChanged ? (
            <Tag color="red">{t('appointment.changedModal.fieldChanged.different')}</Tag>
          ) : (
            <Tag color="green">{t('appointment.changedModal.fieldChanged.same')}</Tag>
          )}
        </span>
      ),
      title: t('appointment.changedModal.difference'),
    },
  ];
  return (
    <Modal open={visible} onOk={onOk} onCancel={onCancel}>
      <Table columns={columns} dataSource={data} pagination={false} />
    </Modal>
  );
};

export default ChangedModal;
