import {
  AppointmentSubscribeSubscription,
  AppointmentsDateChangeDocument,
  AppointmentsDateChangeQuery,
  EventType,
  useAppointmentLazyQuery,
  useAppointmentSubscribeSubscription,
} from '@app/graphql/generated';

import { useApolloClient } from '@apollo/client';
import { useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';

export const useSubscription = () => {
  const barbersFilter = useAppSelector((state) => state.appointments.filters.barbers);
  const dates = useAppSelector((state) => state.appointments.dates);
  const appointmentsWhereVariables = useAppSelector((state) => state.appointments.appointmentsWhereVariables);

  const [fetchAppointment] = useAppointmentLazyQuery();

  const client = useApolloClient();

  const removeAppointmentFromCache = (
    notification: AppointmentSubscribeSubscription['appointmentMutationNotification'],
  ) => {
    if (
      notification?.appointmentStart &&
      moment(notification?.appointmentStart).startOf('day').isSameOrAfter(moment(dates[0])) &&
      moment(notification?.appointmentStart)
        .endOf('day')
        .isSameOrBefore(moment(dates[dates.length - 1]))
    ) {
      // delete from cache
      const normalizedId = client.cache.identify({
        __typename: 'Appointment',
        id: notification.appointmentId,
      });
      client.cache.evict({ id: normalizedId });
      client.cache.gc();
    }
  };

  const addAppointmentToCache = (notification: AppointmentSubscribeSubscription['appointmentMutationNotification']) => {
    // add to cache
    if (
      notification?.appointmentStart &&
      moment(notification?.appointmentStart).startOf('day').isSameOrAfter(moment(dates[0])) &&
      moment(notification?.appointmentStart)
        .endOf('day')
        .isSameOrBefore(moment(dates[dates.length - 1]))
    ) {
      // add to cache
      fetchAppointment({
        variables: {
          where: {
            id: Number(notification.appointmentId),
          },
        },
      }).then((appointmentsData) => {
        const appointment = appointmentsData.data?.appointment;

        if (appointment) {
          client.cache.updateQuery<AppointmentsDateChangeQuery>(
            { query: AppointmentsDateChangeDocument, variables: appointmentsWhereVariables },
            (data) => {
              const appointments = data?.appointments;

              return {
                appointments: appointments ? [...appointments, appointment] : [appointment],
                barberWorkTimeOverrides: data?.barberWorkTimeOverrides || [],
              };
            },
          );
        }
      });
    }
  };

  const updateAppointmentInCache = (
    notification: AppointmentSubscribeSubscription['appointmentMutationNotification'],
  ) => {
    // add to cache
    if (
      notification?.appointmentStart &&
      moment(notification?.appointmentStart).startOf('day').isSameOrAfter(moment(dates[0])) &&
      moment(notification?.appointmentStart)
        .endOf('day')
        .isSameOrBefore(moment(dates[dates.length - 1]))
    ) {
      // add to cache
      fetchAppointment({
        variables: {
          where: {
            id: Number(notification.appointmentId),
          },
        },
      }).then((appointmentsData) => {
        const appointment = appointmentsData.data?.appointment;

        if (appointment) {
          client.cache.updateQuery<AppointmentsDateChangeQuery>(
            { query: AppointmentsDateChangeDocument, variables: appointmentsWhereVariables },
            (data) => {
              const appointments = data?.appointments;

              return {
                appointments: appointments?.map((a) => (a.id === appointment.id ? appointment : a)) || [],
                barberWorkTimeOverrides: data?.barberWorkTimeOverrides || [],
              };
            },
          );
        }
      });
    }
  };

  useAppointmentSubscribeSubscription({
    onData(options) {
      const notification = options.data.data?.appointmentMutationNotification;
      if (!notification) return;
      const appointmentBarberSelectedInFilters = barbersFilter.includes(notification.barberId);

      if (appointmentBarberSelectedInFilters) {
        const eventType = notification?.eventType;

        if (eventType === EventType.Add) {
          addAppointmentToCache(notification);
        } else if (eventType === EventType.Update) {
          updateAppointmentInCache(notification);
        } else if (eventType === EventType.Remove) {
          removeAppointmentFromCache(notification);
        }
      }
    },
  });

  return {
    fetchAppointment,
  };
};
