import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { AuthContext } from '@app/context/AuthContext';
import { ROUTES } from '@app/utils/constants';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return null;
  }

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={0}>
        <S.Text>
          <Link to={ROUTES.BARBERS.children.EDIT.navigateTo(userData?.id)}>{t('profile.title')}</Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.MenuItem key={1}>
        <S.Text>
          <Link to="/logout">{t('header.logout')}</Link>
        </S.Text>
      </S.MenuItem>
    </DropdownMenu>
  );
};
