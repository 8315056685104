import { Card } from '@app/components/common/Card/Card';
import { Col, Typography } from 'antd';
import styled from 'styled-components';

export const AppointmentsCard = styled(Card)`
  .ant-card-body {
    height: 100%;
  }
`;

export const AppointmentsCardCol = styled(Col)`
  height: 100%;
`;

export const SchedulerWrapper = styled.div`
  height: 100%;
`;

export const StatusText = styled(Typography.Text)``;

export const Text = styled(Typography.Text)``;

export const FilterCol = styled(Col)`
  height: fit-content;
`;

export const AppointmentsWrapper = styled.div<{ noOfFilterBarbers: number }>`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  display: flex;
  height: 100%;

  .Cell-brightRightBorder {
    border-right: 4px solid rgba(0, 0, 0, 1) !important;
  }

  .Cell-brightRightBorder:last-child {
    border-right: unset !important;
  }

  .Cell-groupedByDate:nth-child(${(props) => (props.noOfFilterBarbers ? props.noOfFilterBarbers : '')}n) {
    border-right: 4px solid rgba(0, 0, 0, 1) !important;
  }

  .Cell-groupedByDate:last-child {
    border-right: unset !important;
  }
`;
