import {
  Appointment,
  Appointment_FragmentFragmentDoc,
  AppointmentsDateChangeDocument,
  useDeleteAppointmentMutation,
  useDeleteManyAppointmentMutation,
} from '@app/graphql/generated';
import { EditingStateProps } from '@devexpress/dx-react-scheduler';

import { useApolloClient } from '@apollo/client';
import { notificationController } from '@app/controllers/notificationController';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangedDragNDropAppointment, DeleteOptionEnum } from '../Appointments.types';

export const useSchedulerData = (generateDifferencesOnDragNDrop: (changed: ChangedDragNDropAppointment) => void) => {
  const { t } = useTranslation();

  const [selectedDeleteRepeatOption, setSelectedDeleteRepeatOption] = useState<DeleteOptionEnum>('one');

  const client = useApolloClient();

  const [deleteAppointment] = useDeleteAppointmentMutation({
    onCompleted(data) {
      if (data?.deleteOneAppointment?.id) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: t('common.error') });
      }
    },
  });

  const [deleteAppointments] = useDeleteManyAppointmentMutation({
    onCompleted() {
      notificationController.success({ message: t('common.success') });
    },
    update() {
      client.refetchQueries({
        include: [AppointmentsDateChangeDocument],
      });
    },
  });

  const onCommitChanges: EditingStateProps['onCommitChanges'] = useCallback(
    async ({ changed, deleted }) => {
      if (changed) {
        generateDifferencesOnDragNDrop(changed);
      }

      if (deleted) {
        const appointmentToDelete = client.readFragment<Appointment>({
          fragment: Appointment_FragmentFragmentDoc,
          id: `Appointment:${deleted}`,
          // variables: { where: { id: Number(deleted) } } as AppointmentWhereUniqueInput,
        });

        if (selectedDeleteRepeatOption === 'one') {
          deleteAppointment({
            update(cache) {
              // Delete from cache
              const normalizedId = cache.identify({ __typename: 'Appointment', id: deleted });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
            variables: {
              where: {
                id: Number(deleted),
              },
            },
          });
        } else if (appointmentToDelete?.repeatId && selectedDeleteRepeatOption === 'all') {
          deleteAppointments({
            variables: {
              where: {
                repeatId: {
                  equals: String(appointmentToDelete.repeatId),
                },
                start: {
                  gte: appointmentToDelete.start,
                },
              },
            },
          });
        }
      }
    },
    [client, deleteAppointment, deleteAppointments, generateDifferencesOnDragNDrop, selectedDeleteRepeatOption],
  );

  return {
    onCommitChanges,
    selectedDeleteRepeatOption,
    setSelectedDeleteRepeatOption,
  };
};
