import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: unset;

  .ant-modal-content {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
