import { AuthContext } from '@app/context/AuthContext';
import { WithChildrenProps } from '@app/types/generalTypes';
import { ROUTES } from '@app/utils/constants';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../common/Loading';
import * as S from '../layouts/main/MainLayout/MainLayout.styles';

import MainContent from '../layouts/main/MainContent/MainContent';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const { isLoggedIn, mounting } = useContext(AuthContext);

  if (mounting) {
    return (
      <S.LayoutMaster>
        <S.LayoutMain>
          <MainContent id="main-content" $isTwoColumnsLayout={false}>
            <Loading />
          </MainContent>
        </S.LayoutMain>
      </S.LayoutMaster>
    );
  }

  return isLoggedIn ? <>{children}</> : <Navigate to={ROUTES.LOGIN.navigateTo} replace />;
};

export default RequireAuth;
