import { EditOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/table.api';
import { Badge } from '@app/components/common/Badge/Badge';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useGetColumnSearchProps } from '@app/components/tables/components';
import * as S from '@app/components/tables/Tables/Tables.styles';
import {
  ClientOrderByWithRelationInput,
  ClientsQuery,
  ClientWhereInput,
  SortOrder,
  useClientsLazyQuery,
} from '@app/graphql/generated';
import { Col, Row, Space } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Button } from 'components/common/buttons/Button/Button';
import { Table } from 'components/common/Table/Table';
import { capitalize, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

type Client = ClientsQuery['clients'][0];

const ClientsPage: React.FC = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [fetchClients, { data, loading }] = useClientsLazyQuery();

  const { t } = useTranslation();

  const oldWhere = useRef<ClientWhereInput | null>(null);

  const createWhere = (filters?: Record<keyof ClientWhereInput, FilterValue | null>) => {
    let where: ClientWhereInput | null = null;

    if (filters) {
      where = {
        AND: [],
      };
      (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
        const value = filters[key];
        if (where?.AND && value) {
          const OR: ClientWhereInput['OR'] = [];

          OR.push({ [key]: { contains: value[0] } });
          OR.push({ [key]: { contains: String(value[0]).toLowerCase() } });
          OR.push({ [key]: { contains: capitalize(String(value[0])) } });
          where.AND.push({ OR });
        }
      });
    }

    return where;
  };

  const createOrderBy = (sorter?: SorterResult<Client> | SorterResult<Client>[]) => {
    let orderBy: ClientOrderByWithRelationInput | null = null;
    const sorterIsArray = Array.isArray(sorter);

    if (sorter && !sorterIsArray) {
      orderBy = sorter.field
        ? {
            ...(typeof orderBy === 'object' ? orderBy : {}),
            [sorter.field as keyof Client]: sorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc,
          }
        : null;
    }

    return orderBy;
  };

  const fetch = useCallback(
    async (
      pagination: Pagination,
      filters?: Record<keyof ClientWhereInput, FilterValue | null>,
      sorter?: SorterResult<Client> | SorterResult<Client>[],
    ) => {
      const where = createWhere(filters);
      const orderBy = createOrderBy(sorter);

      const response = await fetchClients({
        variables: {
          skip: pagination.current && pagination.pageSize ? (pagination.current - 1) * pagination.pageSize : undefined,
          ...(where ? { where } : null),
          ...(orderBy ? { orderBy } : null),
          take: pagination.pageSize,
        },
      });

      setPagination({ ...pagination, total: response.data?.aggregateClient._count?._all || 0 });
      if (!isEqual(where, oldWhere.current)) {
        oldWhere.current = where;
      }
    },
    [fetchClients],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange: TableProps<Client>['onChange'] = (pagination, filters, sorter) => {
    fetch(pagination, filters, sorter);
  };

  const columns: ColumnsType<Client> = [
    {
      dataIndex: 'firstName',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text}</span>,
      sorter: true,
      title: t('clientsList.firstName'),
      ...useGetColumnSearchProps('firstName'),
    },
    {
      dataIndex: 'lastName',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text}</span>,
      sorter: true,
      title: t('clientsList.lastName'),
      ...useGetColumnSearchProps('lastName'),
    },
    {
      dataIndex: 'phone',
      filterMode: 'tree',
      filterSearch: true,
      render: (text: string) => <span>{text}</span>,
      sorter: true,
      title: t('clientsList.phone'),
      ...useGetColumnSearchProps('phone'),
    },
    {
      dataIndex: 'blocked',
      render: (blocked: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>
              <Badge
                status={blocked ? 'error' : 'success'}
                text={blocked ? t('clientsList.yes') : t('clientsList.no')}
              />
            </Col>
          </Row>
        );
      },
      title: t('clientsList.blocked'),
    },
    {
      dataIndex: 'verified',
      render: (verified: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>
              <Badge
                status={verified ? 'success' : 'error'}
                text={verified ? t('clientsList.yes') : t('clientsList.no')}
              />
            </Col>
          </Row>
        );
      },
      title: t('clientsList.verified'),
    },
    {
      dataIndex: 'active',
      render: (active: boolean) => {
        return (
          <Row gutter={[10, 10]}>
            <Col>
              <Badge status={active ? 'success' : 'error'} text={active ? t('clientsList.yes') : t('clientsList.no')} />
            </Col>
          </Row>
        );
      },
      title: t('clientsList.active'),
    },
    {
      dataIndex: 'actions',
      render: (_text, record: Client) => {
        return (
          <Space>
            <Button type="default" icon={<EditOutlined />} onClick={() => navigate(`/clients/${record.id}`)}>
              {t('tables.edit')}
            </Button>
          </Space>
        );
      },
      title: t('tables.actions'),
      width: '15%',
    },
  ];

  return (
    <>
      <PageTitle>{t('common.clients')}</PageTitle>
      <S.TablesWrapper>
        <S.Card
          id="basic-table"
          title={t('common.clients')}
          extra={<Button onClick={() => navigate('/clients/create')}>{t('common.clientCreate')}</Button>}
          padding="1.25rem 1.25rem 0"
        >
          <Table
            columns={columns}
            dataSource={data?.clients}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
            rowKey={'id'}
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};

export default ClientsPage;
