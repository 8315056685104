import { notificationController } from '@app/controllers/notificationController';
import {
  AppointmentCreateInput,
  AppointmentUpdateInput,
  AppointmentsDateChangeDocument,
  useAppointmentsDateChangeLazyQuery,
  useCreateAppointmentMutation,
  useCreateRepeatableAppointmentMutation,
  useUpdateAppointmentMutation,
} from '@app/graphql/generated';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentData } from '../../Appointments.types';

import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setFormVisible } from '../../store/appointmentFormSlice';
import { AppointmentFields, AppointmentRepeatFormFields, FormProps } from '../Form/Form.types';
import { ManageAppointmentViewModelType } from './ManageAppointment.types';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function create<T extends (args: any) => ReturnType<T>, V>(cb: T, variables: V): ReturnType<T> {
  return cb(variables);
}

export const ManageAppointmentViewModel: ManageAppointmentViewModelType = (clickedAppointmentData) => {
  const [appointmentsDateQuery, { client }] = useAppointmentsDateChangeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted(data) {
      client.writeQuery({
        data: data,
        query: AppointmentsDateChangeDocument,
        variables: appointmentsWhereVariables,
      });
    },
  });
  const appointmentsWhereVariables = useAppSelector((state) => state.appointments.appointmentsWhereVariables);

  const appointmentData = useMemo(() => clickedAppointmentData as AppointmentData, [clickedAppointmentData]);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [createRepeatableAppointment, { loading: loadingCreateRepeatable }] = useCreateRepeatableAppointmentMutation({
    onCompleted(data) {
      if (data?.createRepeatableAppointment) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: t('common.error') });
      }
      dispatch(setFormVisible(false));
      appointmentsDateQuery({
        fetchPolicy: 'network-only',
        variables: appointmentsWhereVariables,
      });
    },
    onError() {
      return null;
    },
  });

  const [createAppointment, { loading: loadingCreate }] = useCreateAppointmentMutation({
    onCompleted(data) {
      if (data?.createOneAppointment.id) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: t('common.error') });
      }
      dispatch(setFormVisible(false));
      appointmentsDateQuery({
        fetchPolicy: 'network-only',
        variables: appointmentsWhereVariables,
      });
    },
    onError() {
      return null;
    },
  });

  const [updateAppointment, { loading: loadingUpdate }] = useUpdateAppointmentMutation({
    onCompleted(data) {
      if (data?.updateOneAppointment?.id) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: t('common.error') });
      }
      dispatch(setFormVisible(false));
      appointmentsDateQuery({
        fetchPolicy: 'network-only',
        variables: appointmentsWhereVariables,
      });
    },
    onError() {
      return null;
    },
  });

  const createOnFinish = useCallback(
    (
      values: AppointmentFields,
      resetFormFields: () => void,
      repeatableValues: AppointmentRepeatFormFields | undefined,
    ) => {
      const appointmentDataToCreate: AppointmentCreateInput = {
        barber: {
          connect: { id: values.barberId },
        },
        barberNote: values.barberNote,
        client: values.isPause
          ? undefined
          : {
              connect: { id: values.clientId },
            },
        end: values.end,
        isPause: values.isPause,
        phoneContact: values.phoneContact,
        price: values.isPause ? undefined : String(values.price),
        service: values.isPause
          ? undefined
          : {
              connect: { id: values.serviceId },
            },
        start: values.start,
      };

      if (repeatableValues && repeatableValues.repeated) {
        create(createRepeatableAppointment, {
          variables: {
            data: {
              ...appointmentDataToCreate,
              customRepeatWeekDays: repeatableValues?.customRepeatWeekDays,
              repeatEndDate: repeatableValues?.repeatEndDate,
              repeatFrequency: repeatableValues?.repeatFrequency,
              repeatOption: repeatableValues?.repeatOption,
            },
          },
        }).then(({ data }) => {
          if (data?.createRepeatableAppointment) {
            resetFormFields();
          }
        });
      } else {
        create(createAppointment, {
          variables: {
            data: appointmentDataToCreate,
          },
        }).then(({ data }) => {
          if (data?.createOneAppointment.id) {
            resetFormFields();
          }
        });
      }
    },
    [createAppointment, createRepeatableAppointment],
  );

  const updateOnFinish = useCallback(
    (values: AppointmentFields) => {
      const data: AppointmentUpdateInput = {
        barber: {
          connect: { id: values.barberId },
        },
        barberNote: { set: values.barberNote },
        client:
          values.isPause && appointmentData.appointment?.client
            ? {
                disconnect: {
                  id: { equals: Number(appointmentData.appointment?.client.id) },
                },
              }
            : {
                connect: { id: values.clientId },
              },
        end: { set: values.end },
        isPause: { set: values.isPause },
        phoneContact: { set: values.phoneContact },
        price: { set: values.isPause && appointmentData.appointment?.price ? '0' : String(values.price) },
        service:
          values.isPause && appointmentData.appointment?.service
            ? {
                disconnect: {
                  id: { equals: Number(appointmentData.appointment?.service.id) },
                },
              }
            : {
                connect: { id: values.serviceId },
              },
        start: { set: values.start },
      };

      updateAppointment({
        variables: {
          data,
          where: {
            id: appointmentData.appointment?.id,
          },
        },
      });
    },
    [
      appointmentData.appointment?.client,
      appointmentData.appointment?.id,
      appointmentData.appointment?.price,
      appointmentData.appointment?.service,
      updateAppointment,
    ],
  );

  const appointment = useMemo(() => {
    return appointmentData.startDate
      ? ({
          barberId: appointmentData.barberId,
          end: moment(appointmentData.endDate).set({
            milliseconds: 0,
            seconds: 0,
          }),
          id: appointmentData.appointment?.id,
          isPause: appointmentData.appointment?.isPause,
          start: moment(appointmentData.startDate).set({
            milliseconds: 0,
            seconds: 0,
          }),
          ...(appointmentData.appointment
            ? {
                barberNote: appointmentData.appointment?.barberNote,
                clientId: appointmentData.appointment.client?.id,
                phoneContact: appointmentData.appointment?.phoneContact,
                price: appointmentData.appointment.price,
                serviceId: appointmentData.appointment.service?.id,
              }
            : null),
        } as FormProps['appointment'])
      : undefined;
  }, [appointmentData]);

  return useMemo(() => {
    return {
      appointment,
      loading: loadingCreate || loadingUpdate || loadingCreateRepeatable,
      onFinish: appointmentData.appointment ? updateOnFinish : createOnFinish,
    };
  }, [
    appointment,
    loadingCreate,
    loadingUpdate,
    loadingCreateRepeatable,
    appointmentData.appointment,
    updateOnFinish,
    createOnFinish,
  ]);
};
