import { Layout } from 'antd';
import styled from 'styled-components';

export const FormBodyWrapper = styled(Layout.Content)`
  padding: 24px;
`;

export const Label = styled.label`
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  max-width: 100% !important;
  height: auto !important;
  color: var(--primary-color) !important;
  font-size: 14px !important;

  ::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;
