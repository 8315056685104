import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  concat,
  fromPromise,
  toPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { RetryLink } from '@apollo/client/link/retry';

import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { setContext } from '@apollo/client/link/context';
import { auth } from '@app/config/firebase';
import { notificationController } from '@app/controllers/notificationController';
import { FC, PropsWithChildren, memo, useCallback, useContext, useEffect, useState } from 'react';
import { GlobalLoadingContext } from './GlobalLoadingContext';

export const ApolloWrapper: FC<PropsWithChildren<unknown>> = memo(({ children }) => {
  const { setLoading } = useContext(GlobalLoadingContext);
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const initAppolloClient = useCallback(async () => {
    const loadingMultiple: boolean[] = [];

    const loadingLink = new ApolloLink((operation, forward) => {
      if (!loadingMultiple.length) {
        setLoading(true);
      }

      return fromPromise(
        toPromise(forward(operation)).finally(() => {
          loadingMultiple.pop();
          setTimeout(() => {
            if (!loadingMultiple.length) {
              setLoading(false);
            }
          }, 1000);
        }),
      );
    });

    const httpLink = new HttpLink({ uri: process.env.REACT_APP_BASE_URL });

    const wsLink = new GraphQLWsLink(
      createClient({
        connectionParams: async () => {
          const token = await auth.currentUser?.getIdToken();
          return {
            authorization: token ? `${token}` : '',
          };
        },
        lazy: true,
        url: process.env.REACT_APP_SUBSCRIPTION_BASE_URL || '',
      }),
    );

    const authLink = setContext(async (_, { headers }) => {
      const token = await auth.currentUser?.getIdToken();

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : '',
        },
      };
    });

    //log query errors
    const errorLink = onError((error) => {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors?.length) {
        graphQLErrors.map(({ message }) => {
          notificationController.warning({ message: message });
        });
      }

      if (networkError) {
        notificationController.error({ message: networkError.message });
      }
    });

    const timeoutMiddleware = new ApolloLink((operation, forward) => {
      const timeout = 5000; // 5 seconds
      const controller: AbortController | null = new AbortController();

      const timeoutId = setTimeout(() => {
        controller?.abort();
      }, timeout);

      return new Observable((observer) => {
        const subscription = forward(operation).subscribe({
          complete: observer.complete.bind(observer),
          error: observer.error.bind(observer),
          next: observer.next.bind(observer),
        });

        return () => {
          clearTimeout(timeoutId);
          controller?.abort();
          subscription.unsubscribe();
        };
      });
    });

    const httpLinkWithTimeout = concat(loadingLink, concat(timeoutMiddleware, httpLink));

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLinkWithTimeout,
    );

    const retryLink = new RetryLink({
      attempts: {
        max: 3, // The maximum number of attempts
        retryIf: (error) => {
          // A function that determines if an error should be retried
          return !!error; // Retry all errors
        },
      },
      delay: {
        initial: 300, // The initial delay in milliseconds
        jitter: false, // Whether to randomize the delays
      },
    });

    const client = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              barber: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
      defaultOptions: {
        mutate: {
          fetchPolicy: 'network-only',
        },
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
      link: ApolloLink.from([errorLink, timeoutMiddleware, authLink.concat(concat(retryLink, splitLink))]),
    });

    setApolloClient(client);
  }, [setLoading]);

  useEffect(() => {
    initAppolloClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!apolloClient) {
    return null;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
});
