import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import { FC } from 'react';
import styled from 'styled-components';
import BarbersFilter from '../BarbersFilter/BarbersFilter';

export const FlexibleSpace = styled(Toolbar.FlexibleSpace)`
  display: flex;
  flex: 1 !important;
  height: 40px;
  padding: 0px 8px;
  max-width: 500px;
  justify-content: flex-end;
`;

export const FlexibleSpaceCustom: FC<Toolbar.FlexibleSpaceProps> = (props) => (
  <FlexibleSpace {...props}>
    <BarbersFilter />
  </FlexibleSpace>
);
