import { AppointmentBarbersQuery, AppointmentsDateChangeQuery } from '@app/graphql/generated';
import { useLanguage } from '@app/hooks/useLanguage';
import { GroupingStateProps, Resource, ResourceInstance } from '@devexpress/dx-react-scheduler';
import { green, lightBlue, orange } from '@mui/material/colors';
import enUS from 'antd/lib/locale/en_US';
import hr_HR from 'antd/lib/locale/hr_HR';
import { useMemo } from 'react';
import { AppointmentData, AppointmentsViewModelReturnType } from '../Appointments.types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const useScheduler = (
  barbers: AppointmentBarbersQuery['barbers'] | undefined,
  appointments: AppointmentsDateChangeQuery['appointments'] | undefined,
) => {
  const barberFilters = useAppSelector((state) => state.appointments.filters.barbers);

  const { language } = useLanguage();
  const { t } = useTranslation();

  const locale = useMemo(() => {
    switch (language) {
      case 'hr':
        return hr_HR;
      case 'en':
        return enUS;

      default:
        return hr_HR;
    }
  }, [language]);

  const groupField = 'barberId';

  const grouping: AppointmentsViewModelReturnType['grouping'] = useMemo(() => {
    return [
      {
        resourceName: groupField,
      },
    ];
  }, []);

  const resourceInstances: ResourceInstance[] = useMemo(() => {
    if (!barberFilters.length) {
      return [];
    }

    const instances: ResourceInstance[] = [];

    barberFilters.forEach((barberId, index) => {
      const barber = barbers?.find((barber) => barber.id === barberId);

      if (barber) {
        const instance: ResourceInstance = {
          color: calculateColor(index),
          id: barber.id,
          text: barber.name,
        };
        instances.push(instance);
      }
    });

    return instances;
  }, [barbers, barberFilters]);

  const resources: Resource[] = useMemo(() => {
    return [
      {
        fieldName: groupField,
        instances: resourceInstances.length
          ? resourceInstances
          : [
              {
                id: 1,
                text: '',
              },
            ],
      },
    ] as Resource[];
  }, [resourceInstances]);

  const schedulerData: AppointmentData[] = useMemo(
    () =>
      appointments?.map((appointment) => {
        const clientTitle =
          !appointment.client?.firstName && !appointment.client?.lastName && appointment.client?.name
            ? `${appointment.client?.name}`
            : `${appointment.client?.firstName} ${appointment.client?.lastName}`;

        const title = appointment.isPause
          ? t('appointment.form.pause/brunch')
          : `${clientTitle} - ${appointment.service?.name}`;

        return {
          appointment: appointment as AppointmentData['appointment'],
          endDate: appointment.end,
          [groupField]: appointment.barberId,
          id: appointment.id,
          startDate: appointment.start,
          title,
        };
      }) || [],
    [appointments, t],
  );

  const calendarStartDayHour = process.env.REACT_APP_CALENDAR_START_DAY_HOUR
    ? Number(process.env.REACT_APP_CALENDAR_START_DAY_HOUR)
    : 0;

  const calendarEndDayHour = process.env.REACT_APP_CALENDAR_END_DAY_HOUR
    ? Number(process.env.REACT_APP_CALENDAR_END_DAY_HOUR)
    : 0;

  const groupByDate: GroupingStateProps['groupByDate'] = () => true;

  return {
    calendarEndDayHour,
    calendarStartDayHour,
    groupByDate,
    groupField,
    grouping,
    locale,
    resources,
    schedulerData,
  };
};

const calculateColor = (index: number) => {
  if (index === 0) {
    return lightBlue;
  }
  if (index === 1) {
    return green;
  }
  if (index === 2) {
    return orange;
  }
};
