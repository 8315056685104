import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { PhoneItem } from '@app/components/common/PhoneItem/PhoneItem';
import { Switch } from '@app/components/common/Switch/Switch';
import { notificationController } from '@app/controllers/notificationController';
import { useCreateClientMutation } from '@app/graphql/generated';
import { Space, Tooltip } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onFormFinish?: (id: number) => void;
}

const ClientCreate: FC<IProps> = ({ onFormFinish }) => {
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();

  const [createClient, { loading: loadingCreateClient }] = useCreateClientMutation({
    onCompleted(data) {
      if (data.createOneClient.id) {
        notificationController.success({ message: t('common.success') });
        form.resetFields();
        if (onFormFinish) {
          onFormFinish(data.createOneClient.id);
        }
      } else {
        notificationController.error({ message: t('common.error') });
      }
    },
    onError() {
      return null;
    },
  });

  return (
    <BaseButtonsForm
      form={form}
      isFieldsChanged={true}
      loading={loadingCreateClient}
      onFinish={(values) => {
        createClient({
          variables: {
            data: {
              active: values.active,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
            },
          },
        });
      }}
      footer={
        <BaseButtonsForm.Item>
          <Button htmlType="submit" type="primary" loading={loadingCreateClient}>
            {t('common.submit')}
          </Button>
        </BaseButtonsForm.Item>
      }
    >
      <BaseButtonsForm.Item
        name="firstName"
        label={t('forms.clientFormLabels.firstName')}
        rules={[{ message: t('forms.clientFormLabels.firstNameError'), required: true }]}
      >
        <Input />
      </BaseButtonsForm.Item>
      <BaseButtonsForm.Item
        name="lastName"
        label={t('forms.clientFormLabels.lastName')}
        rules={[{ message: t('forms.clientFormLabels.lastNameError'), required: true }]}
      >
        <Input />
      </BaseButtonsForm.Item>
      <PhoneItem required form={form} name="phone" />
      <Space>
        <BaseButtonsForm.Item name="active" valuePropName="checked" initialValue={true}>
          <Switch
            checkedChildren={t('forms.clientFormLabels.disabledSwitchYes')}
            unCheckedChildren={t('forms.clientFormLabels.disabledSwitchNo')}
            defaultChecked
          />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item>
          <Tooltip title="Extra information">
            <InfoCircleOutlined style={{ color: 'white', fontSize: 20, marginTop: 7 }} />
          </Tooltip>
        </BaseButtonsForm.Item>
      </Space>
    </BaseButtonsForm>
  );
};

export default ClientCreate;
