import { DayView, WeekView } from '@devexpress/dx-react-scheduler-material-ui';

import { AppointmentBarberWorkTimesQuery, AppointmentBarbersQuery } from '@app/graphql/generated';
import moment from 'moment';

const outsideWorkingHours: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
};

const freeDayStyle: React.CSSProperties = {
  backgroundColor: 'red',
};

export const getViewTimeCell = ({
  barberWorkTimeOverridesData,
  barbersData,
  dayView,
}: {
  barberWorkTimeOverridesData?: AppointmentBarberWorkTimesQuery['barberWorkTimeOverrides'] | undefined;
  dayView?: boolean;
} & {
  barbersData?: AppointmentBarbersQuery;
}) => {
  return (props: WeekView.TimeTableCellProps) => {
    const barberId = props.groupingInfo && props.groupingInfo[0].id;
    const barber = barbersData?.barbers.find((barber) => barber.id === barberId);

    const workTimes = barber?.barberWorkTimes.filter(
      (workTime) => workTime.barberId === barberId && workTime.dayOfWeek === props.startDate?.getDay(),
    );

    const workTimeOverrides = barberWorkTimeOverridesData?.filter(
      (workTimeOverride) =>
        workTimeOverride.barberId === barberId && moment(workTimeOverride.date).isSame(moment(props.startDate), 'date'),
    );

    let isWithinWorkHours = false;
    let isFreeDay = false;

    const cellStartDate = moment(props.startDate).set({ millisecond: 0, second: 0 });
    const cellEndTime = moment(props.endDate).set({ millisecond: 0, second: 0 });
    const now = moment();

    const cellIsInThePast = cellStartDate.isBefore(now);

    if (cellStartDate.isAfter(now)) {
      if (workTimeOverrides?.length) {
        for (const workTimeOverride of workTimeOverrides) {
          const workStartTime = moment(workTimeOverride.start).set({
            date: cellStartDate.date(),
            milliseconds: 0,
            month: cellStartDate.month(),
            year: cellStartDate.year(),
          });
          const workEndTime = moment(workTimeOverride.end).set({
            date: cellEndTime.date(),
            milliseconds: 0,
            month: cellEndTime.month(),
            year: cellEndTime.year(),
          });

          isWithinWorkHours = cellStartDate.isSameOrAfter(workStartTime) && cellEndTime.isSameOrBefore(workEndTime);

          if (isWithinWorkHours) {
            break;
          }
        }
      } else if (workTimes?.length) {
        for (const workTime of workTimes) {
          const workStartTime = moment(workTime.start).set({
            date: cellStartDate.date(),
            milliseconds: 0,
            month: cellStartDate.month(),
            seconds: 0,
            year: cellStartDate.year(),
          });
          const workEndTime = moment(workTime.end).set({
            date: cellEndTime.date(),
            milliseconds: 0,
            month: cellEndTime.month(),
            seconds: 0,
            year: cellEndTime.year(),
          });

          isWithinWorkHours = cellStartDate.isSameOrAfter(workStartTime) && cellEndTime.isSameOrBefore(workEndTime);

          if (isWithinWorkHours) {
            break;
          }
        }
      } else {
        isWithinWorkHours = false;
      }
    }
    if (workTimeOverrides?.length) {
      for (const workTimeOverride of workTimeOverrides) {
        if (workTimeOverride.isFreeDay && moment(workTimeOverride.date).isSame(cellStartDate, 'date')) {
          isFreeDay = true;
          break;
        }
      }
    }

    if (dayView) {
      return (
        <DayView.TimeTableCell
          {...props}
          style={isFreeDay ? freeDayStyle : !isWithinWorkHours ? outsideWorkingHours : undefined}
          isShaded={cellIsInThePast && !isFreeDay}
        />
      );
    }

    return (
      <WeekView.TimeTableCell
        {...props}
        style={isFreeDay ? freeDayStyle : !isWithinWorkHours ? outsideWorkingHours : undefined}
        isShaded={cellIsInThePast && !isFreeDay}
      />
    );
  };
};
