import { InfoCircleOutlined, PhoneOutlined, RetweetOutlined } from '@ant-design/icons';
import { AppointmentTooltip, AppointmentTooltipProps } from '@devexpress/dx-react-scheduler-material-ui';
import { Grid } from '@mui/material';
import { AppointmentData } from '../../../Appointments.types';

export const AppointmentTooltipContent: AppointmentTooltipProps['contentComponent'] = ({
  appointmentData,
  ...restProps
}) => {
  const data = appointmentData as AppointmentData | undefined;

  return (
    <AppointmentTooltip.Content {...restProps} appointmentData={data}>
      {data?.appointment?.barberNote ? (
        <Grid container alignItems="center" marginTop={1.2}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.2rem' }} />
          </Grid>
          <Grid item xs={10}>
            <span>{data?.appointment?.barberNote}</span>
          </Grid>
        </Grid>
      ) : null}
      {data?.appointment?.phoneContact ||
      (data?.appointment?.client?.phone && data?.appointment?.client?.phone !== '0') ? (
        <Grid container alignItems="center" marginTop={1.2}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <PhoneOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.2rem' }} />
          </Grid>
          <Grid item xs={10}>
            <span>{data?.appointment?.phoneContact || data?.appointment?.client?.phone}</span>
          </Grid>
        </Grid>
      ) : null}
      {data?.appointment?.repeatId ? (
        <Grid container alignItems="center" marginTop={1.2}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <RetweetOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.2rem' }} />
          </Grid>
          <Grid item xs={10}>
            <span>Ponavljajući termin</span>
          </Grid>
        </Grid>
      ) : null}
    </AppointmentTooltip.Content>
  );
};
