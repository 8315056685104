import { useState } from 'react';

export const useRepeated = () => {
  const [repeated, setRepeated] = useState<boolean>(false);
  const [repeatedModalVisible, setRepeatedModalVisible] = useState<boolean>(false);

  return {
    repeated,
    repeatedModalVisible,
    setRepeated,
    setRepeatedModalVisible,
  };
};
