import { EditOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/table.api';
// import { useGetColumnSearchProps } from '@app/components/tables/components/filterSearch';
import {
  AdditionalServiceOrderByWithRelationInput,
  AdditionalServiceWhereInput,
  SortOrder,
  useAdditionalServicesLazyQuery,
} from '@app/graphql/generated';
import { Space, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Button } from 'components/common/buttons/Button/Button';
import { capitalize, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AdditionalService, AdditionalServicesViewModelType } from './AdditionalServicesPage.types';
import { useGetColumnSearchProps } from '@app/components/tables/components';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const AdditionalServicesViewModel: AdditionalServicesViewModelType = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [fetchAdditionalServices, { data, loading }] = useAdditionalServicesLazyQuery();

  const { t } = useTranslation();

  const oldWhere = useRef<AdditionalServiceWhereInput | null>(null);

  const createWhere = (filters?: Record<keyof AdditionalServiceWhereInput, FilterValue | null>) => {
    let where: AdditionalServiceWhereInput | null = null;

    if (filters) {
      where = {
        AND: [],
      };
      (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
        const value = filters[key];
        if (where?.AND && value) {
          const OR: AdditionalServiceWhereInput['OR'] = [];

          OR.push({ [key]: { contains: value[0] } });
          OR.push({ [key]: { contains: String(value[0]).toLowerCase() } });
          OR.push({ [key]: { contains: capitalize(String(value[0])) } });
          where.AND.push({ OR });
        }
      });
    }

    return where;
  };

  const createOrderBy = (sorter?: SorterResult<AdditionalService> | SorterResult<AdditionalService>[]) => {
    let orderBy: AdditionalServiceOrderByWithRelationInput | null = null;
    const sorterIsArray = Array.isArray(sorter);

    if (sorter && !sorterIsArray) {
      orderBy = sorter.field
        ? {
            ...(typeof orderBy === 'object' ? orderBy : {}),
            [sorter.field as keyof AdditionalService]: sorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc,
          }
        : null;
    }

    return orderBy;
  };

  const fetch = useCallback(
    async (
      pagination: Pagination,
      filters?: Record<keyof AdditionalServiceWhereInput, FilterValue | null>,
      sorter?: SorterResult<AdditionalService> | SorterResult<AdditionalService>[],
    ) => {
      const where = createWhere(filters);
      const orderBy = createOrderBy(sorter);

      const response = await fetchAdditionalServices({
        variables: {
          skip: pagination.current && pagination.pageSize ? (pagination.current - 1) * pagination.pageSize : undefined,
          ...(where ? { where } : null),
          ...(orderBy ? { orderBy } : null),
          take: pagination.pageSize,
        },
      });

      setPagination({ ...pagination, total: response.data?.aggregateAdditionalService._count?._all || 0 });
      if (!isEqual(where, oldWhere.current)) {
        oldWhere.current = where;
      }
    },
    [fetchAdditionalServices],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange: TableProps<AdditionalService>['onChange'] = useCallback(
    (pagination, filters, sorter) => {
      fetch(pagination, filters, sorter);
    },
    [fetch],
  );

  const columnSearchProps = useGetColumnSearchProps<string, AdditionalService>('name');

  const columns: ColumnsType<AdditionalService> = useMemo(
    () => [
      {
        dataIndex: 'name',
        filterMode: 'tree',
        filterSearch: true,
        render: (text: string) => <span>{text}</span>,
        sorter: true,
        title: t('servicesList.name'),
        ...columnSearchProps,
      },
      {
        dataIndex: 'description',
        filterMode: 'tree',
        filterSearch: true,
        render: (text: string) => (
          <Tooltip title={text}>
            <span
              style={{
                cursor: 'pointer',
                display: 'block',
                maxWidth: '800px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {text}
            </span>
          </Tooltip>
        ),
        sorter: true,
        title: t('servicesList.description'),
      },
      {
        dataIndex: 'duration',
        filterMode: 'tree',
        filterSearch: true,
        render: (text: string) => <span>{text} min</span>,
        sorter: true,
        title: t('servicesList.duration'),
      },
      {
        dataIndex: 'price',
        filterMode: 'tree',
        filterSearch: true,
        render: (text: string) => <span>{text} €</span>,
        sorter: true,
        title: t('servicesList.price'),
      },
      {
        dataIndex: 'actions',
        render: (_text, record: AdditionalService) => {
          return (
            <Space>
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={() => navigate(`/additional-services/${record.id}`)}
              >
                {t('tables.edit')}
              </Button>
            </Space>
          );
        },
        title: t('tables.actions'),
        width: '15%',
      },
    ],
    [columnSearchProps, navigate, t],
  );

  return useMemo(
    () => ({ columns, data, handleTableChange, loading, navigate, pagination }),
    [columns, data, handleTableChange, navigate, loading, pagination],
  );
};
